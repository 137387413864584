import BaseModel from '../core/BaseModel';

export default class NewsModel extends BaseModel {
    constructor() {
        super({ 
                name: "news",
                tableName: 'news', 
                label: "Danh sách Tin tức",
                display_key: 'name'
            },  
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            title : { 
                name: 'title', 
                type: "String", 
                label: 'Tiêu đề',
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            type : { 
                name: 'type', 
                type: "String",
                display: false
            },
            type_name : {
                    name: 'type_name',
                    type: "Selection",
                    label: 'Loại tin tức',
                    custom: 'SelectionText',
                    // width : 6,
                    options: [
                      { value: 'NEWS', label: 'Tin tức',style: 'color:  #000000;'},
                    //   { value: 'PROMOTION', label: 'Khuyến mãi',style: 'color:  #000000;'},
                      { value: "MEDIA", label: 'Video',style: 'color:  #000000;'},
                    ],
                    filter: {
                      type: 'select',
                      optionLabel: 'label',
                      optionKey: 'value',
                      match: 'equals'
                    },
                    computed: (data) => {
                      data.type = '';
                    },
            },
            created_at: {
                name: 'created_at', 
                type: "Datetime",
                label: 'Thời gian tạo',
                filter: { type: 'DateRange', match: 'contains'}
            }
        },)
    }

}