import BaseModel from '../core/BaseModel'

export default class NotificationsLangModel extends BaseModel {

    constructor() {
        super({
            tableName: 'lang_notification', label: "Quản Lý Thông Báo", display_key: 'notification_id'
        }, {
            notification_id: { 
                name: 'notification_id', 
                type: "Bigint", 
                label: 'ID', 
                display: false
            },
            title: { 
                name: 'title', 
                type: "Text", 
                label: 'Tiêu đề',  
                width: 20,
                display: false
            },
            content: { 
                name: 'content', 
                type: "Text", 
                label: 'Nội dung',
                width: 80,
            },
            short_content: { 
                name: 'short_content', 
                type: "String", 
                label: 'Nội dung',
                width: 80,
                display: false
            },
        })
    }

}