import BaseModel from '../core/BaseModel';
export default class StaffModel extends BaseModel {
    constructor() {
        super({ 
                name: "staff",
                tableName: 'staff', 
                label: "Nhân viên phụ trách", 
                display_key: 'name'
            },  
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            name : { 
                name: 'name', 
                type: "String", 
                label: 'Tên loại dịch vụ',
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            updated_at: {
                name: 'updated_at', 
                type: "Datetime",
                label: 'Thời gian cập nhật',
                filter: { type: 'DateRange', match: 'contains'}
            },
                key_get: {
                    refs: 'code',
                    display: false
                },
        },)
    }

}