    import BaseModel from '../core/BaseModel'
// import TypeServiceModel from "./TypeServiceModel";
// import CountryModel from "./CountryModel";
//     import StateModel from "./StateModel";
//     import DistrictModel from "./DistrictModel";
    // import TypeWareHouseModel from "./TypeWareHouseModel";
    import AreaTypeModel from "./AreaTypeModel";
    // import moment from "moment/moment";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
export default class AreaModel extends BaseModel {
    constructor() {
        super({
            tableName: 'area', label: " Danh Sách Khu Vực Giao Hàng", display_key: 'name',name: "area",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center width_150 common',
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },

            country: {
                refs:  ' country {  name id }',
                display: false
            },
            // country_id: {
            //     name:'country_id',
            //     label:'Quốc gia',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new CountryModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     class:"center width_150 common",
            //     computed: (data) => {
            //         data.country_id = '';
            //     },
            // },
            country_id : {
                name: 'country_id',
                type: "Computed",
                custom: 'SelectionText',
                label: 'Quốc gia',
                sortable: false,
                filter: {type: 'input', match: 'contains'},
                class:"width_90 common",
            },

            state: {
                refs:  ' state {  name id }',
                display: false
            },
            // state_id: {
            //     name:'state_id',
            //     label:'Tỉnh/ Thành Phố',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new StateModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     class:"center width_150 common",
            //     computed: (data) => {
            //         data.state_id = '';
            //     },
            // },
            state_id : {
                name: 'state_id',
                type: "Computed",
                custom: 'SelectionText',
                label: 'Tỉnh/ Thành phố',
                sortable: false,
                filter: {type: 'input', match: 'contains'},
                class:"width_90 common",
            },
            district: {
                refs:  ' district {  name id }',
                display: false
            },
            // district_id: {
            //     name:'district_id',
            //     label:'Quận/ Huyện',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new DistrictModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     class:"center width_150 common",
            //     computed: (data) => {
            //         data.district_id = '';
            //     },
            // },
            district_id : {
                name: 'district_id',
                type: "Computed",
                custom: 'SelectionText',
                label: 'Quận/ Huyện',
                sortable: false,
                filter: {type: 'input', match: 'contains'},
                class:"width_90 common",

            },
            area_type: {
                refs:  ' area_type {  name id }',
                display: false
            },
            type_id: {
                name:'type_id',
                label:' Phân Loại ',
                type: "Selection",
                custom: 'SelectionText',
                models: new AreaTypeModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                class:"center width_150 common",
                computed: (data) => {
                    data.type_id = '';
                },
            },
            distance : {
                name: 'distance',
                type: "Text",
                label: 'Khoảng cách liên tỉnh (Km)',
                filter: {type: 'input', match: 'contains'},
                class:"center width_150 common",
            },

            active: {
                name: "active",
                label: "Hiển thị",
                type: "Selection",
                custom: 'SelectionText',
                options: [
                    {value: 'true', label: 'Có', style: 'color:  #000000;'},
                    {value: 'false', label: 'Không',style: 'color:  #000000;'}
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
                class:"center width_150 common",
            },
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            updated_by_id: {
                refs:"updated_by_id { fullname}",
                type: "Selection",
                label: "Người cập nhật",
                custom: 'SelectionText',
                class:"center width_150 common",
            },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
                class:"center width_150 common",
              // filter: { type: 'DateRange', match: 'contains'},
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },




        })
    }

}