import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class RelationshipImageModel extends BaseModel {

    constructor() {
        super({
                name: "relationship_file",
                tableName: 'relationship_file',
                label: "Hình ảnh",
                /*display_key: 'fullname',
                default_order: { key: 'updated_at', direction: 'asc' } */
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
                type: {
                    name: 'type',
                    type: "String",
                    label: 'type',
                    sortable: false,
                    width: 8,
                },
                obj_id: {
                    name: 'obj_id',
                    type: "String",
                    label: 'obj_id',
                    sortable: false,
                    width: 8,
                },
                file_id: {
                    name: 'file_id',
                    type: "Uuid",
                    label: 'file_id',
                    sortable: false,
                    width: 8,
                },
            },)
    }

}