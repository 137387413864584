import BaseModel from '../core/BaseModel'
export default class AreaTypeModel extends BaseModel {
    constructor() {
        super({
            tableName: 'area_type', label: "Khu vực vận chuyển", display_key: 'name'
        }, {
            stt: { 
              name: "stt", 
              label: "No.", 
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: {
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            name : {
              name: 'name', 
              type: "Text", 
              label: 'Tên',
              filter: {type: 'input', match: 'contains'},
              // computed: (data) => {
              //   data.name = '';
              // }
          },
            updated_at : { 
              name: 'updated_at', 
              type: "Datetime", 
              label: 'Updated Date',
              filter: { type: 'DateRange', match: 'contains'},
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },
            deleted: {
              name: "deleted",
              label: "Xóa",
              type: "Checkbox",
              display: false
            }
        })
    }

}