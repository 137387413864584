import BaseModel from '../core/BaseModel';
import CompanyModel from './CompanyModel';
import UnitModel from './UnitModel';
import AreaTypeModel from './AreaTypeModel';
import Common from "../core/Common";
export default class ShippingMethodLogicticsModel extends BaseModel {
    constructor() {
        super({ 
                name: "shipping_method_logictics",
                tableName: 'shipping_method', 
                label: "Đơn Giá Dịch Vụ Logistics",
                display_key: 'name'
            },  
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                class: "xs-col",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            shipping_method_prices: {
                refs: "shipping_method_prices {id price company{id name code} }",
                display: false
            },
            // company_code: {
            //     name: 'company_code',
            //     label: 'Mã công ty',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new CompanyModel(),
            //     class: "small-col",
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.company_code = '';
            //     }
            // },
            company_code: Common.makeFilterCompany('company_code', 'Mã công ty', new CompanyModel(), "shipping_method", "small-col"),
            code : { 
                name: 'code', 
                type: "String", 
                label: 'Mã dịch vụ',
                sortable: false,
                class: "small-col",
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            name : { 
                name: 'name', 
                type: "String", 
                label: 'Tên hạng mục chi phí',
                class: "medium-col",
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            classify: {
                name: 'classify',
                label: 'Phân loại vận chuyển',
                type: "Selection",
                custom: 'SelectionText',
                // width : 6,
                class: "large-col",
                options: [
                    {value: 1, label: 'Vận chuyển thường'},
                    {value: 2, label: 'Hàng trả về không kết hợp với chuyến đi giao cùng địa điểm'},
                    {value: 3, label: 'Hàng trả về có kết hợp với chuyến đi giao cùng địa điểm'},
                    {value: 4, label: 'Giao bằng xe tải từ điểm giao thứ 2'},
                    {value: 5, label: 'Vận chuyển hàng hoá ghép chuyến với hàng của Công ty'}
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
            },
            type_vehicle: {
                name: 'type_vehicle',
                label: 'Loại phương tiện',
                type: "Selection",
                custom: 'SelectionText',
                // width : 6,
                class: "small-col",
                options: [
                    {value: 1, label: 'Xe tải'},
                    {value: 2, label: 'Xe máy'}
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
            },
            type_area: {
                refs: "area_type {id name}",
                display: false
            },
            // unit_name: {
            //     name: 'unit_name', 
            //     label: 'Đơn vị tính',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     class: "medium-col",
            //     models: new UnitModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.unit_name = '';
            //     }
            // },
            unit_name: Common.makeFilterCompany('unit_name', 'Đơn vị tính', new UnitModel(), "shipping_method", "small-col"),
            type_area_name: {
                name: 'type_area_name', 
                label: 'Khu vực giao hàng',
                type: "Selection",
                custom: 'SelectionText',
                class: "small-col",
                models: new AreaTypeModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.type_area_name = '';
                }
            },
            unit: { 
                refs: 'unit {id name}',
                display: false
            },
            active: {
                name: 'active',
                label: 'Hiển thị',
                type: "Selection",
                custom: 'SelectionText',
                // width : 6,
                class: "small-col",
                options: [
                    {value:true, label:"Có"},
                    {value:false, label:"Không"}
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
            },
            ordinal: {
                name: 'ordinal',
                display: false
            },
            user_update: {
                refs: "user_update { id fullname }",
                display: false
            },
            updated_by: {
                name: 'updated_by', 
                type: "Text",
                label: 'Người cập nhật',
                class: "small-col",
                computed: (data) => {
                    data.updated_by = '';
                }
            },
            updated_at: {
                name: 'updated_at', 
                type: "Datetime",
                label: 'Ngày cập nhật',
                class: "small-col",
                // filter: { type: 'DateRange', match: 'contains'}
            },
        },)
    }

}