import BaseModel from '../core/BaseModel'
//import UserModel from "./UserModel";
//import VehicleModel from "./VehicleModel";
//import OrderStatusModel from "@/models/OrderStatusModel";
//import gql from 'graphql-tag'
import ReasonModel from "@/models/ReasonModel";
export default class ReportDetailCostServiceModel extends BaseModel {

    constructor() {
        super({ 
                name: "report_detail_cost_service",
                tableName: 'report_detail_cost_service',
                label: "Báo Cáo Chi Tiết Chi Phí Dịch Vụ Vận Hành Logistics",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            check_box: {
                name: "check_box",
                label: " ",
                type: "Computed",
                custom: 'CheckBoxSelect',
                computed: (data) => {
                    data.stt = '';
                },
                filter: {}
                // display: { list: true, add: false, edit: false }
            },
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },/*
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Tên',
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },*/
                date_order: {
                    name: 'date_order',
                    type: "String",
                    label: 'Ngày đơn hàng',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date txt-width_common-comlumn',
                    // computed: (data) => {
                    //     data.date_order = "";
                    // }
                },
                code: {
                    name: 'code',
                    type: "String",
                    label: 'Mã đơn hàng',
                    sortable: false,
                    // width: 10,
                    class:"width txt-width_common-comlumn",
                    filter: {type: 'input', match: 'contains'},
                },
                doc_created_at: {
                    name: 'doc_created_at',
                    type: "String",
                    label: 'Ngày chứng từ',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date txt-width_common-comlumn'
                },
                doc_code: {
                    name: 'doc_code',
                    type: "String",
                    label: 'Số chứng từ',
                    sortable: false,
                    class:'width_common-comlumn',
                  //  width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                customer_name: {
                    name: 'customer_name',
                    type: "String",
                    label: 'Tên khách hàng',
                    sortable: false,
                   // width: 10,
                    filter: {type: 'input', match: 'contains'},
                    class:'width_common-comlumn'
                },
                departure: {
                    name: 'departure',
                    type: "String",
                    label: 'Nơi đi',
                    sortable: false,
                    /*width: 10,*/
                    class:'width_common-large'
                   // filter: {type: 'input', match: 'contains'},
                },
                delivery_to_freight: {
                    name: 'delivery_to_freight',
                    type: "String",
                    label: 'Giao chành',
                    sortable: false,
                  //  width: 10,
                    class:'width_common-large'
                    // filter: {type: 'input', match: 'contains'},
                },
                place_of_delivery: {
                    name: 'place_of_delivery',
                    type: "String",
                    label: 'Nơi đến',
                    sortable: false,
                 //   width: 10,
                    class:'width_common-large'
                    // filter: {type: 'input', match: 'contains'},
                },
                total_package: {
                    name: 'total_package',
                    type: "String",
                    label: 'Tổng số kiện',
                    custom: "CutomCLickB",
                    sortable: false,
                   // width: 10,
                    class:'width_common-comlumn'
                    /*filter: {type: 'input', match: 'contains'},*/
                },
                package_code: {
                    name: 'package_code',
                    type: "String",
                    label: 'Mã ghép kiện',
                    sortable: false,
                //    width: 10,
                    filter: {type: 'input', match: 'contains'},
                    class:'width_common-comlumn'
                },
                delivery_trip_code: {
                    name: 'delivery_trip_code',
                    type: "String",
                    label: 'Mã chuyến giao',
                    sortable: false,
                 //   width: 10,
                    filter: {type: 'input', match: 'contains'},
                    class:'width_common-comlumn'
                },
                /*delivery_trip_number: {
                    name: 'delivery_trip_number',
                    type: "String",
                    label: 'Số chuyến giao',
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },*/
                delivery_trip_number_key: {
                    name: "delivery_trip_number_key",
                    label: "Số chuyến giao",
                    class: 'center txt-width_common-comlumn',
                    custom: "CutomCLickA",
                    computed: (data) => {
                        /*var total = 0;
                        if (data.order_shipping_items_aggregate ){
                            if (data.order_shipping_items_aggregate.aggregate ){
                                if (data.order_shipping_items_aggregate.aggregate.count ){
                                    total = data.order_shipping_items_aggregate.aggregate.count
                                }
                            }

                        }*/
                        data.delivery_trip_number_key = data.delivery_trip_number;
                        console.log("wqwqwqwqwqwqw",data.delivery_trip_number_key)
                    },
                    filter: {type: 'input', match: 'contains'},
                },
                delivery_vehicle_root: {
                    name: 'delivery_vehicle_root',
                    type: "String",
                    label: 'Phương tiện giao hàng GỐC',
                    sortable: false,
                   // width: 10,
                    class: 'width_common-comlumn',
                    filter: {type: 'input', match: 'contains'},
                },
                delivery_vehicle: {
                    name: 'delivery_vehicle',
                    type: "String",
                    label: 'Phương tiện giao hàng',
                    sortable: false,
                  //  width: 10,
                    class: 'width_common-comlumn',
                    filter: {type: 'input', match: 'contains'},
                },
                date_done: {
                    name: 'date_done',
                    type: "String",
                    label: 'Ngày giao thành công',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date txt-width_common-comlumn'
                },
               /* reasons_failure: {
                    name: 'reasons_failure',
                    type: "String",
                    label: 'Lý do giao không thành công/ hủy/ trả hàng',
                    sortable: false,
                 //   width: 10,
                    class: 'width_common-comlumn',
                },*/
                reasons_failure_key: {
                    name: 'reasons_failure_key',
                    type: "Selection",
                    custom: "SelectionText",
                   // width: 150,
                    label: 'Lý do giao không thành công/ hủy/ trả hàng',
                    models:  new ReasonModel(),
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals"
                    },
                    computed: (data) => {
                        data.reasons_failure_key =  (data.reasons_failure) ? data.reasons_failure:'';
                    }
                },
                company_pay_text: {
                    name: 'company_pay_text',
                    type: "String",
                    label: 'Lê Mây chịu phí',
                    sortable: false,
                 //   width: 10,
                    class: 'width_common-comlumn',
                },
                shipping_service_price: {
                    name: 'shipping_service_price',
                    type: "String",
                    label: 'Dịch vụ vận chuyển',
                    sortable: false,
                  //  width: 10,
                    class: 'width_common-comlumn',
                },
                shipping_truck_price: {
                    name: 'shipping_truck_price',
                    type: "String",
                    label: 'Giao bằng xe tải từ điểm giao thứ 2',
                    sortable: false,
                //    width: 10,
                    class: 'width_common-comlumn',
                },
                goods_returned_price: {
                    name: 'goods_returned_price',
                    type: "String",
                    label: 'Hàng trả về',
                    sortable: false,
                  //  width: 10,
                    class: 'width_common-comlumn',
                },
                combine_flights_company: {
                    name: 'combine_flights_company',
                    type: "String",
                    label: 'Ghép chuyến với hàng của Lê Mây',
                    sortable: false,
                   // width: 10,
                    class: 'width_common-comlumn',
                },
                transportation_service_npp_price: {
                    name: 'transportation_service_npp_price',
                    type: "String",
                    label: 'Dịch vụ vận chuyển từ chành đến NPP',
                    sortable: false,
                    class: 'width_common-comlumn',
                  //  width: 10,
                },
                handling_fee_price: {
                    name: 'handling_fee_price',
                    type: "String",
                    label: 'Dịch vụ bốc xếp',
                    sortable: false,
                    class: 'width_common-comlumn',
                  //  width: 10,
                },
                send_return_goods_post: {
                    name: 'send_return_goods_post',
                    type: "String",
                    label: 'Gửi/ Trả hàng bằng bưu điện',
                    sortable: false,
                    class: 'width_common-comlumn',
                  //  width: 10,
                },
                order_processing_fee: {
                    name: 'order_processing_fee',
                    type: "String",
                    label: 'Chí phí xử lý đơn hàng',
                    sortable: false,
                    class: 'width_common-comlumn',
                  //  width: 10,
                },
                total_cost: {
                    name: 'total_cost',
                    type: "String",
                    label: 'Tổng chi phí',
                    sortable: false,
                    class: 'width_common-comlumn',
                  //  width: 10,
                },
                updated_by_name: {
                    name: 'updated_by_name',
                    type: "String",
                    label: 'Người cập nhật',
                    sortable: false,
                    class: 'width_common-comlumn',
                  //  width: 10,
                },
                updated_at_hso: {
                    name: 'updated_at_hso',
                    type: "String",
                    label: 'Ngày cập nhật',
                    //filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date txt-width_common-comlumn'
                },
                is_lock_key: {
                    name: "is_lock_key",
                    label: " ",
                    type: "Computed",
                    custom:"icon",
                    computed: (data) => {
                        data.is_lock_key = "";
                    },
                    //display:false
                    // display: { list: true, add: false, edit: false }
                },

        },)
    }

}