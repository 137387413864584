import BaseModel from '../core/BaseModel'
//import UnitModel from "./UnitModel";
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class HistoryStatusOrderModel extends BaseModel {

    constructor() {
        super({ 
                name: "history_status_order",
                tableName: 'history_status_order',
                label: "Danh Sách Đơn hàng",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
          /*  check_box: {
                name: "check_box",
                label: " ",
                type: "Computed",
                custom: 'CheckBoxSelect',
                computed: (data) => {
                    data.stt = '';
                },
                filter: {}
                // display: { list: true, add: false, edit: false }
            },*/
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                order_status: {
                    refs: 'order_status {id name}',
                    display: false
                },
                reason: {
                    refs: 'reason {id name}',
                    display: false
                },
                obj_created_by: {
                    refs: 'obj_created_by {id fullname}',
                    display: false
                },
                content: {
                    name: 'content',
                    type: "String",
                    label: 'content',
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                created_at: {
                    name: 'created_at',
                    type: "Date",
                    label: 'Ngày tạo',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date'
                },
                created_by: {
                    name: 'created_by',
                    type: "Text",
                    label: 'Người tạo',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date',
                    display:false
                },
                key_get: {
                    refs: 'code_status name_process_custom reason{id name}  obj_created_by { department { name } } ',
                    display: false
                },


        },)
    }

}