import BaseModel from '../core/BaseModel'
//import ServiceModel from "@/models/ServiceModel";
/*import constants from '../core/Constants';

var getContants = constants.getConstans();*/
//import gql from 'graphql-tag'
export default class WithdrawalHistoryModel extends BaseModel {

    constructor() {
        super({
                name: "controller",
                tableName: 'controller',
                label: "Yêu cầu rút tiền",
                /*display_key: 'fullname',
                default_order: {key: 'updated_at', direction: 'asc'}*/
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
                id: {
                    name: 'id',
                    type: "Bigint",
                    label: 'ID',
                    primaryKey: true,
                    // edit: {
                    //     add: false,
                    //     edit: false
                    // },
                    display: false
                },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Mã yêu cầu',
                    sortable: false,
                    width: 8,
                    display: false
                },
                icon: {
                    name: 'icon',
                    type: "String",
                    label: 'Mã yêu cầu',
                    sortable: false,
                    width: 8,
                    display: false
                },
                url: {
                    name: 'url',
                    type: "String",
                    label: 'Mã yêu cầu',
                    sortable: false,
                    width: 8,
                    display: false
                },
                _key: {
                    name: '_key',
                    type: "String",
                    label: 'Mã yêu cầu',
                    sortable: false,
                    width: 8,
                    display: false
                },
                parent_tab: {
                    name: 'parent_tab',
                    type: "Int",
                    label: 'ID',
                    display: false
                },
                tab_service: {
                    name: 'tab_service',
                    type: "Int",
                    label: 'ID',
                    display: false
                },
                is_parent: {
                    name: 'is_parent',
                    type: "Int",
                    label: 'is_parent',
                    display: false
                },
                childs: {
                    refs: `childs (order_by: {ordinal: desc}, where: {active: {_eq: true}, deleted: {_eq: false}}) {
                          id
                          name
                          
                          url
                          
                        }`,
                    display: false
                },

                // icon
                // roles (order_by: {id: asc}, where: {active: {_eq: true}, deleted: {_eq: false}}) {
                //     value:id
                //     text:name
                //     type
                //     id
                //     name
                //     list_table
                //   }
                roles: {
                    refs: `roles (order_by: {id: asc}, where: {active: {_eq: true}, deleted: {_eq: false}}) {
                          value:id
                          text:name
                          type
                          id
                          name
                          list_table
                        }`,
                    display: false
                }

            },)
    }

}