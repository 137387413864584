import BaseModel from "../core/BaseModel";
// import TypeReasonModel from "@/models/TypeReasonModel";
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class VehicleCompanyModel extends BaseModel {
  constructor() {
    super(
      {
        name: "vehicle_company",
        tableName: "vehicle_company",
        label: "Danh Sách",
        display_key: "id",
        //default_order: { key: "updated_at", direction: "asc" },
      },
      {
        stt: {
          name: "stt",
          label: "STT",
          type: "Computed",
          class: "xs-col",
          computed: (data, index) => {
            data.stt = index + 1;
          },
          // display: { list: true, add: false, edit: false }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          width: 1,
          primaryKey: true,
          display: false,
        }
      }
    );
  }
}
