import BaseModel from '../core/BaseModel'
// import CountryModel from "./CountryModel";
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
export default class OrderShippingPackageModel extends BaseModel {
    constructor() {
        super({
            tableName: 'order_shipping_item', label: "Danh sách kiện hàng", display_key: 'id',name: "order_shipping_package",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center',
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            text_name: {
                name: "text_name",
                label: "Tên kiện hàng",
                class: 'center',
                computed: (data) => {
                    data.text_name = 'Giao hàng';
                },
                //filter: {type: 'input', match: 'contains'},
            },
            text_count: {
                name: "text_count",
                label: "Số lượng",
                class: 'center',
                computed: (data) => {
                    data.text_name = '0';
                },
                //filter: {type: 'input', match: 'contains'},
            },
            key_get: {
                refs: ' order_group_parcel_id order_group_parcel { order_parcels { code parcel { name id } number } }',
                display: false
            },
        })
    }

}