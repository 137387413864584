import BaseModel from "../core/BaseModel";
export default class DistrictModel extends BaseModel {
  constructor() {
    super(
      {
        name: "district",
        tableName: "district",
        label: "Tỉnh thành",
        display_key: "name",
      },
      {
        id: {
          name: "id",
          type: "Number",
          label: "ID",
          primaryKey: true,
          display: false,
        },

        name: {
          name: "name",
          type: "Text",
          label: "Tên Quận Huyện",
          filter: { type: "input", match: "contains" },
        },
        plain_name: {
          name: "plain_name",
          type: "String",
          label: "Quận huyện",
          filter: { type: "input", match: "contains" },
        },
        fullname: {
          name: "fullname",
          type: "String",
          label: "Tên Quận Huyện",
          filter: { type: "input", match: "contains" },
        },
        ordinal: {
          name: "ordinal",
          type: "Number",
          label: "Thứ tự",
        },
        updated_at: {
          name: "updated_at",
          type: "Datetime",
          label: "Ngày cập nhật",
          filter: { type: "DateRange", match: "contains" },
        },
        created_at: {
          name: "created_at",
          type: "Datetime",
          label: "Ngày tạo",
          filter: { type: "DateRange", match: "contains" },
          display: false,
        },
        created_by: {
          name: "created_by",
          type: "Uuid",
          label: "Người tạo",
          display: false,
        },
        updated_by: {
          name: "updated_by",
          type: "Uuid",
          label: "Người cập nhật",
          display: false,
        },
        active: {
          name: "active",
          type: "Boolean",
          label: "Hoạt động",
          filter: { type: "Boolean", match: "contains" },
        },
        deleted: {
          name: "deleted",
          label: "Xóa",
          type: "Checkbox",
          display: false,
        },
      }
    );
  }
}
