import BaseModel from '../core/BaseModel'

export default class NotificationsModel extends BaseModel {

    constructor() {
        super({
            tableName: 'notification', label: "Thông báo", display_key: 'id'
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Bigint", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            type_id: {
                name: "type_id",
                label: "Receiver",
                type: "Selection",
                width : 6,
                options: [
                    {value: 1, label: 'Tất cả người dùng', key : 'ALL',style: 'color:  #000000;'},
                    {value: 3, label: 'Người dùng', key : 'USER',style: 'color:  #000000;'}
                ],
                filter: {
                  type: 'select',
                  optionLabel: 'label',
                  optionKey: 'value',
                  match: 'equals'
                },
                display:false
            },
            title_en : {
                name: 'title_en', 
                type: "Text", 
                label: 'Tiêu đề',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                  data.title_en = '';
                },
            },
            short_content_en : {
                name: 'short_content_en', 
                type: "Text", 
                label: 'Short description',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                  data.short_content_en = '';
                },
                display:false
            },
            action_key : {
                name: 'action_key', 
                type: "String", 
                label: 'Hành động',
                display: false
            },
            is_push: {
                name: 'is_push', 
                type: 'Boolean', 
                label: 'Đã gửi',
                display: false
            },
            is_admin: {
                name: 'is_admin', 
                type: 'Boolean', 
                label: 'Hiển thị trên admin',
                display: false
            },
            type: { 
                name: 'type', 
                type: "String", 
                label: 'Type',  
                width: 20,
                display: false
            },
            created_at: { 
                name: 'created_at', 
                type: "Datetime", 
                width: 15,
                class: 'center',
                label: 'Thời gian tạo',
                filter: { type: 'DateRange', match: 'contains'},
            },
            updated_at: { 
                name: 'updated_at', 
                type: "CustomDate", 
                width: 15,
                class: 'center',
                label: 'Ngày cập nhật',  
                display: false
            },
            rel_lang: {
                refs: `notification_lang{lang title content short_content}`,
                display: false
            },

        })
    }

}