import BaseModel from '../core/BaseModel'

export default class DeliveryCostsModel extends BaseModel {
    constructor() {
        super({
            tableName: 'delivery_costs', label: "địa chỉ giao hàng", display_key: 'code'
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            code: { 
                name: 'code', 
                type: "String", 
                label: 'Tiêu đề',  
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            key_get: {
                refs: 'is_lock history_status_order_id note_delivery ',
                display: false
            },
            
        })
    }

}