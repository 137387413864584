import BaseModel from '../core/BaseModel'

export default class GroupCustomerDocumentModel extends BaseModel {

    constructor() {
        super({
                name: "group_customer_document",
                tableName: 'group_customer_document',
                label: "Danh Sách Đơn hàng",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } },
            {
                key_get1: {
                    refs:  'customer_id total_qty_delivery total_return_quantity ',
                    display: false
                },


        },)
    }

}