import BaseModel from '../core/BaseModel';
// import MaterialModel from "./MaterialModel";
// import OrderStatusModel from "./OrderStatusModel";
export default class ReportOrderCategoryModel extends BaseModel {
    constructor() {
        super({ 
                name: "report_order_category", tableName: 'order_item', label: "Báo Cáo Hạng Mục Đơn Hàng", display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    width: 50,
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            order: {
                refs: "order {code doc_code doc_created_at date_order type description paid customer_code customer{request_pay} order_status{code, name} history_status_orders{code_status reason{cancel_at_warehouse}} delivery_costs{id}}",
                display: false
            },
            warehouse_import: {
                refs: "warehouse{code name}",
                display: false
            },
            warehouse_export: {
                refs: "warehouseByWarehouseId{code name}",
                display: false
            },
            unit: {
                refs: "unit{name}",
                display: false
            },
            material: {
                refs: "material{name code}",
                display: false
            },
            type_order: {
              name: "type_order",
              width: 126,
              label: "Loại đơn hàng",
              type: "Selection",
              custom: 'SelectionText',
              options: [{value:1,label:"Đơn nhập"},{value:2,label:"Đơn xuất"},{value:3, label:"Chuyển kho"}],
              filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
              computed: (data) => {
                data.type_order = '';
              }
            },
            product: { 
                refs: 'product {id name code}',
                display: false
            },
            date_order: {
                name: 'date_order',
                type: "Date",
                width: 126,
                label: 'Ngày đơn hàng',
                filter: { type: 'Date', match: 'contains'},
                computed: (data) => {
                    data.date_order = '';
                }
            },
            order_code : { 
                name: 'order_code', 
                type: "String", 
                label: 'Mã đơn hàng',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.order_code = '';
                }
            },
            doc_created_at : { 
                name: 'doc_created_at', 
                type: "Date", 
                label: 'Ngày chứng từ',
                width: 126,
                sortable: false,
                filter: { type: 'Date', match: 'contains'},
                computed: (data) => {
                    data.doc_created_at = '';
                }
            },
            doc_code : { 
                name: 'doc_code', 
                type: "String", 
                label: 'Số chứng từ',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.doc_code = '';
                }
            },
            description : { 
                name: 'description', 
                type: "String", 
                label: 'Diễn giải',
                width: 300,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.description = '';
                }
            },
            customer_code : { 
                name: 'customer_code', 
                type: "String", 
                label: 'Mã nhà cung cấp/ Mã khách hàng',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.customer_code = '';
                }
            },
            product_code : { 
                name: 'product_code', 
                type: "String", 
                label: 'Mã sản phẩm/ Mã hàng',
                width: 150,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                }
            },
            product_name : { 
                name: 'product_name', 
                type: "String", 
                label: 'Tên sản phẩm/ Tên hàng',
                width: 252,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                }
            },
            unit_name : { 
                name: 'unit_name', 
                type: "String", 
                label: 'Đơn vị tính',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.unit_name = '';
                }
            },
            lot_number : { 
                name: 'lot_number', 
                type: "String", 
                label: 'Mã lô',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            
            expiry_date : { 
                name: 'expiry_date', 
                type: "Date", 
                width: 126,
                label: 'Hạn sử dụng'
            },
            material_code : { 
                name: 'material_code', 
                label: 'Mã nhóm VTHH',
                width: 126,
                type: "String",
                // type: "Selection",
                // custom: 'SelectionText',
                // models: new MaterialModel('code'),
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.material_code = '';
                }
            },
            material_name : { 
                name: 'material_name', 
                type: "String", 
                label: 'Nhóm VTHH',
                sortable: false,
                width: 126,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.material_name = '';
                }
            },
            
            warehouse_export_code : { 
                name: 'warehouse_export_code', 
                type: "String", 
                label: 'Mã kho (xuất)',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.warehouse_export_code = '';
                }
            },
            
            warehouse_export_name : { 
                name: 'warehouse_export_name', 
                type: "String", 
                label: 'Tên kho (xuất)',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.warehouse_export_name = '';
                }
            },
            
            warehouse_import_code : { 
                name: 'warehouse_import_code', 
                type: "String", 
                label: 'Mã kho (nhập)',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.warehouse_import_code = '';
                }
            },
            
            warehouse_import_name : { 
                name: 'warehouse_import_name', 
                type: "String", 
                label: 'Tên kho (nhập)',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.warehouse_import_name = '';
                }
            },
            
            import_qty: {
                width: 126,
                name: 'import_qty', 
                type: "Number",
                class: 'right',
                label: 'SL nhập trong kỳ',
                display: false
            },
            export_qty: {
                width: 126,
                name: 'export_qty', 
                type: "Number",
                class: 'right',
                label: 'SL xuất trong kỳ',
                display: false
            },
            
            qty_export_txt: {
                width: 126,
                name: 'qty_export_txt', 
                type: "String",
                class: 'right',
                label: 'SL xuất',
                filter: { type: 'number', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.qty_export_txt = 0;
                }
            },
            qty_import_txt: {
                width: 126,
                name: 'qty_import_txt', 
                type: "String",
                class: 'right',
                label: 'SL nhận',
                filter: { type: 'number', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.qty_import_txt = 0;
                }
            },
            order_status: {
                width: 126,
                name: 'order_status', 
                type: "Selection",
                custom: 'SelectionText',
                label: 'Tình trạng đơn hàng',
                // models: new OrderStatusModel(),
                options: [
                    {label: "KH hủy đơn nhập lại kho",value: 1},
                    {label: "Hủy đơn", value: 2},
                    {label: "Chờ thanh toán", value: 3},
                    {value: 4, label: "Chưa soạn hàng"},
                    {value:5, label: "Đang soạn hàng"},
                    {value:6, label: "Chờ xuất"},
                    {value: 7, label:"Đã xuất"}
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.order_status = 0;
                }
            },
            delivery_status: {
                width: 126,
                name: 'delivery_status', 
                type: "Selection",
                custom: 'SelectionText',
                label: 'Tình trạng giao hàng',
                // models: new OrderStatusModel(),
                options: [
                    {label: "KH hủy đơn nhập lại kho",value: 1},
                    {label: "Hủy đơn", value: 2},
                    {value:3, label: "Chưa giao hàng"},
                    {value: 4, label:"Giao hàng thành công"},
                    {value: 5, label:"Đang giao hàng lần 1"},
                    {value: 6, label: "Giao không thành công lần 1"},
                    {value: 7, label: "Đang giao hàng lần 2"},
                    {value: 8, label: "Giao không thành công lần 2"},
                    {value: 9, label: "Đang giao hàng lần 3"},
                    {value: 10, label: "Giao không thành công lần 3"}
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.delivery_status = 0;
                }
            },
        },)
    }

}