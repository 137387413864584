import BaseModel from "../core/BaseModel";
export default class CountryModel extends BaseModel {
  constructor() {
    super(
      {
        name: "country",
        tableName: "country",
        label: "Quốc gia",
        display_key: "name",
      },
      {
        id: {
          name: "id",
          type: "Number",
          label: "ID",
          primaryKey: true,
          display: false,
        },

        name: {
          name: "name",
          type: "Text",
          label: "Tên Quốc Gia",
          filter: { type: "input", match: "contains" },
        },
        plain_name: {
          name: "plain_name",
          type: "String",
          label: "Quốc gia",
          filter: { type: "input", match: "contains" },
        },
        /*fullname: {
                    name: "fullname",
                    type: "String",
                    label: "Tên Quốc Gia",
                    filter: { type: "input", match: "contains" },
                },*/
        ordinal: {
          name: "ordinal",
          type: "Number",
          label: "Thứ tự",
        },
        updated_at: {
          name: "updated_at",
          type: "Datetime",
          label: "Ngày cập nhật",
          filter: { type: "DateRange", match: "contains" },
        },
        created_at: {
          name: "created_at",
          type: "Datetime",
          label: "Ngày tạo",
          filter: { type: "DateRange", match: "contains" },
          display: false,
        },
        active: {
          name: "active",
          type: "Boolean",
          label: "Hoạt động",
          filter: { type: "Boolean", match: "contains" },
        },
        deleted: {
          name: "deleted",
          label: "Xóa",
          type: "Checkbox",
          display: false,
        },
      }
    );
  }
}
