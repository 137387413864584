import BaseModel from '../core/BaseModel'
import UserModel from "./UserModel";
import VehicleModel from "./VehicleModel";
import OrderStatusModel from "@/models/OrderStatusModel";
import Common from "@/core/Common";
//import gql from 'graphql-tag'
export default class OrderDetailModel extends BaseModel {

    constructor() {
        super({ 
                name: "order_detail",
                tableName: 'order',
                label: "Danh Sách Đơn hàng",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            check_box: {
                name: "check_box",
                label: " ",
                type: "Computed",
                custom: 'CheckBoxSelect',
                computed: (data) => {
                    data.stt = '';
                },
                filter: {}
                // display: { list: true, add: false, edit: false }
            },
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                class: "xs-col",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },/*
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Tên',
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },*/
                date_order: {
                    name: 'date_order',
                    type: "Date",
                    label: 'Ngày đơn hàng',
                    class: "medium-col style-text-date",
                    filter: { type: 'DateRange', match: 'contains'},
                },
                code: {
                    name: 'code',
                    type: "String",
                    label: 'Mã đơn hàng',
                    sortable: false,
                    class: "medium-col txt-mi-medium-col",
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                doc_created_at: {
                    name: 'doc_created_at',
                    type: "Date",
                    label: 'Ngày chứng từ',
                    class: "medium-col style-text-date",
                    filter: { type: 'DateRange', match: 'contains'},
                },
                doc_code: {
                    name: 'doc_code',
                    type: "String",
                    label: 'Số chứng từ',
                    class: "medium-col txt-mi-medium-col",
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                customer_code: {
                    name: 'customer_code',
                    type: "String",
                    label: 'Mã khách hàng',
                    class: "large-col",
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                customer_name: {
                    name: 'customer_name',
                    type: "String",
                    label: 'Tên khách hàng',
                    class: "large-col",
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                current_order_status_id: {
                    name:'current_order_status_id',
                    label:'Trạng thái',
                    type: "Selection",
                    custom: 'SelectionText',
                    class: "medium-col",
                    models: new OrderStatusModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.current_order_status_id = '';
                    },
                },
                order_status: {
                    refs: 'order_status { id code name }',
                    display: false
                },
                date_expected: {
                    name: 'date_expected',
                    type: "Date",
                    custom: 'SelectionText',
                    label: 'Ngày giao dự kiến',
                    class: "medium-col style-text-date",
                    filter: { type: 'DateRange', match: 'contains'},
                },
                /*total_certification_documents: {
                    name: "total_certification_documents",
                    label: "HSCT nhận",
                    type: "Computed",
                    computed: (data) => {
                        data.total_certification_documents = 0;
                    },
                },*/
                total_number_request: {
                    name: "total_number_request",
                    type: "Selection",
                    label: "HSCT nhận",
                    custom: "SelectionText",
                    sortable: false,
                    disabled: true,
                    class: "medium-col",
                    options: [
                        { value: true, label: "Đủ", style: "color:  #000000;" },
                        { value: false, label: "Không đủ", style: "color:  #000000;" },
                    ],
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    },
                    // display: false
                },
                total_package: {
                    name: 'total_package',
                    type: "String",
                    label: 'Tổng số kiện',
                    class: "medium-col",
                    sortable: false,
                    width: 10,
                    /*filter: {type: 'input', match: 'contains'},*/
                },
                /*total_deliveries: {
                    name: "total_deliveries",
                    label: "Lần giao thứ",
                    type: "Computed",
                    computed: (data) => {
                        data.total_number_packages = 0;
                    },
                },*/
                total_deliveries: {
                    name: "total_deliveries",
                    label: "Lần giao thứ",
                    type: "Selection",
                    custom: "SelectionText",
                    class: "medium-col",
                    // models: new TypeReasonModel(),
                    options: [
                        { value: "1", label: "1", style: "color:  #000000;" },
                        {
                            value: "2",
                            label: "2",
                            style: "color:  #000000;",
                        },
                        { value: "3", label: "3", style: "color:  #000000;" },
                    ],
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    },
                    // computed: (data) => {
                    //   data.type = "",
                    // },
                },
                // delivery_personnel: {
                //     name:'delivery_personnel',
                //     label:'Nhân viên giao',
                //     type: "Selection",
                //     custom: 'SelectionText',
                //     class: "medium-col",
                //     models: new UserModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     computed: (data) => {
                //         if (data.order_shipping_item){
                //             if (data.order_shipping_item.order_shipping) {
                //                 if (data.order_shipping_item.order_shipping.user) {
                //                     data.delivery_personnel = data.order_shipping_item.order_shipping.user.fullname;
                //                 }
                //             }
                //         }

                //     },
                // },
                delivery_personnel: Common.makeFilterCompany('delivery_personnel', 'Nhân viên giao', new UserModel(), 'order', 'medium-col', true),
                vehicle_delivery: {
                    name:'vehicle_delivery',
                    label:'Phương tiện giao',
                    type: "Selection",
                    custom: 'SelectionText',
                    class: "medium-col",
                    models: new VehicleModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.vehicle_delivery = ''
                    },
                },
                /*address_delivery: {
                    name: "address_delivery",
                    label: "Địa chỉ điều phối",
                    type: "Computed",
                    computed: (data) => {
                        if (data.order_shipping_item){
                            if (data.order_shipping_item.freight) {
                                    data.address_delivery = data.order_shipping_item.freight.address;
                            }
                        }
                        /!*if (data.address_delivery1){
                            data.address_delivery = data.address_delivery1;
                        }else {
                            data.address_delivery = '';
                        }*!/

                    },
                    filter: {type: 'input', match: 'contains'},
                    // display: { list: true, add: false, edit: false }
                },*/

                suggest_delivery_type: {
                    name: "suggest_delivery_type",
                    type: "Selection",
                    label: "Loại giao hàng",
                    //custom: "SelectionText",
                    sortable: false,
                    disabled: true,
                    class: "medium-col",
                    options: [
                        { value: 1, label: "Giao hàng đến địa chỉ khách" },
                        { value: 2, label: "Giao hàng đến chành" },
                        { value: 3, label: "Gửi bưu điện" },
                        { value: 4, label: "Tự lấy hàng" }
                    ],
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    },
                    // display: false
                },
                suggest_address_customer: {
                    name: 'suggest_address_customer',
                    type: "String",
                    label: 'Địa chỉ điều phối',
                    class: "large-col",
                   // display: true,
                   sortable: true,
                    filter: {type: 'input', match: 'contains'},
                },
                vehicle: {
                    refs: 'vehicle { id name }',
                    display: false
                },
                vehicle_id: {
                    name: 'vehicle_id',
                    type: "Bigint",
                    label: 'Phương tiện giao',
                    display: false
                },
                updated_at: {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Lần cập nhật cuối',
                    // filter: { type: 'DateRange', match: 'contains'},
                    class:'medium-col style-text-date'
                },
                /*hide column*/
                /*customer_code: {
                    name: 'customer_code',
                    type: "String",
                    label: 'customer_code',
                    display: false
                },*/
                customer_phone: {
                    name: 'customer_phone',
                    type: "String",
                    label: 'customer_phone',
                    display: false
                },
                customer_address: {
                    name: 'customer_address',
                    type: "String",
                    label: 'customer_address',
                    display: false
                },
                description: {
                    name: 'description',
                    type: "String",
                    label: 'description',
                    display: false
                },
               /* date_order: {
                    name: 'date_order',
                    type: "Datetime",
                    label: 'date_order',
                    filter: { type: 'DateRange', match: 'contains'},
                    display: false
                },*/
                date_done: {
                    name: 'date_done',
                    type: "Datetime",
                    label: 'date_done',
                    filter: { type: 'DateRange', match: 'contains'},
                    display: false
                },
                request_payment: {
                    name: 'request_payment',
                    type: "String",
                    label: 'explain',
                    display: false
                },
                date_paid: {
                    name: 'date_paid',
                    type: "Datetime",
                    label: 'date_paid',
                    filter: { type: 'DateRange', match: 'contains'},
                    display: false
                },
                user_id: {
                    name: 'user_id',
                    type: "String",
                    label: 'user_id',
                    display: false
                },
                /*total_number_request: {
                    name: 'total_number_request',
                    type: "Bigint",
                    label: 'total_number_request',
                    display: false
                },*/
                total_number_process: {
                    name: 'total_number_process',
                    type: "Bigint",
                    label: 'total_number_process',
                    display: false
                },
                key_get: {
                    refs: 'note_delivery note_profile paid order_group_parcel_id customer_id staff_name customer { group_channel { code channel { code } } }',
                    display: false
                },
                key_get1: {
                    refs:  'fee_postage customer_delivery_id address order_group_parcel{ code_root } total_price staff_id created_at suggest_freight_id obj_suggest_freight{ code name} suggest_delivery_type confirm_receipt order_shipping_item{  order_shipping{vehicle_delivery_id{name} user{fullname}} delivery_type freight{code name}} ',
                    display: false
                },


        },)
    }

}