import BaseModel from '../core/BaseModel'
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class OrderDocumentModel extends BaseModel {

    constructor() {
        super({ 
                name: "order_document",
                tableName: 'order_document',
                label: "Danh Sách Đơn hàng",
                display_key: 'id',
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            check_box: {
                name: "check_box",
                label: " ",
                type: "Computed",
                custom: 'CheckBoxSelect',
                computed: (data) => {
                    data.stt = '';
                },
                filter: {}
                // display: { list: true, add: false, edit: false }
            },
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                number_request: {
                    name: 'number_request',
                    type: "String",
                    label: 'Tổng số kiện',
                    sortable: false,
                    width: 10,
                    /*filter: {type: 'input', match: 'contains'},*/
                },
                number_process: {
                    name: 'number_process',
                    type: "String",
                    label: 'Tổng số kiện',
                    sortable: false,
                    width: 10,
                    /*filter: {type: 'input', match: 'contains'},*/
                },
                document: {
                    refs: 'document {  name code id  }',
                    display: false
                },


        },)
    }

}