import BaseModel from '../core/BaseModel';
import TypeServiceModel from './TypeServiceModel';
export default class QuestionModel extends BaseModel {
    constructor() {
        super({ 
                name: "questions",
                tableName: 'questions', 
                label: "Câu hỏi thêm",
                display_key: 'name'
            },  
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            name : {
                name: 'name', 
                type: "String", 
                label: 'Câu hỏi',
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            type_service_name: {
                name: 'type_service_name', 
                label: 'Loại dịch vụ',
                type: "Selection",
                custom: 'SelectionText',
                models: new TypeServiceModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.type_service_name = '';
                }
            },
            type_service: { 
                refs: 'question_services {type_service {id name}}',
                display: false
            },
            ordinal : {
                    name: 'ordinal',
                    type: "Int",
                    label: 'Số thứ tự',
                    sortable: false,
                    // display: false
            },
            updated_at: {
                name: 'updated_at', 
                type: "Datetime",
                label: 'Cập nhật lúc',
                filter: { type: 'DateRange', match: 'contains'}
            }
        },)
    }

}