//import axios from 'axios'
import {createProvider} from '../vue-apollo'
//import Vue from 'vue';
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
//import Config from "@/config";
import Common from './Common';
import GetConstants from './Constants';

var Constants = GetConstants.getConstans();
import ApiRepository from "@/core/ApiRepository";
import AuthenticationApp from "@/core/AuthenticationApp";
import store from '@/store'
import gql from "graphql-tag";
import moment from "moment-timezone";
import Cryptojs from 'crypto-js'
import configJson from '../../config';

export default {
    async getTypeOrderByCode(code_order) {
        var prefix_code = code_order.substring(0, 2);
        var CODE_INPUT = [Constants.REFIX_CODE_ORDER_RETRUN,'NK','MH','PN','GN']
        var CODE_EXPORT = ["BH",'BT','PX','DH','XK','GX','TL','HS','HN']
        var CODE_WAREHOUSE_TRANSFER = ['CK']
        var check_input = CODE_INPUT.indexOf(prefix_code);
        var check_export = CODE_EXPORT.indexOf(prefix_code);
        var check_warehouse = CODE_WAREHOUSE_TRANSFER.indexOf(prefix_code);
        var type_order = 0;
        if (check_input !== -1){
            //đơn nhập
            type_order = 1;
        }else if (check_export !== -1){
            //đơn xuất
            type_order = 2;
        }else if (check_warehouse !== -1){
            //chuyển kho
            type_order = 3;
            //Lấy 1 đơn trong chuyển kho xong là nhập hay xuất
        }
        return type_order;
    },
    async flow_order(code_order) {
        var prefix_code = code_order.substring(0, 2);
        //các đơn hàng ko giao hàng
        var NOT_DELIVERY = ['GX','TL','NK','MH','PN','GN']
        //Các đơn hàng trả hàng
        var RETURN = [Constants.REFIX_CODE_ORDER_RETRUN]

        var check_not_delivery = NOT_DELIVERY.indexOf(prefix_code);
        var check_return = RETURN.indexOf(prefix_code);
        //var check_warehouse = CODE_WAREHOUSE_TRANSFER.indexOf(prefix_code);
        var type_order = 0;
        if (check_not_delivery !== -1){
            //các đơn hàng ko giao hàng
            type_order = 1;
        }else if (check_return !== -1){
            //Các đơn hàng trả hàng
            type_order = 2;
        }else {
            //còn lại
            type_order = 3;

        }
        return type_order;
    },
   async check_type_order(code_order,STATUS_NEXT_ORDER,code_current,code_change) {
        var type_order = await this.flow_order(code_order);
        var list_status  = [];
        if (type_order == 2){
            list_status =  STATUS_NEXT_ORDER[code_current]["list"]["returns"]
        }else {
            list_status =  STATUS_NEXT_ORDER[code_current]["list"]["other"]
        }
        console.log("code_change:",code_change)
        console.log("list_status:",type_order)
        console.log("list_status:",STATUS_NEXT_ORDER[code_current]["list"]["other"])
        var is_success = 0;
        for (var i=0;i<list_status.length;i++){
            var item_data = list_status[i];
            if (item_data.code_change == code_change){
                is_success = 1;
                break;
            }
        }
        return is_success
    },
    getFileName(file) {
        if (file.length) {
            var arrSplit = file.split('/')
            return arrSplit[arrSplit.length - 1];
        }
    },
    baseurl() {
        var envConfig = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') ? process.env.NODE_ENV:'development';
        const baseUrl = configJson[envConfig].base_url_api;
        return baseUrl;
    },
    async getImage(type, obj_id, key_list) {
        return type + obj_id + key_list;
    },
    async getFile(type, obj_id, key_list) {
        var table = "file";
        var gqLQueryList = DataServices.getList(table);
        var where = {
            relationship_files: {
                obj_id: {_eq: obj_id.toString()},
                type: {_eq: type}
            },
        }
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where
            }
        });
        var listData = resData.data[table];
        return {listData: listData, key_list: key_list};
    },
    async getMultipleFile(type = [], obj_id, key_list) {
        var table = "file";
        var list_type = []
        for (let i = 0; i < type.length; i++) {
            const e = type[i];
            list_type.push({type:{_eq :e}})
        }
        var tpm_obj_id = obj_id.toString()
        var gqLQueryList = DataServices.getList(table,{"fields" : `id url relationship_files(where: {obj_id: {_eq: "`+tpm_obj_id+`"}}){type obj_id}`});
        var where = {
            relationship_files: {
                obj_id: {_eq: obj_id.toString()},
                _or : list_type
            },
        }
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
                orderBy:{created_at: 'asc'}
            }
        });
        var listData = resData.data[table];
        return {listData: listData, key_list: key_list};
    },
    /*async uploadImages(folder,list_image) {
        var s3client = Config.s3Client(folder)
        var objects = [];
        console.log("list_image:",list_image);
        if (list_image) {
            //this.$store.commit('setLoading', true);
            for (let index = 0; index < list_image.length; index++) {
                const img = list_image[index];
                let file = img.source
                if (file !== '' && img.file !== '') {
                    let fileName = img.name;
                    let random = Math.random().toString().slice(2);
                    fileName = `${random}_${fileName}`
                    await s3client.uploadFile(file, fileName).then((data) => {
                        objects.push(data.location);
                    });
                }
            }
        }
        console.log("objectsobjects:",objects)
        return objects;
    },*/
    async uploadImages(folder, list_image) {
        var objects = [];
        console.log("list_image:", list_image);
        console.log("store.getters.token:", store.getters.token);
        if (list_image) {
            //this.$store.commit('setLoading', true);
            for (let index = 0; index < list_image.length; index++) {
                if (index % 5 == 0) {
                    //nếu 5 tấm sẽ nghỉ 100
                    await Common.sleep(100);
                }
                const img = list_image[index];
                let file = img.source
                if (file !== '' && img.file !== '') {
                    let fileName = img.name;
                    let random = Math.random().toString().slice(2);
                    fileName = `${random}_${fileName}`;

                    var formData = new FormData();
                    formData.append('upload', img);
                    var headerUpload = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            "Authorization": "Bearer " + store.getters.token,
                        }
                    };
                    //console.log("55555555555555");
                    /* var response =  await ApiRepository.post(Constants.API.UPLOAD_FILE,formData,headerUpload);
                     var url_image = response.data.data.url;
                     objects.push(url_image);*/
                    await ApiRepository.post(Constants.API.UPLOAD_FILE, formData, headerUpload).then(function (response) {
                        //var url_image = response.data.data.url;
                        var dataUpload = response.data.data;
                        objects.push(dataUpload);
                    })
                        .catch(function (error) {
                            var message = error.response.data.message;
                            console.error("There was an error!", message);
                            switch (message) {
                                case 'INVALID_TOKEN_ERR':
                                    alert(Constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN)
                                    location.reload();
                                    break;
                                default:
                                    alert(Constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN)
                                //location.reload();
                            }
                        });
                }
            }
        }
        console.log("objectsobjects:", objects)
        return objects;
    },
    async updateDataImage(type, obj_id, objectFile) {
        console.log("type========",type)
        var relationshipFileInsert = {
            'type': type,
            'obj_id': (obj_id).toString(),
            'file_id': (objectFile.id).toString(),
        };
        await createProvider().defaultClient.mutate({
            //mutation: gql(queryUsers),
            mutation: DataServices.insertData('relationship_file'),
            variables: {
                objects: relationshipFileInsert
            }
        })
            .then((res) => {
                console.log('Thành công save image:', res);
            }).catch(async error => {
                console.log('error', error);
            });
    },
    async updateDataImage_ver2(type, obj_id, url) {
        var fileInsert = {
            url : url
        }
        await createProvider().defaultClient.mutate({
            //mutation: gql(queryUsers),
            mutation: DataServices.insertData('file'),
            variables: {
                objects: fileInsert
            }
        }).then(async (res) => {
            console.log("RESSSSSS===",res)
            if (res.data[Object.keys(res.data)].affected_rows) {
                var returning = res.data[Object.keys(res.data)].returning[0];
                var file_id = returning.id.toString();
                var relationshipFileInsert = {
                    'type': type,
                    'obj_id': (obj_id).toString(),
                    'file_id': file_id,
                };
                await createProvider().defaultClient.mutate({
                    //mutation: gql(queryUsers),
                    mutation: DataServices.insertData('relationship_file'),
                    variables: {
                        objects: relationshipFileInsert
                    }
                }).then(() => {
                    console.log('Thành công save image:', res);
                }).catch(async error => {
                    console.log('error', error);
                });
            }
        }).catch(async error => {
            console.log('error', error);
        });

    },
    async deleteDataImage(type, obj_id) {
        var table = "relationship_file";
        var gqLQueryList = DataServices.deleteData(table);
        var where = {type: {_eq: type}, obj_id: {_eq: obj_id.toString()}}
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where
            }
        });
        console.log("assssssssssssssss:", type);
        return resData;
    },
    async deleteDataImageMulti(type, listImageDelete) {
        if (listImageDelete && listImageDelete.length > 0) {
            var listInUrl = [];
            for (var i = 0; i < listImageDelete.length; i++) {
                listInUrl.push(listImageDelete[i].objectURL);
            }
            var table = "file";
            var gqLQueryList = DataServices.getList(table);
            var where = {
                url: {_in: listInUrl}
            }
            var resData = await createProvider().defaultClient.mutate({
                mutation: gqLQueryList,
                variables: {
                    where_key: where
                }
            });
            var listData = resData.data[table];
            //console.log("listDatalistData:",listData);
            var id_image = [];
            if (listData && listData.length > 0) {
                for (var p = 0; p < listData.length; p++) {
                    id_image.push(listData[p].id);
                }
                var table_relationship_image = "relationship_file";
                var gqLQueryListRel = DataServices.deleteData(table_relationship_image);
                var whereRel = {type: {_eq: type}, file_id: {_in: id_image}}
                await createProvider().defaultClient.mutate({
                    mutation: gqLQueryListRel,
                    variables: {
                        where_key: whereRel
                    }
                });
            }
        }
        return true;
    },
    async deleteDataImageMultilple(type, listImageDelete) {
        if (listImageDelete && listImageDelete.length > 0) {
            var listInUrl = [];
            for (var i = 0; i < listImageDelete.length; i++) {
                listInUrl.push(listImageDelete[i].objectURL);
            }
            var table = "image";
            var gqLQueryList = DataServices.getList(table);
            var where = {
                url: {_in: listInUrl}
            }
            var resData = await createProvider().defaultClient.mutate({
                mutation: gqLQueryList,
                variables: {
                    where_key: where
                }
            });
            var listData = resData.data[table];
            //console.log("listDatalistData:",listData);
            var id_image = [];
            if (listData && listData.length > 0) {
                for (var p = 0; p < listData.length; p++) {
                    id_image.push(listData[p].id);
                }
                var table_relationship_image = "relationship_image";
                var gqLQueryListRel = DataServices.deleteData(table_relationship_image);
                var whereRel = {type: {_eq: type}, image_id: {_in: id_image}}
                await createProvider().defaultClient.mutate({
                    mutation: gqLQueryListRel,
                    variables: {
                        where_key: whereRel
                    }
                });
            }
        }
        return true;
    },
    async checkPhoneExist(phone, model) {//check tồn tại sdt
        if (phone !== undefined && phone !== "") {
            // var phone = this.phone;
            // if (this.phone[0] === "0") {
            //   phone = phone.substring(1);
            // }
            // phone = phone.replace(' ', '');
            // phone = "+84" + phone;

            var where = {
                account: {'_eq': phone},
                type: {'_eq': 'PHONE'},
                deleted: {'_eq': false}
            }
            if (model !== undefined) where = Object.assign(where, {id: {'_neq': model.id}})
            var {data} = await createProvider().defaultClient.query({
                query: DataServices.getList('accounts', {"fields": "id, account, type"}),
                variables: {
                    where_key: where
                },
                fetchPolicy: "network-only"
            })
            // console.log(data)
            return data && data[Object.keys(data)].length > 0
        }
    },
    async convertLang(str_convert) {//Dịch tiếng việt sang ngôn ngữ khác
        var data = {
            str_convert: str_convert
        };
        var dataRes = {};
        var response = await ApiRepository.post(Constants.API.TRAN_CONVERT_LANG, data);
        console.log("response", response);
        var str_convert_en = response.data.data.str_convert_en;
        dataRes.str_convert_en = str_convert_en;
        return dataRes;
    },
    async getAccountByUid(uid) {
        var table_cer = 'accounts';
        var where = {uid: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        return listData;
    },
    async getChannelByUid(uid) {
        var table_cer = 'channel';
        var where = {uid: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        return listData;
    },
    async getAccountByUidFirebase(uid) {
        var table_cer = 'accounts';
        var where = {id: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    async getUsersById(uid) {
        var table_cer = 'users';
        var where = {id: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    async getService() {
        var table_service = 'service';
        var where = {active: {_eq: true}, deleted: {_eq: false}};
        var gqLQueryList = await DataServices.getList(table_service);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_service];
        return listData;
    },
    async getListData(table, where, offset = null, limit = null, orderBy = null) {
        var variables = {
            where_key: where,
        };
        if (offset && limit) {
            variables.limit = limit;
            variables.offset = offset;
        }
        if (orderBy) {
            variables.orderBy = orderBy;
        }
        var gqLQueryList = await DataServices.getList(table);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: variables
        });
        var listData = resData.data[table];
        return listData;
    },
    async getPackage(order_group_parcel_id) {
        var where_data = {
            order_group_parcel_id: {_eq: order_group_parcel_id},
            deleted: {_eq: false},
        }
        var order_by_data = {created_at: 'desc'};
        var data = await this.getListData('order_parcel', where_data, null, null, order_by_data);
        var data_convert = [];
        for (var i=0;i<data.length;i++){
            var item_data = data[i];
            item_data["parcel_qty"] =  (item_data["number"]) ? item_data["number"]:0
            item_data["key_data"] =  (new Date()).getTime()+Common.makeId(8)
            data_convert.push(item_data);
        }
        return data_convert;
        //obj_controller = (controller_list.length > 0) ? controller_list[0] : null;
    },
    async getCompanyFilter(whereAttach = null) {
        var where_data = {
            deleted: {_eq: false},
        }
        if (whereAttach){
            for (const [key, value] of Object.entries(whereAttach)) {
                where_data[key] = value;
            }
        }
        var order_by_data = {created_at: 'desc'};
        var data = await this.getListData('company', where_data, null, null, order_by_data);
        var data_convert = [];
        for (var i=0;i<data.length;i++){
            var item_data = data[i];
            var code = (item_data.code) ? item_data.code:''
            var name = (item_data.name) ? item_data.name:''
            var text_lable = `${code} - ${name}`;
            item_data["text_lable"] = text_lable
            data_convert.push(item_data);
        }
        return data_convert;
        //obj_controller = (controller_list.length > 0) ? controller_list[0] : null;
    },
    async getSumCutomerDocs(where_data) {
        var data = await this.getListData('group_customer_document', where_data, null, null, null);
        var map_docs = {}
        for (var i=0;i<data.length;i++){
            var item_data = data[i];
            var customer_id = item_data["customer_id"]
            map_docs[customer_id] = item_data
        }
        return {
            list_data:data,
            map_docs:map_docs,
        };
        //obj_controller = (controller_list.length > 0) ? controller_list[0] : null;
    },
    async getListCustomerDocs(customer_id) {
        var where_data = {
            deleted: {_eq: false},
            active: {_eq: true},
            customer_id: {_eq: customer_id},
        }
        var order_by_data = {created_at: 'desc'};
        var data = await this.getListData('customer_document', where_data, null, null, order_by_data);
        var data_convert = [];
        for (var i=0;i<data.length;i++){
            var item_data = data[i];
            item_data["number_process"] = 0;
            item_data["number_request"] = item_data["qty_delivery"];
            data_convert.push(item_data);
        }
        return data_convert;
        //obj_controller = (controller_list.length > 0) ? controller_list[0] : null;
    },
    async getListOrderDocs(order_id) {
        var where_data = {
            order_id:{_eq: order_id},
            deleted:{_eq: false},
            active:{_eq: true},
        }
        var order_by_data = {created_at: 'desc'};
        var data = await this.getListData('order_document', where_data, null, null, order_by_data);
        var data_convert = [];
        for (var i=0;i<data.length;i++){
            var item_data = data[i];
            /*item_data["number_process"] = item_data["number_process"];
            item_data["number_request"] = item_data["number_request"];*/
            item_data["name"] = (item_data["document"]) ? item_data["document"]["name"]:"";
            data_convert.push(item_data);
        }
        return data_convert;
        //obj_controller = (controller_list.length > 0) ? controller_list[0] : null;
    },
    async getObjectData(table, where, order_by = null) {
        var gqLQueryList = await DataServices.getList(table);
        var variables = {
            where_key: where,
            limit:1
        };
        if (order_by) {
            variables.orderBy = order_by;
        }
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: variables
        });
        var listData = resData.data[table];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    async convertFilter(type_input, key, filters) {
        if (type_input == 'DateRange') {
            if (!Common.isEmpty(filters[key]) && !Common.isEmpty(filters[key][0]) && !Common.isEmpty(filters[key][1])) {
                return {
                    type_input: 'DateRange',
                    value: {
                        from: moment(filters[key][0]).utc().format(),
                        to: moment(filters[key][1]).utc().add(24, 'hours').format()
                    },
                };
            } else {
                return {
                    type_input: 'DateRange',
                    value: null
                };
            }
        } else {
            return {
                type_input: type_input,
                value: filters[key],
            };
        }
    },
    async getSetting(code) {
        var table = 'setting';
        var where = {code: {_eq: code}};
        var gqLQueryList = await DataServices.getList(table);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    getPaymentMethod(type_payment) {
        var text = "";
        var list_type_payment = Constants.LIST_TYPE_PAYMENT;
        for (var i = 0; i < list_type_payment.length; i++) {
            if (list_type_payment[i].value == type_payment) {
                text = list_type_payment[i].label;
                break;
            }
        }
        return text;
    },
    async checkPermission(key_controller) {
        //var uid_user = store.getters.user;
        var uid_user = await AuthenticationApp.getUid();
        var users = await this.getUsersById(uid_user);
        // console.log("users",users)
        var list_role = [];
        //users.role == Constants.ROLE_ACCOUNT.SUPPER_ADMIN
        if (users.role == Constants.ROLE_ACCOUNT.SUPPER_ADMIN) {
            // if (account.type_admin == Constants.TYPE_ADMIN.SUPPER_ADMIN) {
            for (const [key, value] of Object.entries(Constants.ROLE)) {
                console.log(key);
                list_role.push(value);//full quyền
            }
            return list_role;
        }
        //type: {_eq: "${role}"},
        var query_controller = `query MyQuery {
          group_user(where: {uid: {_eq: "${uid_user}"}}) {
            group {
              group_roles(where: {role: { controller: {active: {_eq: true}, deleted: {_eq: false}, _key: {_eq: "${key_controller}"}}}}, order_by: {role: {controller: {ordinal: desc}}}) {
                role {
                  type
                  controller {
                    id
                    name
                    ordinal
                    parent_id
                    parent_tab
                    icon
                    _key
                    url
                    tab_service
                  }
                }
              }
            }
          }
        }
      `
        var resData = await createProvider().defaultClient.mutate({
            mutation: gql(query_controller)
        });
        console.log("resData.data.group_user",resData.data.group_user);
        var group_user = (!Common.isEmptyObject(resData.data.group_user)) ? resData.data.group_user : [];
        // var is_permission = false;
        console.log("group_user",group_user);
        var check_dulicate_role = {}
        if (group_user && group_user.length > 0) { //Vì 1 user có thể có nhiều quyền
            for (var g = 0; g < group_user.length; g++) {
                var group_user_obj = group_user[g];
                console.log("group_user_obj", group_user_obj)
                if (group_user_obj) {
                    if (group_user_obj.group) {
                        if (group_user_obj.group.group_roles) {
                            var controller_list = group_user_obj.group.group_roles;
                            for (var m = 0; m < controller_list.length; m++) {
                                if (controller_list[m].role) {
                                    if (controller_list[m].role.type) {
                                        if (Common.isEmpty(check_dulicate_role[controller_list[m].role.type])) {
                                            check_dulicate_role[controller_list[m].role.type] = 1
                                            list_role.push(controller_list[m].role.type);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        console.log('list_rolelist_role:',list_role)
        return list_role;
    },
    async getListControllerGroupUser(uid_user, offset = null, limit = null) {
        var query_controller = `query MyQuery ($offset: Int, $limit: Int) {
          group_user(where: {uid: {_eq: "${uid_user}"}}) {
            group {
              group_roles(where: {role: {type: {_eq: "view"}, controller: {_key: {_nin: ["add_user_to_role"]},active: {_eq: true}, deleted: {_eq: false}}}}, order_by: {role: {controller: {ordinal: desc}}}, offset: $offset, limit: $limit) {
                role {
                  type
                  controller {
                    id
                    name
                    ordinal
                    parent_id
                    parent_tab
                    icon
                    _key
                    url
                    tab_service
                    is_parent
                    parent_menu{
                    id
                    name
                    ordinal
                    parent_id
                    parent_tab
                    icon
                    _key
                    url
                    tab_service
                    is_parent
                    }
                  }
                }
              }
            }
          }
        }
      `
        var resData = await createProvider().defaultClient.mutate({
            mutation: gql(query_controller),
            variables: {
                offset: offset,
                limit: limit,
            }
        });
        var group_user = (!Common.isEmptyObject(resData.data.group_user)) ? resData.data.group_user : [];

        return group_user;
    },
    async geturlRedirectLogin() {
        //var account = store.getters.accountUser;
        //var uid_user = store.getters.user;
        var uid_user = await AuthenticationApp.getUid();
        /*var account = await this.getAccountByUidFirebase(uid_admin);
        console.log("accountaccountaccount:",account);
        var uid_user = (account) ? account.uid:null;*/
        var users = await this.getUsersById(uid_user);
        // console.log("accountaccountaccount:", users);
        var controller_list = [];
        var obj_controller;
        if (users.role == Constants.ROLE_ACCOUNT.SUPPER_ADMIN) {
            var where_controller = {_key: {_nin: ["add_user_to_role"]},active: {_eq: true}, deleted: {_eq: false}, parent_tab: {_eq: true}};
            var order_by_controller = {ordinal: 'desc'};
            controller_list = await this.getListData('controller', where_controller, 0, 1, order_by_controller);
            obj_controller = (controller_list.length > 0) ? controller_list[0] : null;
        } else {
            var group_user = await this.getListControllerGroupUser(uid_user, 0, 1);
            if (group_user && group_user.length > 0) {
                var group_user_obj = group_user[0];
                console.log("group_user_obj", group_user_obj)
                if (group_user_obj) {
                    if (group_user_obj.group) {
                        if (group_user_obj.group.group_roles) {
                            controller_list = group_user_obj.group.group_roles;
                            if (controller_list.length > 0) {
                                if (controller_list[0].role) {
                                    if (controller_list[0].role.controller) {
                                        obj_controller = controller_list[0].role.controller;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        var arr_menu = [];
        var menu_education = [];
        /*var listService = await this.getService();
        if (listService && listService.length > 0){
            for (var i=0;i<listService.length;i++){
                if(listService[i].type == 5){
                    menu_education.push({
                        label: listService[i].name, icon: 'record-icon', to: '/admin/list-register/list/'+listService[i].id, id: 'list_register_'+listService[i].id
                    })
                }
                arr_menu.push(
                    {
                        label: listService[i].name, icon: 'record-icon', to: '/admin/list-service/list/'+listService[i].id, id: 'list_service_'+listService[i].id
                    }
                );
            }
        }*/
        console.log("obj_controllerobj_controllerobj_controller:", obj_controller)
        var url = null;
        if (obj_controller) {
            if (!Common.isEmpty(obj_controller.url)) {
                url = obj_controller.url;
            } else {
                //nếu tồn tại menu child
                switch (obj_controller.tab_service) {
                    case 0:
                        if (obj_controller.childs && obj_controller.childs.length > 0) {
                            var list_menu_child = obj_controller.childs;
                            if (list_menu_child.length > 0) {
                                url = list_menu_child[0].url;
                            }
                        }
                        break;
                    case 1:
                        if (arr_menu.length > 0) {
                            url = arr_menu[0].to;
                        }
                        break;
                    case 2:
                        if (menu_education.length > 0) {
                            url = menu_education[0].to;
                        }
                        break;
                    default:
                    // code block
                }
            }

        }
        return url;

    },
    async uploadMultilpart(file, key_status, key_status_progress_bar) {
        //var calculateMD5 = await this.calculateMD5(file);
        var chunkSize = 1024 * 1024 * 6;//1 phận đoạn 6MB
        var chunks_lenght = Math.ceil(file.size / chunkSize);
        var dataRequest = {
            name_file: file.name,
            parts: chunks_lenght,
        };
        var dataState = {
            status: 1,//1:pending,2:error,3:thành công
        }
        const headers = {
            "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
        };
        ApiRepository.post('/_api/upload-chunk/init_multipart_upload', dataRequest, {headers}).then(async (response) => {
            var dataSuccess = response.data;
            var dataObject = dataSuccess.data;
            var key_s3 = dataObject.key;
            var UploadId = dataObject.UploadId;
            var list_url = (dataObject.list_url) ? dataObject.list_url : [];
            console.log("list_urllist_url", list_url)
            delete ApiRepository.defaults.headers.put['Content-Type']
            if (list_url.length > 0) {
                var listPart = [];
                var count_done = 0;
                var count_error = 0;
                for (var i = 0; i < list_url.length; i++) {
                    if (i % 5 == 0) {
                        await Common.sleep(3000);//deplay 3 giây
                    }
                    const start = i * chunkSize;
                    const end = (i + 1) * chunkSize;
                    const blob = (i < list_url.length) ? file.slice(start, end) : file.slice(start);
                    this.sendChunkS3(list_url[i], blob, i).then(async (response) => {
                        if (response) {
                            count_done++;
                            if (count_done % 5 == 0) {
                                //setprocessbar
                                localStorage.setItem(key_status_progress_bar, ((count_done / list_url.length) * 100).toString());
                            }
                            console.log("responseS3:", response);
                            listPart.push(response);
                            if (count_done == list_url.length) {
                                var dataRequestComplete = {
                                    key_s3: key_s3,
                                    UploadId: UploadId,
                                    parts: listPart,
                                };
                                const headers = {
                                    "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
                                };
                                ApiRepository.post('/_api/upload-chunk/complete_multi_upload', dataRequestComplete, {headers}).then(async (responseComplete) => {
                                    console.log("responseComplete", responseComplete)
                                    var dataComplete = responseComplete.data;
                                    var dataObjectComplete = dataComplete.data;
                                    /*console.log("dataObjectComplete:",(Cryptojs.MD5(file)).toString());
                                    console.log("dataObjectComplete:",(Cryptojs.MD5(dataObjectComplete.url)).toString());*/
                                    //this.downloadAndCreateHash( dataObjectComplete.url);
                                    dataState.status = 3;//Thành công
                                    dataState.data = dataObjectComplete;//Thành công
                                    localStorage.setItem(key_status, JSON.stringify(dataState));
                                })
                            }
                        } else {
                            count_error++;
                            //Nếu lỗi lần đầu thì update còn lần tiếp theo thì ko update
                            if (count_error == 1) {
                                dataState.status = 2;
                                localStorage.setItem(key_status, JSON.stringify(dataState));
                                //alert('Upload file thất bại! Vui lòng thử lại');
                                //Gọi APi hủy UploadId
                                this.abort_multipart_upload(key_s3, UploadId)
                            }

                        }
                    })
                        .catch((error) => {
                            console.log("error::", error)
                            count_error++;
                            //Nếu lỗi lần đầu thì update còn lần tiếp theo thì ko update
                            if (count_error == 1) {
                                //alert('Upload file thất bại! Vui lòng thử lại');
                                dataState.status = 2;
                                localStorage.setItem(key_status, JSON.stringify(dataState));
                                //Gọi APi hủy UploadId
                                this.abort_multipart_upload(key_s3, UploadId)
                            }
                        })
                }
            }
        })
            .catch((error) => {
                console.log(error)
                //alert('Upload file thất bại! Vui lòng thử lại');
                dataState.status = 2;
                localStorage.setItem(key_status, JSON.stringify(dataState));
            })
    },
    async sendChunkS3(url, blob, i) {
        try {
            var response = await ApiRepository.put(url, blob);
            console.log("(Cryptojs.SHA256(this.password.toString())).toString():", (Cryptojs.MD5(blob)).toString())
            console.log("response.headers.etag:", response.headers.etag)
            var hashChunk = await this.calculateMD5(blob);
            console.log("hashChunk:"+i+":"+hashChunk);
            console.log("response.headers.etag.trim():"+i+":"+response.headers.etag.trim());
            var Etag = response.headers.etag;
            //vi etag chứa thêm nhũng ký tự "" || nên dùng indexof
            if ((Etag.indexOf(hashChunk) > -1)){
                return {
                    ETag: response.headers.etag,
                    PartNumber: i + 1
                };
            }else {
                console.log("SAI:"+i)
                return false;//ko toàn vẹn dữ liệu
            }

        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async abort_multipart_upload(key_s3, UploadId) { //HỦy một state upload
        try {
            var dataRequest = {
                key_s3: key_s3,
                UploadId: UploadId,
            };
            const headers = {
                "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
            };
            await ApiRepository.post('/_api/upload-chunk/abort_multipart_upload', dataRequest, {headers});
        } catch (err) {
            console.log(err);
        }
    },
    fileHash(file, callback) {
        //Instantiate a reader
        var reader = new FileReader();

        //What to do when we gets data?
        reader.onload = function (e) {
            var hash = (Cryptojs.MD5(e.target.result)).toString();
            callback(hash);
        }
        reader.readAsBinaryString(file);
    }
    ,
    async downloadAndCreateHash(url) {
        fetch(url)
            .then(function (response) {
                return response.blob()
            })
            .then(blob => {
                return this.calculateMD5(blob)
            })
            .then(hash => {
                console.log(`Hash of the file is: ${hash}`)
            })
            .catch(err => {
                console.error(err)
            });

    },
    calculateMD5(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onloadend = function () {
                var wordArray = Cryptojs.lib.WordArray.create(reader.result),
                    hash = Cryptojs.MD5(wordArray).toString();
                resolve(hash)
            };
        })
    },
    getFilterCommonPlaceHolder(col) {//
        if (col.filter) {
            if (col.filter.placeHolder) {
                return col.filter.placeHolder;
            } else if (col.filter.type == "select") {
                return `Chọn`;
            } else if (col.filter.type == "input") {
                return `Nhập`;
            } else if (col.filter.type == "DateRange") {
                return `Chọn ngày`;
            } else if (col.filter.type == "Date") {
                return `Chọn ngày`;
            } else {
                return ``;
            }
        }
        return '';
    },
    convert_date(value,type) {//
        var value_res = '';
        if (type == "Date" && value) {
            value_res = moment.tz(value, "Asia/Ho_Chi_Minh").format("DD/MM/yyyy");
        } else if (type == "Datetime" && value) {
            value_res = moment.tz(value, "Asia/Ho_Chi_Minh").format("DD/MM/yyyy HH:mm:ss");
        }
        return value_res;
    },
    async insert_process_order(data_insert) {
        var gqLQueryInsert = await DataServices.insertData("history_status_order");
        await createProvider().defaultClient.mutate({
            mutation: gqLQueryInsert,
            variables: {
                objects: data_insert,
            }
        });
        return true;
    },
    async callApiDownloadPost(url,params={},header={}) {
        var payloadDefault = {};
        if (!(Object.keys(params).length === 0)){
            for (const [key, value] of Object.entries(params)) {
                payloadDefault[key] = value;
            }
        }
        var headerDefaul = {};
        if (!(Object.keys(header).length === 0)){
            for (const [key_h, value_h] of Object.entries(header)) {
                headerDefaul[key_h] = value_h;
            }
        }
        var config = {
            responseType: 'blob',
            headers:headerDefaul
        }
        const results = await ApiRepository.post(url,payloadDefault,config)
        return results;
    },
    async get_warehouse_order_ck(order_id, type) {
        try {
            var dataRequest = {
                order_id: order_id,
                type: type,
            };
            const headers = {
                "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
            };
            var data_res =  await ApiRepository.post('/_api/order/get_warehouse_order_ck', dataRequest, {headers});
            console.log("data_resdata_res:",data_res)
            return {
                data:data_res?.data?.data ?? null
            }
            // return data_res["data"]
        } catch (err) {
            //var message = err.response.data.message;
            console.log("err.responseerr.response:",err.response);
            console.log(err);
            return  {
                data:null
            }
        }
    },
    async check_status_order(list_order_code, type,code_change) {
        try {
            var dataRequest = {
                list_order_code: list_order_code,
                type: type,
                code_change: code_change,
            };
            const headers = {
                "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
            };
          var data_res =  await ApiRepository.post('/_api/order/verify_update_status_order', dataRequest, {headers});
          console.log("data_resdata_res:",data_res)
            return null
           // return data_res["data"]
        } catch (err) {
            var message = err.response.data.message;
            var data_err = {}
            if (message == 'UPDATE_STATUS_ERR'){
                var text_err = "";
                if (type==2){
                 if (code_change =='ST005'){
                     text_err = !Common.isEmpty(Constants.STATUS_ORDER_TEXT['ST005_MULTILPLE']) ? Constants.STATUS_ORDER_TEXT['ST005_MULTILPLE']:'';
                 }else  if (code_change =='ST014'){
                     text_err = !Common.isEmpty(Constants.STATUS_ORDER_TEXT['ST014_MULTILPLE']) ? Constants.STATUS_ORDER_TEXT['ST014_MULTILPLE']:'';
                 }
                 else {
                     text_err = !Common.isEmpty(Constants.STATUS_ORDER_TEXT[code_change]) ? Constants.STATUS_ORDER_TEXT[code_change]:'';
                 }
                 text_err = Common.replaceAll(text_err,'#NUMBER_ERR#',err.response.data.data.length);
                 text_err = Common.replaceAll(text_err,'#NUMBER_ORDER#',list_order_code.length);
                 text_err = Common.replaceAll(text_err,'#CODE#',err.response.data.data.join(', '));
                data_err["text_err"] = text_err
                }else {
                    data_err["text_err"] = "Các đơn hàng không thể thay đổi trạng thái này:" + err.response.data.data.join(', ')
                }
            }else if (message == 'ORDER_NOT_PAYMENT_ERR'){
                data_err["text_err"] = "Vui lòng xác nhận thanh toán đơn hàng:" + err.response.data.data.join(', ')
            }else {
                data_err["text_err"] = "Vui lòng thử lại"

            }
            console.log("err.responseerr.response:",err.response);
            console.log(err);
            return data_err
        }
    },
    async update_status_multil(dataRequest) {
        try {
            const headers = {
                "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
            };
            await ApiRepository.post('/_api/order/update-status-order-multil', dataRequest, {headers});
            return {
                status:true
            }
        } catch (err) {
            var message = err.response.data;
            console.log(message);
            return {
                status:false,
                list_err:err.response.data.data,
                message:err.response.data.message,
            };
        }
    },
    async check_status_multil(dataRequest) {
        try {
            const headers = {
                "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
            };
            await ApiRepository.post('/_api/order/check-status-order-multil', dataRequest, {headers});
            return {
                status:true
            }
        } catch (err) {
            var message = err.response.data;
            console.log(message);
            return {
                status:false,
                list_err:err.response.data.data,
                message:err.response.data.message,
            };
        }
    },
    async process_comapny_vehicle(vehicle_id,list_id_company) {
        try {
            var tableDel = "vehicle_company";
            var gqLQueryDel = DataServices.deleteData(tableDel);
            var whereDel = {vehicle_id: {_eq: vehicle_id}}
            await createProvider().defaultClient.mutate({
                mutation: gqLQueryDel,
                variables: {
                    where_key: whereDel
                }
            });
            var list_data_insert = [];
            for(var i = 0;i<list_id_company.length;i++){
                var item_insert = {
                    company_id:list_id_company[i],
                    vehicle_id:vehicle_id,
                }
                list_data_insert.push(item_insert);
            }
            if(list_data_insert.length>0){

                await createProvider().defaultClient.mutate({
                    //mutation: gql(queryUsers),
                    mutation: DataServices.insertData('vehicle_company'),
                    variables: {
                        objects: list_data_insert
                    }
                })
                    .then((res) => {
                        console.log('Thành công save image:', res);
                    }).catch(async error => {
                        console.log('error', error);
                    });
            }
            return {
                status:true
            }
        } catch (err) {
            var message = err.response.data;
            console.log(message);
            return {
                status:false,
                list_err:err.response.data.data,
                message:err.response.data.message,
            };
        }
    },
    async checkOLOrderCustomer(listCode) {
        try {

            var variables = {
                where_key: {code: {_in: listCode}}
            };
            var gqLQueryList = await DataServices.getList('order_detail');
            var resData = await createProvider().defaultClient.mutate({
                mutation: gqLQueryList,
                variables: variables
            });
            var list = resData.data['order'];
            console.log("listlist:",list)
            var is_ol = 1;
            if (list.length >0) {
                for (let i = 0; i < list.length; i++) {
                    var obj_data = list[i];
                    var is_ol_i = 0;
                    if (obj_data) {
                        var group_code = obj_data?.customer?.group_channel?.channel?.code ?? null;
                        if (group_code) {
                            var prefix_group_code = group_code.toLowerCase().substring(0, 2);
                            if (prefix_group_code == 'ol') {
                                is_ol_i = 1;
                            }
                        }
                    }
                    if (is_ol_i == 0) {
                        is_ol = 0;
                    }
                }
            }else {
                is_ol = 0;
            }
            return {
                is_ol:is_ol
            }
        } catch (err) {
            console.log(err);
            return {
                is_ol:0
            };
        }
    },
    async getOrderParcel(list_order_group_parcel_id) {
        var where_data = {
            order_group_parcel_id: {_in: list_order_group_parcel_id},
            //deleted: {_eq: false},
        }
        var order_by_data = {created_at: 'desc'};
        var data = await this.getListData('order_parcel', where_data, null, null, order_by_data);
        var data_convert = [];
        var map_order_group_parcel = {}
        for (var i=0;i<data.length;i++){
            var item_data = data[i];
            var order_group_parcel_id = item_data["order_group_parcel_id"]
            var parcel_size = item_data?.parcel_size ?? 0;
            var parcel_qty = item_data?.number ?? 0;
            var parcel_name = item_data?.parcel.name ?? '';
            var code_root = item_data?.order_group_parcel.code_root ?? '';
            var total_size = parcel_size*parcel_qty;
            var text_key = `${parcel_qty} ${parcel_name}`;
            item_data["key_data"] =  (new Date()).getTime()+Common.makeId(8)
            if (Common.isEmpty(map_order_group_parcel[order_group_parcel_id])){
                map_order_group_parcel[order_group_parcel_id] = {
                    arr_text:[],
                    total_size:0,
                    total_qty:0,
                    code_root:code_root,
                }
            }
            map_order_group_parcel[order_group_parcel_id]["total_size"] = map_order_group_parcel[order_group_parcel_id]["total_size"]+total_size;
            map_order_group_parcel[order_group_parcel_id]["total_qty"] = map_order_group_parcel[order_group_parcel_id]["total_qty"]+parcel_qty;
            console.log("parcel_qtyparcel_qty:",parcel_qty)
            map_order_group_parcel[order_group_parcel_id]["arr_text"].push(text_key)
            data_convert.push(item_data);
        }
        console.log("map_order_group_parcel:",map_order_group_parcel)
        return {
            map_order_group_parcel:map_order_group_parcel,
            data_convert:data_convert,
        };
        //obj_controller = (controller_list.length > 0) ? controller_list[0] : null;
    },
    async getDeliveryCosts(order_id) {
        var where_data = {
            order_id: {_in: [order_id]},
            //deleted: {_eq: false},
        }
        var order_by_data = {created_at: 'desc'};
        var data = await this.getListData('delivery_costs', where_data, null, null, order_by_data);
        var map_history_status_order_id = {}
        var list_data = []
        for (var i=0;i<data.length;i++){
            var item_data = data[i];
            var history_status_order_id = item_data?.history_status_order_id ?? null
            map_history_status_order_id[history_status_order_id] = item_data
            list_data.push(item_data)
        }
        return {
            map_history_status_order_id:map_history_status_order_id,
            list_data:list_data,
        };
        //obj_controller = (controller_list.length > 0) ? controller_list[0] : null;
    },



}
