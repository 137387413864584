import BaseModel from '../core/BaseModel'
//import UnitModel from "./UnitModel";
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class OrderParcelModel extends BaseModel {

    constructor() {
        super({ 
                name: "order_parcel",
                tableName: 'order_parcel',
                label: "Danh Sách Đơn hàng",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
          /*  check_box: {
                name: "check_box",
                label: " ",
                type: "Computed",
                custom: 'CheckBoxSelect',
                computed: (data) => {
                    data.stt = '';
                },
                filter: {}
                // display: { list: true, add: false, edit: false }
            },*/
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                parcel: {
                    refs: 'parcel {id name}',
                    display: false
                },
                order_group_parcel_id: {
                    name: 'order_group_parcel_id',
                    type: "String",
                    label: 'order_group_parcel_id',
                    sortable: false,
                    width: 10,
                    /*filter: {type: 'input', match: 'contains'},*/
                },
                number: {
                    name: 'number',
                    type: "Bigint",
                    label: 'number',
                    display: false
                },
                key_get: {
                    refs: 'parcel_size parcel_name parcel_id order_group_parcel{ code_root }',
                    display: false
                },
        },)
    }

}