import BaseService from "./BaseService";
import UserModel from "../models/UserModel";
import AccountsModel from "../models/AccountsModel";
import AdminModel from "../models/AdminModel";
import ContentExportCompanyModel from "../models/ContentExportCompanyModel";
import VehicleCompanyModel from "../models/VehicleCompanyModel";
import OrderCustomTransferHsctAccountingFaildModel from "../models/OrderCustomTransferHsctAccountingFaildModel";
import OrderCustomTransferHsctAccountingModel from "../models/OrderCustomTransferHsctAccountingModel";
import OrderShippingDeliveryTripModel from "../models/OrderShippingDeliveryTripModel";
// import UserUsersModel from '../models/UserUserModel'

import FileModel from "@/models/FileModel";
import RelationshipFileModel from "../models/RelationshipFileModel";

import GroupModel from "../models/GroupModel";
import GroupUserModel from "../models/GroupUserModel";

import PolicyModel from "../models/PolicyModel";
import PolicyLangModel from "../models/PolicyLangModel";

import NotificationsModel from "../models/NotificationsModel";
import NotificationsLangModel from "../models/NotificationsLangModel";
import ControllerModel from "../models/ControllerModel";
import ActiveUsersModel from "../models/ActiveUsersModel";
import BookingModel from "../models/BookingModel";
import ServiceModel from "../models/ServiceModel";
import TypeServiceModel from "../models/TypeServiceModel";
import BranchModel from "../models/BranchModel";
import TimeModel from "../models/TimeModel";
import QuestionServiceModel from "../models/QuestionServiceModel";
import BookingQuestionModel from "../models/BookingQuestionModel";
import BookingLineModel from "../models/BookingLineModel";
import CategoryServiceModel from "../models/CategoryServiceModel";
import PromotionModel from "../models/PromotionModel";
import ClubModel from "../models/ClubModel";
import QuestionModel from "../models/QuestionModel";
import ClubRegisterModel from "../models/ClubRegisterModel";
import NewsModel from "../models/NewsModel";
import TermModel from "../models/TermModel";
import ContactModel from "../models/ContactModel";
import HolidayModel from "../models/HolidayModel";
import HolidayBranchModel from "../models/HolidayBranchModel";
import StaffModel from "../models/StaffModel";
import ReasonModel from "../models/ReasonModel";
import VehicleModel from "../models/VehicleModel";
import FreightModel from "../models/FreightModel";
import OrderModel from "../models/OrderModel";
import ChannelModel from "../models/ChannelModel";
import ChannelCompanyModel from "../models/ChannelCompanyModel";
import ChannelSelectModel from "../models/ChannelSelectModel";
import GroupChannelModel from "../models/GroupChannelModel";
import ProductModel from "../models/ProductModel";
import CompanyModel from "../models/CompanyModel";
import MaterialModel from "../models/MaterialModel";
import UnitModel from "../models/UnitModel";
import OriginModel from "../models/OriginModel";
import SuppliesModel from "../models/SuppliesModel";
import ShippingMethodModel from "../models/ShippingMethodModel";
import SummaryReportOperateServiceModel from "../models/SummaryReportOperateServiceModel";
import ShippingMethodPriceModel from "../models/ShippingMethodPriceModel";
import ShippingMethodLogicticsModel from "../models/ShippingMethodLogicticsModel";
import AreaTypeModel from "../models/AreaTypeModel";
import GroupChannelCompanyModel from "../models/GroupChannelCompanyModel";
import ParcelModel from "../models/ParcelModel";
import AreaModel from "../models/AreaModel";
import OrderDocumentModel from "../models/OrderDocumentModel";
import CountryModel from "../models/CountryModel";
import StateModel from "../models/StateModel";
import DistrictModel from "../models/DistrictModel";
import WardModel from "../models/WardModel";
import OrderItemModel from "../models/OrderItemModel";
import OrderParcelModel from "../models/OrderParcelModel";
import CustomerModel from "../models/CustomerModel";
import RegionModel from "../models/RegionModel";
import DocumentModel from "../models/DocumentModel";
import DeliveryModel from "../models/DeliveryModel";
import CustomerDocumentModel from "../models/CustomerDocumentModel";
import CustomerFreightModel from "../models/CustomerFreightModel";
import RoleModel from "../models/RoleModel";
import GroupCustomerDocumentModel from "../models/GroupCustomerDocumentModel";
//import WareHouseModel from "../models/WareHouseModel";
import TypeWareHouseModel from "../models/TypeWareHouseModel";
import StockQuantModel from "../models/StockQuantModel";
import WarehouseModel from "../models/WarehouseModel";
import FreightCustomerModel from "../models/FreightCustomerModel";
import HistoryStatusOrderModel from "../models/HistoryStatusOrderModel";
import OrderItemCustomModel from "../models/OrderItemCustomModel";
import OrderCustomModel from "../models/OrderCustomModel";
import OrderCustomWareHouseModel from "../models/OrderCustomWareHouseModel";
import OrderCustomDeliveryStatusModel from "../models/OrderCustomDeliveryStatusModel";
import OrderCustomDeliveryStatusFaildModel from "../models/OrderCustomDeliveryStatusFaildModel";
import HolidayItemModel from "../models/HolidayItemModel";
import OrderShippingModel from "../models/OrderShippingModel";
import OrderStatusModel from "../models/OrderStatusModel";
import DepartmentModel from "../models/DepartmentModel";
import OrdershippingItemModel from "../models/OrdershippingItemModel";
import OtherCostsModel from "../models/OtherCostsModel";
import OtherCostsServiceModel from "../models/OtherCostsServiceModel";
import ReportDetailCostServiceModel from "../models/ReportDetailCostServiceModel";
import LogOrderModel from "../models/LogOrderModel";
import InventoryReportModel from "../models/InventoryReportModel";
import OrdershippingItemCustomModel from "../models/OrdershippingItemCustomModel";
import ReportSummaryModel from "../models/ReportSummaryModel";
import ReportDocumentModel from "../models/ReportDocumentModel";
import DeliveryCostsModel from "../models/DeliveryCostsModel";
import DeliveryCostsDetailModel from "../models/DeliveryCostsDetailModel";
import ReportOrderProgressModel from "../models/ReportOrderProgressModel";
import MaterialCodeModel from "../models/MaterialCodeModel";
import DashboardModel from "../models/DashboardModel";
import ReportOrderCategoryModel from "../models/ReportOrderCategoryModel";
import OrderProgressModel from "../models/OrderProgressModel";
import StatusClassificationModel from "../models/StatusClassificationModel";
import DocumentCompanyModel from "../models/DocumentCompanyModel";
import OrderDetailModel from "../models/OrderDetailModel";
import OrderShippingPackageModel from "../models/OrderShippingPackageModel";

export default {
  services: {
    order_shipping_delivery_trip: {
      model: new OrderShippingDeliveryTripModel(),
      service: new BaseService(new OrderShippingDeliveryTripModel()),
    },
    order_shipping_package: {
      model: new OrderShippingPackageModel(),
      service: new BaseService(new OrderShippingPackageModel()),
    },
    order_detail: {
      model: new OrderDetailModel(),
      service: new BaseService(new OrderDetailModel()),
    },
    order_custom_transfer_hsct_accounting_faild: {
      model: new OrderCustomTransferHsctAccountingFaildModel(),
      service: new BaseService(new OrderCustomTransferHsctAccountingFaildModel()),
    },
    order_custom_transfer_hsct_accounting: {
      model: new OrderCustomTransferHsctAccountingModel(),
      service: new BaseService(new OrderCustomTransferHsctAccountingModel()),
    },
    vehicle_company: {
      model: new VehicleCompanyModel(),
      service: new BaseService(new VehicleCompanyModel()),
    },
    content_export_company: {
      model: new ContentExportCompanyModel(),
      service: new BaseService(new ContentExportCompanyModel()),
    },
    order: {
      model: new OrderModel(),
      service: new BaseService(new OrderModel()),
    },
    accounts: {
      model: new AccountsModel(),
      service: new BaseService(new AccountsModel()),
    },
    view_admin: {
      model: new AdminModel(),
      service: new BaseService(new AdminModel()),
    },
    group: {
      model: new GroupModel(),
      service: new BaseService(new GroupModel()),
    },
    group_user: {
      model: new GroupUserModel(),
      service: new BaseService(new GroupUserModel()),
    },
    users: {
      model: new UserModel(),
      service: new BaseService(new UserModel()),
    },
    file: {
      model: new FileModel(),
      service: new BaseService(new FileModel()),
    },
    relationship_file: {
      model: new RelationshipFileModel(),
      service: new BaseService(new RelationshipFileModel()),
    },

    notification: {
      service: new BaseService(new NotificationsModel()),
      model: new NotificationsModel(),
      rels: [],
    },

    policy: {
      service: new BaseService(new PolicyModel()),
      model: new PolicyModel(),
    },
    policy_lang: {
      service: new BaseService(new PolicyLangModel()),
      model: new PolicyLangModel(),
    },

    lang_notification: {
      service: new BaseService(new NotificationsLangModel()),
      model: new NotificationsLangModel(),
    },
    controller: {
      service: new BaseService(new ControllerModel()),
      model: new ControllerModel(),
    },
    active_users: {
      service: new BaseService(new ActiveUsersModel()),
      model: new ActiveUsersModel(),
    },

    booking: {
      service: new BaseService(new BookingModel()),
      model: new BookingModel(),
    },
    service: {
      service: new BaseService(new ServiceModel()),
      model: new ServiceModel(),
    },
    type_service: {
      service: new BaseService(new TypeServiceModel()),
      model: new TypeServiceModel(),
    },
    branch: {
      service: new BaseService(new BranchModel()),
      model: new BranchModel(),
    },
    time: {
      service: new BaseService(new TimeModel()),
      model: new TimeModel(),
    },
    question_service: {
      service: new BaseService(new QuestionServiceModel()),
      model: new QuestionServiceModel(),
    },
    booking_line: {
      service: new BaseService(new BookingLineModel()),
      model: new BookingLineModel(),
    },
    booking_question: {
      service: new BaseService(new BookingQuestionModel()),
      model: new BookingQuestionModel(),
    },
    category_service: {
      service: new BaseService(new CategoryServiceModel()),
      model: new CategoryServiceModel(),
    },
    promotion: {
      service: new BaseService(new PromotionModel()),
      model: new PromotionModel(),
    },
    club: {
      service: new BaseService(new ClubModel()),
      model: new ClubModel(),
    },
    questions: {
      service: new BaseService(new QuestionModel()),
      model: new QuestionModel(),
    },
    club_register: {
      service: new BaseService(new ClubRegisterModel()),
      model: new ClubRegisterModel(),
    },
    news: {
      service: new BaseService(new NewsModel()),
      model: new NewsModel(),
    },
    term: {
      service: new BaseService(new TermModel()),
      model: new TermModel(),
    },
    contact: {
      service: new BaseService(new ContactModel()),
      model: new ContactModel(),
    },
    holiday: {
      service: new BaseService(new HolidayModel()),
      model: new HolidayModel(),
    },
    holiday_branch: {
      service: new BaseService(new HolidayBranchModel()),
      model: new HolidayBranchModel(),
    },
    staff: {
      service: new BaseService(new StaffModel()),
      model: new StaffModel(),
    },
    reason: {
      service: new BaseService(new ReasonModel()),
      model: new ReasonModel(),
    },
    vehicle: {
      service: new BaseService(new VehicleModel()),
      model: new VehicleModel(),
    },
    freight: {
      service: new BaseService(new FreightModel()),
      model: new FreightModel(),
    },

    channel: {
      service: new BaseService(new ChannelModel()),
      model: new ChannelModel(),
    },
    channel_company: {
      service: new BaseService(new ChannelCompanyModel()),
      model: new ChannelCompanyModel(),
    },
    company: {
      service: new BaseService(new CompanyModel()),
      model: new CompanyModel(),
    },
    channel_select: {
      service: new BaseService(new ChannelSelectModel()),
      model: new ChannelSelectModel(),
    },
    group_channel: {
      service: new BaseService(new GroupChannelModel()),
      model: new GroupChannelModel(),
    },
    product: {
      service: new BaseService(new ProductModel()),
      model: new ProductModel(),
    },
    materials: {
      service: new BaseService(new MaterialModel()),
      model: new MaterialModel(),
    },
    unit: {
      service: new BaseService(new UnitModel()),
      model: new UnitModel(),
    },
    origin: {
      service: new BaseService(new OriginModel()),
      model: new OriginModel(),
    },
    supplies: {
      service: new BaseService(new SuppliesModel()),
      model: new SuppliesModel(),
    },
    shipping_method: {
      service: new BaseService(new ShippingMethodModel()),
      model: new ShippingMethodModel(),
    },
    summary_report_operate_service: {
      service: new BaseService(new SummaryReportOperateServiceModel()),
      model: new SummaryReportOperateServiceModel(),
    },
    shipping_method_price: {
      service: new BaseService(new ShippingMethodPriceModel()),
      model: new ShippingMethodPriceModel(),
    },
    shipping_method_logictics: {
      service: new BaseService(new ShippingMethodLogicticsModel()),
      model: new ShippingMethodLogicticsModel(),
    },
    area_type: {
      service: new BaseService(new AreaTypeModel()),
      model: new AreaTypeModel(),
    },
    area: {
      service: new BaseService(new AreaModel()),
      model: new AreaModel(),
    },
    group_channel_company: {
      service: new BaseService(new GroupChannelCompanyModel()),
      model: new GroupChannelCompanyModel(),
    },
    parcel: {
      service: new BaseService(new ParcelModel()),
      model: new ParcelModel(),
    },
    order_document: {
      service: new BaseService(new OrderDocumentModel()),
      model: new OrderDocumentModel(),
    },
    country: {
      service: new BaseService(new CountryModel()),
      model: new CountryModel(),
    },
    state: {
      service: new BaseService(new StateModel()),
      model: new StateModel(),
    },
    district: {
      service: new BaseService(new DistrictModel()),
      model: new DistrictModel(),
    },
    ward: {
      service: new BaseService(new WardModel()),
      model: new WardModel(),
    },
    order_item: {
      service: new BaseService(new OrderItemModel()),
      model: new OrderItemModel(),
    },
    order_parcel: {
      service: new BaseService(new OrderParcelModel()),
      model: new OrderParcelModel(),
    },
    customer: {
      service: new BaseService(new CustomerModel()),
      model: new CustomerModel(),
    },
    region: {
      service: new BaseService(new RegionModel()),
      model: new RegionModel(),
    },
    document: {
      service: new BaseService(new DocumentModel()),
      model: new DocumentModel(),
    },
    customer_delivery: {
      service: new BaseService(new DeliveryModel()),
      model: new DeliveryModel(),
    },
    customer_document: {
      service: new BaseService(new CustomerDocumentModel()),
      model: new CustomerDocumentModel(),
    },
    freight_customer: {
      service: new BaseService(new CustomerFreightModel()),
      model: new CustomerFreightModel(),
    },
    freightcustomer_: {
      service: new BaseService(new FreightCustomerModel()),
      model: new FreightCustomerModel(),
    },
    type_warehouse: {
      service: new BaseService(new TypeWareHouseModel()),
      model: new TypeWareHouseModel(),
    },
    stock_quant: {
      service: new BaseService(new StockQuantModel()),
      model: new StockQuantModel(),
    },
    warehouse: {
      service: new BaseService(new WarehouseModel()),
      model: new WarehouseModel(),
    },
    history_status_order: {
      service: new BaseService(new HistoryStatusOrderModel()),
      model: new HistoryStatusOrderModel(),
    },
    order_item_custom: {
      service: new BaseService(new OrderItemCustomModel()),
      model: new OrderItemCustomModel(),
    },
    order_custom: {
      service: new BaseService(new OrderCustomModel()),
      model: new OrderCustomModel(),
    },
    order_custom_warehouse: {
      service: new BaseService(new OrderCustomWareHouseModel()),
      model: new OrderCustomWareHouseModel(),
    },
    order_custom_delivery_status: {
      service: new BaseService(new OrderCustomDeliveryStatusModel()),
      model: new OrderCustomDeliveryStatusModel(),
    },
    order_custom_delivery_status_faild: {
      service: new BaseService(new OrderCustomDeliveryStatusFaildModel()),
      model: new OrderCustomDeliveryStatusFaildModel(),
    },
    holiday_item: {
      service: new BaseService(new HolidayItemModel()),
      model: new HolidayItemModel(),
    },
    order_shipping: {
      service: new BaseService(new OrderShippingModel()),
      model: new OrderShippingModel(),
    },
    department: {
      service: new BaseService(new DepartmentModel()),
      model: new DepartmentModel(),
    },
    order_status: {
      service: new BaseService(new OrderStatusModel()),
      model: new OrderStatusModel(),
    },
    order_shipping_item: {
      service: new BaseService(new OrdershippingItemModel()),
      model: new OrdershippingItemModel(),
    },

    other_costs: {
      service: new BaseService(new OtherCostsModel()),
      model: new OtherCostsModel(),
    },
    other_costs_service: {
      service: new BaseService(new OtherCostsServiceModel()),
      model: new OtherCostsServiceModel(),
    },
    report_detail_cost_service: {
      service: new BaseService(new ReportDetailCostServiceModel()),
      model: new ReportDetailCostServiceModel(),
    },
    log_order: {
      service: new BaseService(new LogOrderModel()),
      model: new LogOrderModel(),
    },

    role: {
      service: new BaseService(new RoleModel()),
      model: new RoleModel(),
    },


    inventory_report: {
      service: new BaseService(new InventoryReportModel()),
      model: new InventoryReportModel()
    },
    order_shipping_item_custom: {
      service: new BaseService(new OrdershippingItemCustomModel()),
      model: new OrdershippingItemCustomModel()
    },
    delivery_costs: {
      service: new BaseService(new DeliveryCostsModel()),
      model: new DeliveryCostsModel()
    },
    delivery_costs_detail: {
      service: new BaseService(new DeliveryCostsDetailModel()),
      model: new DeliveryCostsDetailModel()
    },
    report_order_progress: {
      service: new BaseService(new ReportOrderProgressModel()),
      model: new ReportOrderProgressModel()
    }
    ,
    report_summary: {
      service: new BaseService(new ReportSummaryModel()),
      model: new ReportSummaryModel()
    }
    ,
    /*report_document: {
      service: new BaseService(new ReportDocumentModel()),
      model: new ReportDocumentModel()
    },*/
    order_docs: {
      service: new BaseService(new ReportDocumentModel()),
      model: new ReportDocumentModel()
    },
    materials_code: {
      service: new BaseService(new MaterialCodeModel()),
      model: new MaterialCodeModel(),
    },
    dashboard: {
      service: new BaseService(new DashboardModel()),
      model: new DashboardModel()
    },
    report_order_category: {
      service: new BaseService(new ReportOrderCategoryModel()),
      model: new ReportOrderCategoryModel()
    },
    order_progress: {
      service: new BaseService(new OrderProgressModel()),
      model: new OrderProgressModel()
    },
    status_classification: {
      service: new BaseService(new StatusClassificationModel()),
      model: new StatusClassificationModel()
    },
    group_customer_document: {
      service: new BaseService(new GroupCustomerDocumentModel()),
      model: new GroupCustomerDocumentModel()
    },
    document_company: {
      service: new BaseService(new DocumentCompanyModel()),
      model: new DocumentCompanyModel()
    }
  },
  getModel(modelName) {
    return this.services[modelName].model;
  },
  getListFreightCustomer(modelName, params) {
    return this.services[modelName].service.getListQueryFreightCustomer(params);
  },
  getList(modelName, params) {
    return this.services[modelName].service.getListQuery(params);
  },
  countDataFreightCustomer(modelName) {
    return this.services[modelName].service.countDataQueryFreightCustomer();
  },
  countData(modelName) {
    return this.services[modelName].service.countDataQuery();
  },
  updateData(modelName) {
    return this.services[modelName].service.updateDataQuery();
  },
  insertData(modelName) {
    return this.services[modelName].service.insertDataQuery();
  },
  deleteData(modelName) {
    return this.services[modelName].service.deleteDataQuery();
  },
  getByMyId(modelName, params) {
    return this.services[modelName].service.getByMyIdQuery(params);
  },
};
