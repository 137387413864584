import BaseModel from '../core/BaseModel'
export default class DocumentCompanyModel extends BaseModel {
    constructor() {
        super({
            tableName: 'document_company', label: "hsct", display_key: 'id'
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            company_id: {
                name: 'company_id',
            },

            document_id : {
              name: 'document_id',
            }
        })
    }

}