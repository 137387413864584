import BaseModel from '../core/BaseModel'
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
export default class RoleModel extends BaseModel {
    constructor() {
        super({
            tableName: 'role', label: " Role", display_key: 'code',name: "role",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center width_160 common',
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            type : {
                name: 'type',
                type: "String",
                label: 'Mã  kênh',},
            controller_id : {
                name: 'controller_id',
                type: "String",
                label: 'controller_id',},

            name : {
              name: 'name',
              type: "String",
              label: 'Tên kênh phân phối',
                sortable: false,
              filter: {type: 'input', match: 'contains'},
                class:"center width_160 common",
            },
            active: {
                name: "active",
                label: "Hiển thị",
                type: "String",
            },
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
                class:" width_160 common",
            },
            created_at : {
              name: 'created_at',
              type: "Datetime",
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },




        })
    }

}