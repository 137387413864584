import BaseModel from '../core/BaseModel'
/*import UserModel from "./UserModel";
import VehicleModel from "./VehicleModel";
import OrderStatusModel from "@/models/OrderStatusModel";*/
//import gql from 'graphql-tag'
export default class LogOrderModel extends BaseModel {

    constructor() {
        super({ 
                name: "log_order",
                tableName: 'log_order',
                label: "Báo cáo đơn hàng chỉnh sửa",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            /*check_box: {
                name: "check_box",
                label: " ",
                type: "Computed",
                custom: 'CheckBoxSelect',
                computed: (data) => {
                    data.stt = '';
                },
                filter: {}
                // display: { list: true, add: false, edit: false }
            },*/
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },/*
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Tên',
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },*/
                /*created_at: {
                    name: 'created_at',
                    type: "Date",
                    label: 'Ngày đơn hàng',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date'
                },*/
                code: {
                    name: 'code',
                    type: "String",
                    label: 'Mã đơn hàng',
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                /*doc_created_at: {
                    name: 'doc_created_at',
                    type: "Date",
                    label: 'Ngày chứng từ',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date'
                },*/
                doc_code: {
                    name: 'doc_code',
                    type: "String",
                    label: 'Số chứng từ',
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                customer_name: {
                    name: 'customer_name',
                    type: "String",
                    label: 'Tên khách hàng',
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                info_change: {
                    name: 'info_change',
                    type: "String",
                    label: 'Thông tin thay đổi',
                    sortable: false,
                    width: 10,
                },
                unedited_content: {
                    name: 'unedited_content',
                    type: "String",
                    label: 'Nội dung chưa chỉnh sửa',
                    sortable: false,
                    width: 10,
                    // filter: {type: 'input', match: 'contains'},
                },
                edited_content: {
                    name: 'edited_content',
                    type: "String",
                    label: 'Nội dung đã chỉnh sửa',
                    sortable: false,
                    width: 10,
                    // filter: {type: 'input', match: 'contains'},
                },
                updated_at: {
                    name: 'updated_at',//
                    type: "Datetime",
                    label: 'Thời gian chỉnh sửa',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date'
                },
                date_line_editor: {
                    name: 'date_line_editor',
                    type: "Datetime",
                    label: 'Thời gian soạn hàng lại',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date'
                }
        },)
    }

}