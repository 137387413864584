    import BaseModel from '../core/BaseModel'
export default class TypeWareHouseModel extends BaseModel {
    constructor() {
        super({
            tableName: 'type_warehouse', label: "Loai kho", display_key: 'name',name: "type_warehouse",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: {
              name: 'id',
              type: "Uuid",
              label: 'ID',
              primaryKey: true,
              display: false
            },
            name : {
                name: 'name',
                type: "Text",
                label: 'Loai',
                filter: {type: 'input', match: 'contains'},
            },


            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            created_by: {
                name: "created_by",
                type: "Uuid",
                label: "Người cập nhật",
                // primaryKey: true,
                // display: false,
            },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
              filter: { type: 'DateRange', match: 'contains'},
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },




        })
    }

}