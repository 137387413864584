import BaseModel from '../core/BaseModel'
// import CountryModel from "./CountryModel";
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
export default class OrderShippingModel extends BaseModel {
    constructor() {
        super({
            tableName: 'order_shipping', label: "Quản lý điều phối", display_key: 'id',name: "order_shipping",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center',
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            type_ship: {
                name: "type_ship",
                label: "Loại điều phối",
                class: 'center',
                computed: (data) => {
                    if (data.type == 1){
                        data.type_ship = 'Giao hàng';
                    }
                },
              //  filter: {type: 'input', match: 'contains'},
            },
            /*order_deliveries: {
                name:'order_deliveries',
                label:'Loại điều phối',
                type: "Text",
                filter: {type: 'input', match: 'contains'},
            },*/

            code : {
                name: 'code',
                type: "Text",
                label: 'Mã chuyến giao',
                class:"center",
                filter: {type: 'input', match: 'contains'},
            },
            vehicle_delivery_id: {
                refs:  ' vehicle_delivery_id {  name id }',
                display: false
            },
            vehicle_id_key: {
                name: "vehicle_id_key",
                label: "Phương tiện giao",
                class: 'center',
                computed: (data) => {
                    if (data.vehicle_delivery_id){
                        data.vehicle_id_key = data.vehicle_delivery_id.name;
                    }
                },
                filter: {type: 'input', match: 'contains'},
            },
            /*vehicle_id: {
                name:'vehicle_id',
                label:'Phương tiện giao',
                type: "Text",
                filter: {type: 'input', match: 'contains'},
            },*/
            user: {
                refs:  ' user {  fullname id }',
                display: false
            },
            uid_key: {
                name: "uid_key",
                label: "Nhân viên giao",
                class: 'center',
                computed: (data) => {
                    if (data.user ){
                        data.uid_key = data.user.fullname;
                    }
                },
                filter: {type: 'input', match: 'contains'},
            },
            total_package: {
                name: "total_package",
                label: "Tổng số kiện",
                class: 'center',
                custom: "CutomCLickA",
                computed: (data) => {
                    var total = 0;
                    if (data.order_shipping_items_aggregate ){
                        if (data.order_shipping_items_aggregate.aggregate ){
                            if (data.order_shipping_items_aggregate.aggregate.sum ){
                                total = data.order_shipping_items_aggregate.aggregate.sum.total_package
                            }
                        }

                    }
                    data.total_package = total;
                },
                // filter: {type: 'input', match: 'contains'},
            },
            total_order: {
                name: "total_order",
                label: "SL đơn",
                class: 'center',
                custom: "CutomCLickA",
                computed: (data) => {
                    var total = 0;
                    if (data.order_shipping_items_count_aggregate ){
                        if (data.order_shipping_items_count_aggregate.aggregate ){
                            if (data.order_shipping_items_count_aggregate.aggregate.count ){
                                total = data.order_shipping_items_count_aggregate.aggregate.count
                            }
                        }

                    }
                    data.total_order = total;
                },
                // filter: {type: 'input', match: 'contains'},
            },
           /* note : {
                name: 'code',
                type: "Text",
                label: 'SL đơn',
            },*/
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            updated_by_id: {
                refs:"updated_by_id { fullname}",
                type: "Selection",
                label: "Người cập nhật",
                custom: 'SelectionText',
                class: 'center',
            },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
                class: 'center',
            },
            created_at : {
              name: 'created_at',
              type: "Datetime",
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },
            key_get: {
                refs: 'note order_deliveries uid vehicle_id type ',
                display: false
            },
            key_get1: {
                refs: 'order_shipping_items_count_aggregate(where: {active: {_eq: true}, deleted: {_eq: false}}) { aggregate { count  } } order_shipping_items_aggregate(where: {active: {_eq: true}, deleted: {_eq: false}},distinct_on: order_group_parcel_id) { aggregate { count sum { total_package } } } ',
                display: false
            },






        })
    }

}