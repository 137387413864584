import BaseModel from '../core/BaseModel';

export default class PromotionModel extends BaseModel {
    constructor() {
        super({ 
                name: "promotion",
                tableName: 'promotion', 
                label: "Danh sách khuyến mãi",
                display_key: 'name'
            },  
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            name : { 
                name: 'name', 
                type: "String", 
                label: 'Tên chương trình',
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            ordinal : {
                    name: 'ordinal',
                    type: "Int",
                    label: 'Số thứ tự',
                    sortable: false,
                    // display: false
            },
            date_start: {
                name: 'date_start', 
                type: "Date",
                label: 'Ngày bắt đầu',
                filter: { type: 'DateRange', match: 'contains'}
            },
            date_end: {
                name: 'date_end', 
                type: "Date",
                label: 'Ngày kết thúc',
                filter: { type: 'DateRange', match: 'contains'}
            },
            updated_at: {
                name: 'updated_at', 
                type: "Datetime",
                label: 'Cập nhật lúc',
                filter: { type: 'DateRange', match: 'contains'}
            },
            is_new : {
                name: 'is_new',
                type: "Checkbox",
                label: 'Hiện',
                sortable: false,
                disabled: true
                // display: false
            },
            is_home : {
                name: 'is_home',
                type: "Checkbox",
                label: 'Trang chủ',
                sortable: false,
                disabled: true
                // display: false
            },
            is_popup : {
                name: 'is_popup',
                type: "Checkbox",
                label: 'Popup',
                sortable: false,
                disabled: true
                // display: false
            },
        },)
    }

}