import BaseModel from "../core/BaseModel";
// import TypeReasonModel from "@/models/TypeReasonModel";
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
// import StateModel from "@/models/StateModel";
// import DistrictModel from "@/models/DistrictModel";
// import WardModel from "@/models/WardModel";
// import CountryModel from "@/models/CountryModel";
export default class FreightModel extends BaseModel {
  constructor() {
    super(
      {
        name: "freight",
        tableName: "freight",
        label: "Danh Sách Chành Xe",
        display_key: "name",
        default_order: { key: "updated_at", direction: "desc" },
      },
      {
        stt: {
          name: "stt",
          label: "STT",
          type: "Computed",
          class: "xs-col",
          computed: (data, index) => {
            data.stt = index + 1;
          },
          // display: { list: true, add: false, edit: false }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          width: 1,
          primaryKey: true,
          display: false,
        },
        code: {
          name: "code",
          type: "String",
          label: "Mã chành",
          sortable: false,
          class: "small-col",
          filter: { type: "input", match: "contains" },
        },
        name: {
          name: "name",
          type: "String",
          label: "Tên chành",
          sortable: false,
          class: "small-col",
          filter: { type: "input", match: "contains" },
        },
        address: {
          name: "address",
          type: "String",
          label: "Địa chỉ",
          sortable: false,
          filter: { type: "input", match: "contains" },
          display: false,
        },
        country: {
          refs: "country { name id }",
          display: false,
        },
        state: {
          refs: "state { name id }",
          display: false,
        },
        district: {
          refs: "district { name id }",
          display: false,
        },
        ward: {
          refs: "ward {name id}",
          display: false,
        },
        freight_customers_aggregate: {
          refs: "freight_customers_aggregate(distinct_on: customer_id where: {deleted: {_eq: false},customer_id: {_is_null: false}}) { aggregate { count } }",
          display: false,
        },
        country_id: {
          name: "country_id",
          type: "Input",
          label: "Quốc gia",
          custom: "SelectionText",
          // models: new CountryModel(),
          class: "small-col",
          filter: { type: "input", match: "contains" },
        },
        state_id: {
          name: "state_id",
          type: "Input",
          label: "Tỉnh/ Thành phố",
          custom: "SelectionText",
          // models: new StateModel(),
          class: "small-col",
          sortable: false,
          filter: { type: "input", match: "contains" },
          // computed: (data) => {
          //   data.state_id = "";
          // },
        },
        district_id: {
          name: "district_id",
          type: "Input",
          label: "Quận/ Huyện",
          custom: "SelectionText",
          // models: new DistrictModel(),
          class: "small-col",
          sortable: false,
          filter: { type: "input", match: "contains" },
        },
        ward_id: {
          name: "ward_id",
          type: "Input",
          label: "Phường/ Xã",
          custom: "SelectionText",
          // models: new WardModel(),
          class: "small-col",
          sortable: false,
          filter: { type: "input", match: "contains" },
        },
        street: {
          name: "street",
          type: "String",
          label: "Số nhà, tên đường",
          class: "medium-col",
          sortable: false,
          filter: { type: "input", match: "contains" },
        },
        customer_count: {
          name: "customer_count",
          type: "Computed",
          custom: "SelectionLink",
          label: "Số khách hàng",
          class: "small-col popup-link",
          computed: (data) => {
            data.customer_count = 0;
          },
        },
        active: {
          name: "active",
          type: "Selection",
          label: "Tình trạng hoạt động",
          custom: "SelectionText",
          class: "small-col",
          options: [
            { value: true, label: "Có", style: "color:  #000000;" },
            { value: false, label: "Không", style: "color:  #000000;" },
          ],
          sortable: false,
          disabled: true,
          filter: {
            type: "select",
            optionLabel: "label",
            optionKey: "value",
            match: "equals",
          },
          // display: false
        },
        // updated_by: {
        //   name: "updated_by",
        //   type: "Uuid",
        //   label: "Người cập nhật",
        //   class: "xs-col",
        //   // primaryKey: true,
        //   //   display: false,
        // },
        updated_by_id: {
          refs: "updated_by_id { fullname}",
          type: "String",
          label: "Người cập nhật",
          class: "small-col",
          custom: "SelectionText",
        },
        created_by: {
          name: "created_by",
          type: "Uuid",
          label: "ID",

          primaryKey: true,
          display: false,
        },
        created_at: {
          name: "created_at",
          type: "Datetime",
          label: "Ngày tạo",
          sortable: false,
          // filter: { type: "DateRange", match: "contains" },
          display: false,
        },
        updated_at: {
          name: "updated_at",
          type: "Datetime",
          label: "Ngày cập nhật",
          class: "small-col",
          sortable: false,
          // filter: { type: "DateRange", match: "contains" },
          // display: false
        },
        deleted: {
          name: "deleted",
          type: "Boolean",
          label: "Đã xóa",
          sortable: false,
          display: false,
        },

        /* rel_object_group_uses: {
                    refs: `rel_object_group_uses {
                            group_id
                        }`,
                    display: false
                },
                name_group: {
                    name: 'name_group',
                    type: "String",
                    label: 'Nhóm quyền',
                    sortable: false,
                    display: false
                },
                name_group_filter: {
                    name: 'name_group_filter',
                    type: "Selection",
                    label: 'Nhóm quyền',
                    sortable: false,
                    width: 10,
                    models: new GroupModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.name_group_filter = '';
                    }
                },*/
      }
    );
  }
}
