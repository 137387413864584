import BaseModel from '../core/BaseModel';
import CompanyModel from "./CompanyModel";
// import HolidayItemModel from "./HolidayItemModel";
// import BranchModel from './BranchModel';
import Common from '../core/Common';
import moment from 'moment';
export default class HolidayModel extends BaseModel {
    constructor() {
        super({ 
                name: "holiday", tableName: 'holiday', label: "Danh Sách Ngày Nghỉ", display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    class:"center common",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                company_ids: {
                    refs: 'company_ids { id code name }',
                    display: false
                },
                // company_id: {
                //     name:'company_id',
                //     label:'Công ty',
                //     type: "Selection",
                //     custom: 'SelectionText',
                //     models: new CompanyModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     class:"center width_320 common",
                //     computed: (data) => {
                //         data.company_id = '';
                //     },
                // },
                company_id: Common.makeFilterCompany('company_id', 'Mã công ty', new CompanyModel(), "holiday", "center width_320 common"),
                year: {
                    name: "year",
                    label: "Năm",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: get_nam(),
                    filter: {type: 'select', optionLabel: 'label', optionKey: 'label', match: 'equals'
                    },
                    class:"center text_align_des width_160 common",
                //     computed: (data) => {
                //         // var arr_year = []
                //         // const currentYear = new Date().getFullYear();
                //         // const startYear = 2014; // hoặc năm bắt đầu mong muốn
                //         // for (let year = currentYear + 100; year >= startYear; year--) {
                //         //     arr_year.push(year);
                //         // }
                //                 data.year = get_nam() ;
                //             },
                },

            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
                holiday_items_key: {
                    name: "holiday_items_key",
                    label: 'Danh sách ngày nghỉ',
                    type: "Computed",
                    filter: {type: 'input', match: 'contains'},
                    class:"center width_320 common",
                    computed: (data) => {
                        var  list= data.holiday_items;
                        var list_day =''
                        for (var i = 0 ; i < list.length ; i++)
                        {
                            if (i != list.length -1)
                            {
                                list_day += moment(list[i].date).format("MM/DD")+", "
                            }

                            else
                            {
                                list_day += moment(list[i].date).format("MM/DD")
                            }
                        }
                        data.holiday_items_key = list_day;
                    },
                },
                holiday_items: {
                    refs: 'holiday_items (where: {deleted: {_eq: false}}) { date }',
                    display: false
                },
                updated_by_id: {
                    refs:"updated_by_id { fullname}",
                    type: "Selection",
                    label: "Người cập nhật",
                    custom: 'SelectionText',
                    class:"center width_160 common",
                },
                text_date_all: {
                    name:"text_day_all",
                    type: "Text",
                    label: "Người cập nhật",
                    display:false
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Ngày cập nhật',
                    class:"center width_320 common",
                },


            },)
        function get_nam (){
            var arr_year=[]
            const currentYear = new Date().getFullYear();
            const startYear = 2014; // hoặc năm bắt đầu mong muốn
            for (let year = currentYear + 100; year >= startYear; year--) {
                var  item_date = {
                    "value":year,
                    "label":year
                }
                arr_year.push(item_date);
            }
            return arr_year
        }
    }



}