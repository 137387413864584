import BaseModel from "../core/BaseModel";
// import TypeReasonModel from "@/models/TypeReasonModel";
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class ReasonModel extends BaseModel {
  constructor() {
    super(
      {
        name: "reason",
        tableName: "reason",
        label: "Danh Sách Lý Do",
        display_key: "name",
        default_order: { key: "updated_at", direction: "desc" },
      },
      {
        stt: {
          name: "stt",
          label: "STT",
          type: "Computed",
          class: "xs-col",
          computed: (data, index) => {
            data.stt = index + 1;
          },
          // display: { list: true, add: false, edit: false }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          // width: 1,
          primaryKey: true,
          display: false,
        },
        name: {
          name: "name",
          type: "String",
          label: "Lý do",
          sortable: false,
          class: "medium-col",
          // width: 5,
          filter: { type: "input", match: "contains" },
        },
        type: {
          name: "type",
          label: "Phân loại",
          type: "Selection",
          custom: "SelectionText",
          class: "small-col",
          // models: new TypeReasonModel(),
          options: [
            { value: "1", label: "Lý do trả hàng", style: "color:  #000000;" },
            {
              value: "2",
              label: "Lý do giao hàng không thành công",
              style: "color:  #000000;",
            },
            { value: "3", label: "Lý do huỷ đơn", style: "color:  #000000;" },
          ],
          filter: {
            type: "select",
            optionLabel: "label",
            optionKey: "value",
            match: "equals",
          },
          // computed: (data) => {
          //   data.type = "",
          // },
        },
        cancel_at_warehouse:{
          name: "cancel_at_warehouse",
          type: "Boolean",
          display: false,
        },
        company_pay: {
          name: "company_pay",
          type: "Selection",
          label: "Lê Mây chịu phí",
          custom: "SelectionText",
          sortable: false,
          disabled: true,
          class: "small-col",
          options: [
            { value: true, label: "Có", style: "color:  #000000;" },
            { value: false, label: "Không", style: "color:  #000000;" },
          ],
          filter: {
            type: "select",
            optionLabel: "label",
            optionKey: "value",
            match: "equals",
          },

          // display: false
        },

        active: {
          name: "active",
          type: "Selection",
          label: "Hiển thị",
          custom: "SelectionText",
          sortable: false,
          disabled: true,
          class: "small-col",
          options: [
            { value: true, label: "Có", style: "color:  #000000;" },
            { value: false, label: "Không", style: "color:  #000000;" },
          ],
          filter: {
            type: "select",
            optionLabel: "label",
            optionKey: "value",
            match: "equals",
          },
          // display: false
        },
        created_by: {
          name: "created_by",
          type: "Uuid",
          label: "ID",
          display: false,
        },
        created_at: {
          name: "created_at",
          type: "Datetime",
          label: "Ngày tạo",
          sortable: false,
          // filter: { type: "DateRange", match: "contains" },
          display: false,
        },
        updated_by_id: {
          refs: "updated_by_id { fullname}",
          type: "String",
          label: "Người cập nhật",
          class: "small-col",
          custom: "SelectionText",
        },
        updated_at: {
          name: "updated_at",
          type: "Datetime",
          label: "Ngày cập nhật",
          sortable: false,
          class: "small-col",
          // filter: { type: "DateRange", match: "contains" },
          // display: false
        },
        deleted: {
          name: "deleted",
          type: "Boolean",
          label: "Đã xóa",
          sortable: false,
          display: false,
        },

        /* rel_object_group_uses: {
                    refs: `rel_object_group_uses {
                            group_id
                        }`,
                    display: false
                },
                name_group: {
                    name: 'name_group',
                    type: "String",
                    label: 'Nhóm quyền',
                    sortable: false,
                    display: false
                },
                name_group_filter: {
                    name: 'name_group_filter',
                    type: "Selection",
                    label: 'Nhóm quyền',
                    sortable: false,
                    width: 10,
                    models: new GroupModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.name_group_filter = '';
                    }
                },*/
      }
    );
  }
}
