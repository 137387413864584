import BaseModel from '../core/BaseModel'
// import SportsModel from './SportsModel';
export default class ActiveUsersModel extends BaseModel {

    constructor() {
        super({
                name: "user",
                tableName: 'users',
                label: "Active users",
                /* display_key: 'fullname',
                 default_order: { key: 'updated_at', direction: 'asc' }*/
            },
            {
                stt: {
                    name: "stt",
                    label: "No.",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Full Name',
                    width : 40,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },
                type_login: {
                    name: "type_login",
                    label: "Type Login",
                    type: "Selection",
                    custom: 'SelectionText',
                    width : 6,
                    options: [
                      { value: 'USERNAME', label: 'Username',style: 'color:  #000000;'},
                      { value: 'FACEBOOK', label: 'Facebook',style: 'color:  #000000;'},
                      { value: 'GOOGLE', label: 'Google',style: 'color:  #000000;'},
                      { value: 'APPLE', label: 'Apple ID',style: 'color:  #000000;'},
                    ],
                    filter: {
                      type: 'select',
                      optionLabel: 'label',
                      optionKey: 'value',
                      match: 'equals'
                    },
                    computed: (data) => {
                      data.type_login = '';
                    }
                },
                email : {
                    name: 'email',
                    type: "String",
                    label: 'Email',
                    width : 40,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },
                // name_sports: {
                //     name: "name_sports",
                //     label: "Sport",
                //     type: "Selection",
                //     custom: 'SelectionText',
                //     // width : 6,
                //     models: new SportsModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     computed: (data) => {
                //         data.name_sports = null;
                //     }
                // },
                club_html: {
                    name: "club_html",
                    label: "Join Club",
                    type: "Text",
                    computed: (data) => {
                      data.club_html = '';
                    }
                },
                active: {
                    name: "active",
                    label: "Hiển thị",
                    type: "Checkbox",
                    display : false
                },
                active_html: {
                    name: "active_html",
                    label: "Status",
                    type: "Selection",
                    custom: 'SelectionText',
                    width : 3,
                    options: [
                      { value: 'true', label: 'Active',style: 'color:  #000000;'},
                      { value: 'false', label: 'Lock',style: 'color:  #000000;'},
                    ],
                    filter: {
                      type: 'select',
                      optionLabel: 'label',
                      optionKey: 'value',
                      match: 'equals'
                    },
                    computed: (data) => {
                      data.active_html = '';
                    }
                },
                // created_at : { 
                //     name: 'created_at', 
                //     type: "Datetime",
                //     label: 'Created Date',
                //     filter: { type: 'DateRange', match: 'contains'}
                // },
                // expiration_date : { 
                //     name: 'expiration_date', 
                //     type: "CustomDate",
                //     label: 'Expired Date',
                //     filter: { type: 'DateRange', match: 'contains'},
                //     computed: (data) => {
                //         data.expiration_date = null;
                //     }
                // }
                // role: {
                //     name: 'role',
                //     type: "String",
                //     label: 'Type',
                //     display: false
                // },
            },)
    }

}