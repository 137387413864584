import BaseModel from '../core/BaseModel'
// import moment from "moment/moment";
//import gql from 'graphql-tag'
export default class GroupModel extends BaseModel {

    constructor() {
        super({ 
                name: "group",
                tableName: 'group',
                label: "Danh sách nhóm quyền",
                display_key: 'name',
                default_order: { key: 'ordinal', direction: 'asc' } ,
            },
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Bigint",
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            name: {
                name: 'name',
                type: "String",
                label: 'Tên nhóm quyền',
                width: 15,
                class:"width",
                filter: {type: 'input', match: 'contains'},
                // display: false
            },
            group_roles: {
                refs: `group_roles {
                        id
                        role_id
                    }`,
                display: false
            },
                group_users: {
                    refs:"group_users { uid group_id}",
                    type: "CustomUrl",
                    label: "Đang áp dụng",
                    class:"center width_160 common",
                },

                updated_by_id: {
                    refs:"updated_by_id { fullname}",
                    type: "Selection",
                    label: "Người cập nhật",
                    custom: 'SelectionText',
                    class:"center width_160 common",
                },


                // updated_by_id: {
                //     refs: 'updated_by_id (where: {deleted: {_eq: false}}) { fullname }',
                //     display: false
                // },
                // updated_by: {
                //     name: "updated_by",
                //     label: 'Người cập nhật',
                //     type: "Computed",
                //     // filter: {type: 'input', match: 'contains'},
                //     class:"center width_320 common",
                //     computed: (data) => {
                //         console.log("Người cập nhật")
                //         var  list = data.updated_by_id;
                //         data.updated_by_id = list;
                //     },
                // },


                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Ngày cập nhật',
                    class:"center width_160 common",
                },


            
        },)
    }

}
