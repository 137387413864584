import BaseModel from "../core/BaseModel";
export default class DepartmentModel extends BaseModel {
  constructor() {
    super(
      {
        name: "department",
        tableName: "department",
        label: "Bộ phận",
        display_key: "name",
      },
      {
        id: {
          name: "id",
          type: "Number",
          label: "ID",
          primaryKey: true,
          display: false,
        },

        name: {
          name: "name",
          type: "String",
          label: "Tên Bộ phận",
          filter: { type: "input", match: "contains" },
        },
        type: {
          name: "type",
          type: "Number",
          // custom: "SelectionText",
          label: "Loại Bộ phận",
          filter: { type: "input", match: "contains" },
        },
        status_order: {
          name: "status_order",
          type: "String",
          label: "Thứ tự hiển thị",
        },
        /*fullname: {
                    name: "fullname",
                    type: "String",
                    label: "Tên Quốc Gia",
                    filter: { type: "input", match: "contains" },
                },*/
        ordinal: {
          name: "ordinal",
          type: "Number",
          label: "Thứ tự",
        },
        updated_at: {
          name: "updated_at",
          type: "Datetime",
          label: "Ngày cập nhật",
          filter: { type: "DateRange", match: "contains" },
        },
        created_at: {
          name: "created_at",
          type: "Datetime",
          label: "Ngày tạo",
          filter: { type: "DateRange", match: "contains" },
          display: false,
        },
        created_by: {
          name: "created_by",
          type: "Uuid",
          label: "Người tạo",
          //   primaryKey: true,
          display: false,
        },
        updated_by: {
          name: "updated_by",
          type: "Uuid",
          label: "Người cập nhật",
          display: false,
        },

        deleted: {
          name: "deleted",
          label: "Xóa",
          type: "Checkbox",
          display: false,
        },
      }
    );
  }
}
