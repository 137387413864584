import BaseModel from '../core/BaseModel';
import CompanyModel from "./CompanyModel";
import MaterialModel from "./MaterialModel";
import Common from "../core/Common";
export default class InventoryReportModel extends BaseModel {
    constructor() {
        super({ 
                name: "inventory_report", tableName: 'stock_quant', label: "Báo Cáo Tồn Kho", display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    width: 50,
                    class: " p-1",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            company_code: Common.makeFilterCompany('company_code', 'Mã công ty', new CompanyModel(), "inventory_report","left",null,126),
            /*company_code: {
              name: "company_code",
              width: 126,
              label: "Mã công ty",
              class: "left",
              type: "Selection",
              custom: 'SelectionText',
              models: new CompanyModel(),
              filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
              computed: (data) => {
                data.company_code = '';
              }
            },*/
            product: { 
                refs: 'product {id name code unit{id name} material{id name code} company{id code}}',
                display: false
            },
            product_code : { 
                name: 'product_code', 
                type: "String", 
                label: 'Mã sản phẩm',
                width: 126,
                sortable: false,
                class: "left",
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.product_code = '';
                }
            },
            product_name : { 
                name: 'product_name', 
                type: "String", 
                label: 'Tên sản phẩm',
                class: "left",
                width: 252,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.product_name = '';
                }
            },
            unit_name : { 
                name: 'unit_name', 
                type: "String", 
                label: 'Đơn vị tính',
                class: "left",
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.unit_name = '';
                }
            },
            lot_number : { 
                name: 'lot_number', 
                type: "String", 
                label: 'Mã lô',
                width: 126,
                class: "left",
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            expiry_date : { 
                name: 'expiry_date', 
                type: "Date",
                class: "left",
                width: 126,
                label: 'Hạn sử dụng'
            },
            material_code : { 
                name: 'material_code', 
                label: 'Mã nhóm VTHH',
                width: 126,
                class: "left",
                type: "Selection",
                custom: 'SelectionText',
                models: new MaterialModel('code'),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.material_code = '';
                }
            },
            material_name : { 
                name: 'material_name', 
                type: "String", 
                class: "left",
                label: 'Nhóm VTHH',
                sortable: false,
                width: 126,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.material_name = '';
                }
            },
            qty: { 
                width: 126,
                name: 'qty', 
                type: "Number",
                class: "left",
                label: 'SL kiểm tồn',
                display: false
            },
            qty_import: {
                width: 126,
                name: 'qty_import', 
                type: "Number",
                class: "left",
                label: 'SL nhập trong kỳ',
                display: false
            },
            qty_export: {
                width: 126,
                name: 'qty_export', 
                type: "Number",
                class: "left",
                label: 'SL xuất trong kỳ',
                display: false
            },
            qty_pending: {
                width: 126,
                name: 'qty_pending', 
                type: "Number",
                class: "left",
                label: 'SL hàng chờ xuất',
                display: false
            },
            qty_sale: {
                width: 126,
                name: 'qty_sale',
                type: "Number",
                class: "left",
                label: 'SL tồn có thể bán',
                display: false
            },
            qty_total: {
                width: 126,
                name: 'qty_total', 
                type: "Number",
                class: "left",
                label: 'SL tồn kỳ cuối',
                display: false
            },
            
            qty_txt: {
                width: 126,
                name: 'qty_txt', 
                type: "String",
                class: "left",
                label: 'SL tồn đầu kỳ',
                computed: (data) => {
                    data.qty_txt = 0;
                }
            },
            
            qty_import_txt: {
                width: 126,
                name: 'qty_import_txt', 
                type: "String",
                class: "left",
                label: 'SL nhập trong kỳ',
                computed: (data) => {
                    data.qty_import_txt = 0;
                }
            },
            qty_export_txt: {
                width: 126,
                name: 'qty_export_txt', 
                type: "String",
                class: "left",
                label: 'SL xuất trong kỳ',
                computed: (data) => {
                    data.qty_export_txt = 0;
                }
            },
            qty_pending_txt: {
                width: 126,
                name: 'qty_pending_txt', 
                type: "String",
                class: "left",
                label: 'SL hàng chờ xuất',
                computed: (data) => {
                    data.qty_pending_txt = 0;
                }
            },
            qty_sale_txt: {
                width: 126,
                name: 'qty_sale_txt',
                type: "String",
                class: "left",
                label: 'SL tồn có thể bán',
                computed: (data) => {
                    data.qty_sale_txt = 0;
                }
            },
            qty_total_txt: {
                width: 126,
                name: 'qty_total_txt', 
                type: "String",
                class: "left",
                label: 'SL tồn cuối kỳ',
                computed: (data) => {
                    data.qty_total_txt = 0;
                }
            },
            // warehouse: { 
            //     refs: 'warehouse {id name code}',
            //     display: false
            // },
            // warehouse_code : { 
            //     name: 'warehouse_code', 
            //     label: 'Mã kho',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new WarehouseModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.warehouse_code = '';
            //     }
            // },
            // warehouse_name : { 
            //     name: 'warehouse_name', 
            //     type: "String", 
            //     label: 'Tên kho',
            //     sortable: false,
            //     filter: { 
            //         type: 'input', 
            //         match: 'contains' 
            //     },
            //     computed: (data) => {
            //         data.warehouse_name = '';
            //     }
            // },
            // warning_expiry : { 
            //     name: 'warning_expiry', 
            //     label: 'Cảnh báo HSD',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     options: [
            //         { 'value': 1, 'label': '<1' },
            //         { 'value': 3, 'label': '1-3' },
            //         { 'value': 6, 'label': '3-6' },
            //         { 'value': 12, 'label': '6-12' },
            //         { 'value': 13, 'label': '>12' }
            //     ],
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.warning_expiry = '';
            //     }
            // },
            // updated_at: {
            //     name: 'updated_at', 
            //     type: "Datetime",
            //     label: 'Lần cập nhật cuối'
            // }
        },)
    }

}