import BaseModel from "../core/BaseModel";

export default class DashboardModel extends BaseModel {
  constructor() {
    super(
      {
        name: "dashboard",
        tableName: "channel",
        label: "Dashboard",
        display_key: "id",
      },
      {
        id: {
          name: "id",
          type: "Int",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        customers: {
          ref: "customers {orders { paid order_status { code } } }",
          display: false
        },
        code: {
            name: "code",
            label: "Mã",
            type: "Text",
            filter: {type: 'input', match: 'contains'}
        },
        
        name: {
            name: "name",
            label: "Chi tiết",
            width: 40,
            type: "Text",
            filter: {type: 'input', match: 'contains'}
        },
        waiting_delivery: {
            name: "waiting_delivery",
            label: "Đang giao hàng",
            type: "Number",
            class: 'right',
            width: 15,
            computed: (data) => {
              data.waiting_delivery = 0;
            }
        },
        preparing_goods: {
          name: "preparing_goods",
          label: "Đang soạn hàng",
          type: "Number",
          class: 'right',
          width: 15,
          computed: (data) => {
            data.preparing_goods = 0;
          }
        },
        waiting_payment: {
          name: "waiting_payment",
          label: "Chờ thanh toán",
          type: "Number",
          class: 'right',
          width: 15,
          computed: (data) => {
            data.waiting_payment = 0;
          }
        },
        waiting_export: {
          name: "waiting_export",
          label: "Chờ xuất",
          type: "Number",
          class: 'right',
          width: 15,
          computed: (data) => {
            data.waiting_export = 0;
          }
        },
        total: {
          name: "total",
          label: "Tổng",
          type: "Number",
          class: 'right',
          width: 15,
          computed: (data) => {
            data.total = 0;
          }
        },
      }
    );
  }
}
