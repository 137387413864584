import BaseModel from '../core/BaseModel'
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class OrderCustomWareHouseModel extends BaseModel {

    constructor() {
        super({ 
                name: "order_custom_warehouse",
                tableName: 'order',
                label: "Danh Sách Đơn hàng",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            /*check_box: {
                name: "check_box",
                label: " ",
                type: "Computed",
                custom: 'CheckBoxSelect',
                computed: (data) => {
                    data.stt = '';
                },
                filter: {}
                // display: { list: true, add: false, edit: false }
            },*/
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },/*
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Tên',
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },*/
               /* created_at: {
                    name: 'created_at',
                    type: "Date",
                    label: 'Ngày đơn hàng',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date'
                },*/
                order_shipping_item: {
                    refs: 'order_shipping_item { order_shipping { code id } }',
                    display: false
                },
                obj_order_item: {
                    refs: 'obj_order_item { warehouseByWarehouseId { name id } }',
                    display: false
                },
                order_shipping_code: {
                    name: "order_shipping_code",
                    label: "Chuyến giao",
                    type: "Computed",
                    computed: (data) => {
                        if (data.order_shipping_item){
                            data.order_shipping_code = data.order_shipping_item.order_shipping ? data.order_shipping_item.order_shipping.code:'';
                        }
                    },
                    // display: { list: true, add: false, edit: false }
                },
                code_custom: {
                    name: "code_custom",
                    label: 'Mã đơn hàng',
                    class: 'left txt-width_common-comlumn',
                    custom: "CutomCLickA",
                    width: 10,
                    computed: (data) => {
                        /*var total = 0;
                        if (data.order_shipping_items_aggregate ){
                            if (data.order_shipping_items_aggregate.aggregate ){
                                if (data.order_shipping_items_aggregate.aggregate.count ){
                                    total = data.order_shipping_items_aggregate.aggregate.count
                                }
                            }

                        }*/
                        data.code_custom = '';
                        // console.log("wqwqwqwqwqwqw",data.delivery_trip_number_key)
                    },
                    // filter: {type: 'input', match: 'contains'},
                },
                /*code: {
                    name: 'code',
                    type: "String",
                    label: 'Mã đơn hàng',
                    sortable: false,
                    class:"link-code",
                    width: 10,
                    /!*filter: {type: 'input', match: 'contains'},*!/
                },*/
               /* doc_created_at: {
                    name: 'doc_created_at',
                    type: "Date",
                    label: 'Ngày chứng từ',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date'
                },*/
                doc_code: {
                    name: 'doc_code',
                    type: "String",
                    label: 'Số chứng từ',
                    sortable: false,
                    width: 10,
                   /* filter: {type: 'input', match: 'contains'},*/
                },
                total_package: {
                    name: 'total_package',
                    type: "String",
                    label: 'Tổng số kiện',
                    sortable: false,
                    width: 10,
                    /*filter: {type: 'input', match: 'contains'},*/
                },
                name_warehouse: {
                    name: "name_warehouse",
                    label: "Tên kho",
                    type: "Computed",
                    computed: (data) => {
                        if (data.obj_order_item){
                            data.name_warehouse = data.obj_order_item.warehouseByWarehouseId ? data.obj_order_item.warehouseByWarehouseId.name:'';
                        }
                    },
                    // display: { list: true, add: false, edit: false }
                },
                detail_text: {
                    name: "detail_text",
                    label: "Chi tiết",
                    type: "Computed",
                    computed: (data) => {
                        data.detail_text = '';
                    },
                    // display: { list: true, add: false, edit: false }
                },
                key_get1: {
                    refs:  'code ',
                    display: false
                },
                /*order_status: {
                    refs: 'order_status { id name }',
                    display: false
                },
                current_order_status_id: {
                    name: 'current_order_status_id',
                    type: "Bigint",
                    label: 'Trạng thái',
                    display: false
                },
                date_expected: {
                    name: 'date_expected',
                    type: "Date",
                    label: 'Ngày giao dự kiến',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date'
                },*/
                /*total_certification_documents: {
                    name: "total_certification_documents",
                    label: "HSCT nhận",
                    type: "Computed",
                    computed: (data) => {
                        data.total_certification_documents = 0;
                    },
                },*/
                /*total_number_request: {
                    name: "total_number_request",
                    type: "Selection",
                    label: "HSCT nhận",
                    custom: "SelectionText",
                    sortable: false,
                    disabled: true,
                    options: [
                        { value: true, label: "Đủ", style: "color:  #000000;" },
                        { value: false, label: "Không đủ", style: "color:  #000000;" },
                    ],
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    },
                    // display: false
                },
                total_package: {
                    name: 'total_package',
                    type: "String",
                    label: 'Tổng số kiện',
                    sortable: false,
                    width: 10,
                    /!*filter: {type: 'input', match: 'contains'},*!/
                },
                total_deliveries: {
                    name: "total_deliveries",
                    label: "Lần giao thứ",
                    type: "Computed",
                    computed: (data) => {
                        data.total_number_packages = 0;
                    },
                },
                staff_name: {
                    name: 'staff_name',
                    type: "String",
                    label: 'Nhân viên giao',
                    sortable: false,
                    width: 10,
                    filter: {type: 'input', match: 'contains'},
                },
                vehicle: {
                    refs: 'vehicle { id name }',
                    display: false
                },
                vehicle_id: {
                    name: 'vehicle_id',
                    type: "Bigint",
                    label: 'Phương tiện giao',
                    display: false
                },
                updated_at: {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Lần cập nhật cuối',
                    filter: { type: 'DateRange', match: 'contains'},
                    class:'style-text-date'
                },*/
                /*hide column*/
              /*  customer_code: {
                    name: 'customer_code',
                    type: "String",
                    label: 'customer_code',
                    display: false
                },
                customer_phone: {
                    name: 'customer_phone',
                    type: "String",
                    label: 'customer_phone',
                    display: false
                },
                customer_address: {
                    name: 'customer_address',
                    type: "String",
                    label: 'customer_address',
                    display: false
                },
                explain: {
                    name: 'explain',
                    type: "String",
                    label: 'explain',
                    display: false
                },
                date_order: {
                    name: 'date_order',
                    type: "Datetime",
                    label: 'date_order',
                    filter: { type: 'DateRange', match: 'contains'},
                    display: false
                },
                date_done: {
                    name: 'date_done',
                    type: "Datetime",
                    label: 'date_done',
                    filter: { type: 'DateRange', match: 'contains'},
                    display: false
                },
                request_payment: {
                    name: 'request_payment',
                    type: "String",
                    label: 'explain',
                    display: false
                },
                date_paid: {
                    name: 'date_paid',
                    type: "Datetime",
                    label: 'date_paid',
                    filter: { type: 'DateRange', match: 'contains'},
                    display: false
                },
                user_id: {
                    name: 'user_id',
                    type: "String",
                    label: 'user_id',
                    display: false
                },*/
                /*total_number_request: {
                    name: 'total_number_request',
                    type: "Bigint",
                    label: 'total_number_request',
                    display: false
                },*/
                /*total_number_process: {
                    name: 'total_number_process',
                    type: "Bigint",
                    label: 'total_number_process',
                    display: false
                },
                key_get: {
                    refs: 'paid order_group_parcel_id ',
                    display: false
                },*/


        },)
    }

}