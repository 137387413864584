import BaseModel from '../core/BaseModel'
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelModel from "./ChannelModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import GroupChannelCompanyModel from "./GroupChannelCompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import GroupChannelModel from "./GroupChannelModel";
export default class ReportDocumentModel extends BaseModel {
    constructor() {
        super({
            tableName: 'order', label: "Báo Cáo Giao Nhận Hồ Sơ Chứng Từ", display_key: 'name',name: "order_docs",
        }, {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            code: {
                name:'code',
                label:'Mã đơn hàng ',
                type: "Text",
                filter: {type: 'input', match: 'contains'},
                class:"width_135 common",
            },

            doc_code: {
                name:'doc_code',
                label:'Số chứng từ',
                type: "Text",
                filter: {type: 'input', match: 'contains'},
                class:"width_135 common",
            },
            customer_code: {
                name:'customer_code',
                label:'Mã khách hàng',
                type: "Text",
                filter: {type: 'input', match: 'contains'},
                class:"width_135 common",
            },
            customer_name: {
                name:'customer_name',
                label:'Tên khách hàng',
                type: "Text",
                filter: {type: 'input', match: 'contains'},
                class:"width_135 common",
            },
            detail_docs: {
                name: "detail_docs",
                label: 'Bộ hồ sơ chứng từ, check list xác nhận',
                type: "Computed",
                computed: (data) => {
                    var order_documents = data.order_documents
                    var text_data = ""
                    if (order_documents){
                        for (var i=0;i<order_documents.length;i++){
                            var number_process = (order_documents[i]["number_process"]) ? order_documents[i]["number_process"]:0;
                            var number_request = order_documents[i]["number_request"];
                            var name = (order_documents[i]["document"]) ? order_documents[i]["document"]["name"]:'';
                            var text_str = `${number_process}/${number_request} ${name} `
                            text_data +=text_str
                        }
                    }
                    data.detail_docs = text_data;
                },
                // display: { list: true, add: false, edit: false }
            },
            note_profile: {
                name:'note_profile',
                label:'Ghi chú chứng từ',
                type: "Text",
                class:"width_135 common",
            },
            documents_received: {
                name: "documents_received",
                label: 'Đã nhận chứng từ',
                type: "Computed",
                computed: (data) => {
                    var total_number_request = (data.total_number_request) ? data.total_number_request:0
                    var total_number_process = (data.total_number_process) ? data.total_number_process:0
                    var text = `${total_number_process}/${total_number_request} `
                    data.documents_received = text;
                },
                // display: { list: true, add: false, edit: false }
            },
            date_done : {
                name: 'date_done',
                type: "Datetime",
                label: 'Thời gian giao hàng thành công',
                filter: { type: 'DateRange', match: 'contains'},
            },
            transfer_docs_date : {
                name: 'transfer_docs_date',
                type: "Datetime",
                label: 'Thời gian kho chuyển bộ hồ sơ chứng từ',
                filter: { type: 'DateRange', match: 'contains'},
            },
            /*warehouse_time: {
                name: "warehouse_time",
                label: 'Thời gian kho chuyển bộ hồ sơ chứng từ',
                type: "Computed",
                filter: { type: 'DateRange', match: 'contains'},
                computed: (data) => {
                    data.warehouse_time = data.warehouse_time_key
                },
                // display: { list: true, add: false, edit: false }
            },*/
            is_enough_documents: {
                name: "is_enough_documents",
                label: "Kế toán xác nhận bộ hồ sơ chứng từ",
                type: "Selection",
                custom: 'SelectionText',
                options: [
                    {value: 'true', label: 'Có', style: 'color:  #000000;'},
                    {value: 'false', label: 'Không',style: 'color:  #000000;'}
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
                class:"center width_90 common",
            },
            /*is_enough_documents: {
                name: "is_enough_documents",
                label: "Kế toán xác nhận bộ hồ sơ chứng từ",
                type: "Selection",
                //custom: 'SelectionText',
                width : 6,
                options: [
                    { value: false, label: 'Không',style: 'color:  #000000;'},
                    { value: true, label: 'Có',style: 'color:  #000000;'},
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
                computed: (data) => {
                    data.accountant_confirms = "";
                }
            },*/
            confirmation_docs_date : {
                name: 'confirmation_docs_date',
                type: "Datetime",
                label: 'Thời gian Kế toán xác nhận',
                filter: { type: 'DateRange', match: 'contains'},
            },
            /*confirmation_docs_date: {
                name: "confirmation_docs_date",
                label: 'Thời gian Kế toán xác nhận',
                type: "Computed",
                filter: { type: 'DateRange', match: 'contains'},
                computed: (data) => {
                    data.date_accountant_confirms = data.date_accountant_confirms_key
                },
                // display: { list: true, add: false, edit: false }
            },*/
            /*created_at : {
                name: 'created_at',
                type: "Datetime",
                label: 'Thời gian kho chuyển bộ hồ sơ chứng từ',
                filter: { type: 'DateRange', match: 'contains'},
                display : false
            },*/
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            key_get: {
                refs: 'total_number_request total_number_process order_documents { number_process number_request document { code name id type } }',
                display: false
            },
            /*
            total_docs : {
                name: 'total_docs',
                type: "Text",
                label: 'Phương tiện vận chuyển',
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                class:"width_135 common"
            },
            area_type : {
                name: 'area_type',
                type: "Text",
                label: 'Khu vực giao hàng',
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                class:"width_135 common"
            },
            unit : {
              name: 'unit',
              type: "Text",
              label: 'Đơn vị tính',
                class:"width_135 common",
            },
            unit_price : {
                name: 'unit_price',
                type: "Text",
                label: 'Đơn giá',
                class:"width_135 common",
            },
            qty : {
                name: 'quantity',
                type: "Text",
                label: 'Số lượng',
                class:"width_135 common",
            },
            total_price : {
                name: 'total_price',
                type: "Text",
                label: 'Thành tiền',
                class:"width_135 common",
            },
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },*/
            /*created_at : {
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },*/




        })
    }

}