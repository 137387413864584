import BaseModel from '../core/BaseModel'
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
export default class ParcelModel extends BaseModel {
    constructor() {
        super({
            tableName: 'parcel', label: "Loại kiện hàng", display_key: 'id',name: "parcel",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: ' width_240 common',
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },

            name : {
              name: 'name',
              type: "Text",
              label: 'Kiện hàng ',
              filter: {type: 'input', match: 'contains'},
                class:" width_240 common",
            },
            size : {
                name: 'size',
                type: "Text",
                label: 'Kích thước (m³)',
                filter: {type: 'input', match: 'contains'},
                class:"text_align  width_240 common",

            },
            ordinal : {
                name: 'ordinal',
                type: "Text",
                label: 'Thứ tự',
                //filter: {type: 'input', match: 'contains'},
                class:"text_align  width_240 common",

            },
            active: {
                name: "active",
                label: "Hiển thị",
                type: "Selection",
                custom: 'SelectionText',
                options: [
                    {value: 'true', label: 'Có', style: 'color:  #000000;'},
                    {value: 'false', label: 'Không',style: 'color:  #000000;'}
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
                class:" width_240 common",
            },
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            updated_by_id: {
                refs:"updated_by_id { fullname}",
                type: "Selection",
                label: "Người cập nhật",
                custom: 'SelectionText',
                class:" width_240 common",
            },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
                class:" width_240 common",
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },
            key_get: {
                refs: 'type',
                display: false
            },



        })
    }

}