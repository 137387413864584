import BaseModel from "../core/BaseModel";

export default class PolicyLangModel extends BaseModel {
  constructor() {
    super(
      {
        name: "policy_lang",
        tableName: "policy_lang",
        label: "Ngôn ngữ chính sách",
        display_key: "policy_id",
      },
      {
        policy_id: {
          name: "id",
          type: "bigint",
          label: "ID",
          primaryKey: true,
        },
        content: {
          name: "content",
          type: "Text",
          label: "Nội dung",
          display: { list: true },
          validations: { required: "Vui lòng nhập nội dung" },
          //filter: { type: "input", match: "contains" }
        },
      }
    );
  }
}
