import BaseModel from '../core/BaseModel';
import CompanyModel from './CompanyModel';
import ChannelModel from './ChannelModel';
import GroupChannelModel from './GroupChannelModel';
import RegionModel from './RegionModel';
import Common from "../core/Common";
export default class CustomerModel extends BaseModel {
    constructor() {
        super({
            tableName: 'customer', label: "Danh sách khách hàng", display_key: 'id'
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center txt-xs-col', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            compnay: { 
                refs: 'company {id code}',
                display: false
            },
            company_code: Common.makeFilterCompany('company_code', 'Mã công ty', new CompanyModel(), "customer", "small-col"),
            code: { 
                name: 'code', 
                type: "String", 
                label: 'Mã khách hàng',  
                sortable: false,
                class: "small-col",
                width : 15,
                filter: { type: 'input', match: 'contains'}
            },
            name: { 
                name: 'name', 
                type: "String", 
                label: 'Tên khách hàng',  
                class: "medium-col",
                sortable: false,
                width : 15,
                filter: { type: 'input', match: 'contains'}
            },
            region: { 
                refs: 'region {id name}',
                display: false
            },
            region_name: {
              name: "region_name",
              label: "Miền",
              type: "Selection",
              custom: 'SelectionText',
              models: new RegionModel(),
              class: "small-col",
              filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
              computed: (data) => {
                data.region_name = '';
              }
            },
            request_pay: {
                name: "request_pay",
                label: "Yêu cầu thanh toán",
                type: "Selection",
                custom: 'SelectionText',
                class: 'center txt-small-col',
                // width : 6,
                options: [
                    {value:1,label:"Có"},
                    {value:0,label:"Không"}
                ],
                filter: {
                  type: 'select',
                  optionLabel: 'label',
                  optionKey: 'value',
                  match: 'equals'
                },
            },
            
            group_channel: { 
                refs: 'group_channel {id name}',
                display: false
            },
            // group_channel_name: {
            //   name: "group_channel_name",
            //   label: "Nhóm kênh khách hàng",
            //   type: "Selection",
            //   custom: 'SelectionText',
            //   models: new GroupChannelModel(),
            //   filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //   computed: (data) => {
            //     data.group_channel_name = '';
            //   }
            // },
            group_channel_name: Common.makeFilterCompany('group_channel_name', 'Nhóm kênh khách hàng', new GroupChannelModel(), "customer", "medium-col"),
            channel: { 
                refs: 'channel {id name code}',
                display: false
            },
            // channel_name: {
            //   name: "channel_name",
            //   label: "Kênh phân phối",
            //   type: "Selection",
            //   custom: 'SelectionText',
            //   models: new ChannelModel('name'),
            //   filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //   computed: (data) => {
            //     data.channel_name = '';
            //   }
            // },
            channel_name: Common.makeFilterCompany('channel_name', 'Kênh phân phối', new ChannelModel('name'), "customer", "small-col"),
            active : {
                name: 'active',
                type: "Selection",
                label: 'Trạng thái',
                custom: 'SelectionText',
                class: "small-col",
                // width : 6,
                options: [
                    {value:1,label:"Đang hoạt động"},
                    {value:0,label:"Dừng hoạt động"}
                ],
                filter: {
                  type: 'select',
                  optionLabel: 'label',
                  optionKey: 'value',
                  match: 'equals'
                },
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime", 
                label: 'Ngày cập nhật', 
                class: "small-col",
                sortable: false,  
                filter: false,
                // display: false
            },
            updated_by : { 
                name: 'updated_by',
                type: "Text", 
                label: 'Người cập nhật', 
                class: "small-col",
                sortable: false,
                filter: false,
                // display: false
            },
            user_update: {
                refs: 'user_update {id fullname}',
                display: false
            },
            ordinal: {
              name: 'ordinal',
              display: false
            }
        })
    }

}