import BaseModel from "../core/BaseModel";

export default class ContactModel extends BaseModel {
  constructor() {
    super(
      {
        name: "contact",
        tableName: "contact",
        label: "Liên hệ",
        display_key: "id",
      },
      {
        stt: { 
          name: "stt", 
          label: "STT", 
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Int",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        phone: {
          name: "phone",
          type: "Text",
          label: "Số điện thoại",
          filter: false,
          // validations: { required: "Vui lòng nhập nội dung" },
          //filter: { type: "input", match: "contains" }
        }
      }
    );
  }
}
