import BaseModel from '../core/BaseModel'
// import CountryModel from "./CountryModel";
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
export default class OrderShippingDeliveryTripModel extends BaseModel {
    constructor() {
        super({
            tableName: 'order_shipping_item', label: "Danh sách kiện hàng", display_key: 'id',name: "order_shipping_delivery_trip",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center',
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            text_code: {
                name: "text_code",
                label: "Mã đơn hàng",
                width: 20,
                class: 'center',
                computed: (data) => {
                    data.text_code = 'Giao hàng';
                },
                //filter: {type: 'input', match: 'contains'},
            },
            text_doc_code: {
                name: "text_doc_code",
                label: "Số chứng từ",
                width: 20,
                class: 'center',
                computed: (data) => {
                    data.text_doc_code = '0';
                },
                //filter: {type: 'input', match: 'contains'},
            },
            text_address_delivery: {
                name: "text_address_delivery",
                label: "Địa chỉ",
                width: 30,
                class: 'center large-col',
                computed: (data) => {
                    data.text_address_delivery = "";
                },
                //filter: {type: 'input', match: 'contains'},
            },
        })
    }

}