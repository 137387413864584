import BaseModel from "../core/BaseModel";

export default class PolicyModel extends BaseModel {
  constructor() {
    super(
      {
        name: "policy",
        tableName: "policy",
        label: "Quản lý thông tin chính sách",
        display_key: "id",
      },
      {
        stt: { 
          name: "stt", 
          label: "STT", 
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Int",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        // type: {
        //   name: "type",
        //   type: "Selection",
        //   display: false,
        //   // validations: { required: "Vui lòng chọn loại" },
        //   label: "Loại",
        //   // options: [
        //   //   { value: "policy", label: "Chính sách" },
        //   // ],
        // },
        // title: {
        //   name: "title",
        //   type: "Text",
        //   label: "Tiêu đề",
        //   filter: false,
        //   // validations: { required: "Vui lòng nhập nội dung" },
        //   //filter: { type: "input", match: "contains" }
        // },
        content: {
          name: "content",
          type: "Text",
          label: "Nội dung",
          filter: false,
          // validations: { required: "Vui lòng nhập nội dung" },
          //filter: { type: "input", match: "contains" }
        },
        created_at : { 
            name: 'created_at', 
            type: "CustomDate", 
            label: 'Thời gian tạo', 
            sortable: false,  
            filter: false,
            display: false
        },
        updated_at : { 
            name: 'updated_at', 
            type: "Date", 
            label: 'Ngày cập nhật', 
            sortable: false,  
            filter: false,
            // display: false
        },
        // policy_lang: { 
        //   refs: 'policy_lang {lang content}',
        //   display: false
        // },
      }
    );
  }
}
