import BaseModel from '../core/BaseModel'
export default class CompanyModel extends BaseModel {
    constructor() {
        super({
            tableName: 'company', label: "Công ty", display_key: 'code'
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            code : {
              name: 'code', 
              type: "Text", 
              label: 'Mã công ty',
              filter: {type: 'input', match: 'contains'},
            },
            name : {
                name: 'name', 
                type: "Text", 
                label: 'Tên',
                filter: {type: 'input', match: 'contains'},
            },
            updated_at : { 
              name: 'updated_at', 
              type: "Datetime", 
              label: 'Updated Date',
              filter: { type: 'DateRange', match: 'contains'},
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },
            deleted: {
              name: "deleted",
              label: "Xóa",
              type: "Checkbox",
              display: false
            }
        })
    }

}