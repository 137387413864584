import BaseModel from '../core/BaseModel'
import CompanyModel from './CompanyModel'
import Common from '../core/Common'
export default class DocumentModel extends BaseModel {
    constructor() {
        super({
            tableName: 'document', label: "Danh Mục Hồ Sơ Chứng Từ", display_key: 'name'
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            company_id: Common.makeFilterCompany('company_id', 'Mã công ty', new CompanyModel(), "channel","width_160 common"),
            code: { 
                name: 'code', 
                type: "String", 
                label: 'Mã HSCT',
                width : 15,
                filter: { type: 'input', match: 'contains'}
            },
            name: { 
                name: 'name', 
                type: "String", 
                label: 'Tên hồ sơ chứng từ',
                filter: { type: 'input', match: 'contains'}
            },
            type: { 
                name: 'type', 
                type: "Selection",
                custom: "SelectionText",
                label: 'Loại hồ sơ',  
                options: [
                    { value: "DELIVERY", label: "Giao hàng" },
                    { value: "RETURN", label: "Trả hàng" }
                ],
                filter: {
                    type: "select",
                    optionLabel: "label",
                    optionKey: "value",
                    match: "equals",
                },
            },
            active: {
                name: "active",
                label: "Hiển thị",
                type: "Selection",
                custom: "SelectionText",
                options: [
                    { value: true, label: "Có" },
                    { value: false, label: "Không" }
                ],
                filter: {
                    type: "select",
                    optionLabel: "label",
                    optionKey: "value",
                    match: "equals",
                },
            },
            deleted: {
                name: "deleted",
                label: "Xóa",
                type: "Checkbox",
                display: false
            },
            document_company: {
                refs: "document_company {company {id name code}}",
                display: false
            },
            user: {
                refs: "user {id fullname}",
                display: false
            },
            updated_name : { 
                name: 'updated_name', 
                type: "String",
                label: 'Người cập nhật',
                computed: (data) => {
                    data.updated_name = "";
                }
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime",
                label: 'Ngày cập nhật',
                filter: { type: 'DateRange', match: 'contains'}
            }
        })
    }

}