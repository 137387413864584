import BaseModel from '../core/BaseModel';
export default class HolidayBranchModel extends BaseModel {
    constructor() {
        super({ 
                name: "holiday_branch",
                tableName: 'holiday_branch', 
                label: "Danh sách ngày nghỉ", 
                display_key: 'name'
            },  
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            branch_id : { 
                name: 'branch_id',
                type: "String",
                display: false
            },
            holiday_id : { 
                name: 'holiday_id', 
                type: "String",
                display: false
            }
        },)
    }

}