import BaseModel from '../core/BaseModel';

export default class OtherCostsServiceModel extends BaseModel {
    constructor() {
        super({ 
                name: "other_costs_service",
                tableName: 'other_costs_service', 
                label: "Khai Báo Chi Phí Khác dịch vụ", 
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            }
        },)
    }

}