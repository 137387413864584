import BaseModel from '../core/BaseModel'
import UserModel from "./UserModel";
import VehicleModel from "./VehicleModel";
// import OrderStatusModel from "@/models/OrderStatusModel";
import ReasonModel from "@/models/ReasonModel";
import ChannelModel from "@/models/ChannelModel";
import GroupChannelModel from "@/models/GroupChannelModel";
import StateModel from "@/models/StateModel";
import AreaTypeModel from './AreaTypeModel';
import StatusClassificationModel from './StatusClassificationModel';
//import gql from 'graphql-tag'

var Reason = new ReasonModel;
var Vehicle = new VehicleModel;
var User = new UserModel;
export default class OrderProgressModel extends BaseModel {

    constructor() {
        super({ 
                name: "order_progress",
                tableName: 'order',
                label: "Tiến trình xử lý Đơn hàng",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            check_box: {
                name: "check_box",
                label: " ",
                type: "Computed",
                custom: 'CheckBoxSelect',
                computed: (data) => {
                    data.stt = '';
                },
                filter: {},
                display: false
                // display: { list: true, add: false, edit: false }
            },
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                width: 60,
                class: "xs-col",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },/*
                fullname: {
                    name: 'fullname',
                    type: "String",
                    label: 'Tên',
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },*/
                company_code: {
                    name: 'company_code',
                    type: "String",
                    width: 150,
                    label: 'Mã công ty',
                    class: "medium-col",
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.company_code = "";
                    }
                },
                date_order: {
                    name: 'date_order',
                    type: "Date",
                    width: 150,
                    label: 'Ngày đơn hàng',
                    class: "medium-col style-text-date",
                    filter: { type: 'DateRange', match: 'contains'},
                },
                code: {
                    name: 'code',
                    type: "String",
                    label: 'Mã đơn hàng',
                    sortable: false,
                    width: 150,
                    class: "medium-col",
                    filter: {type: 'input', match: 'contains'},
                },
                doc_created_at: {
                    name: 'doc_created_at',
                    type: "Date",
                    width: 150,
                    label: 'Ngày chứng từ',
                    class: "medium-col style-text-date",
                    filter: { type: 'DateRange', match: 'contains'},
                },
                doc_code: {
                    name: 'doc_code',
                    type: "String",
                    width: 150,
                    label: 'Số chứng từ',
                    class: "medium-col",
                    sortable: false,
                    filter: {type: 'input', match: 'contains'},
                },
                description: {
                    name: 'description',
                    type: "String",
                    width: 150,
                    label: 'Diễn giải chung'
                },
                customer_code: {
                    name: 'customer_code',
                    type: "String",
                    width: 150,
                    label: 'Mã khách hàng',
                    filter: {type: 'input', match: 'contains'}
                },
                customer_name: {
                    name: 'customer_name',
                    type: "String",
                    label: 'Tên khách hàng',
                    class: "large-col",
                    sortable: false,
                    width: 200,
                    filter: {type: 'input', match: 'contains'},
                },
                request_payment: {
                    name: 'request_payment',
                    type: "Selection",
                    width: 150,
                    custom: "SelectionText",
                    label: 'Yêu cầu thanh toán',
                    options: [{value: true, label: "Có"}, {value: false
                    , label: "Không"}],
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    }
                },
                date_paid: {
                    width: 150,
                    name: 'date_paid',
                    type: "Datetime",
                    label: 'Ngày thanh toán',
                    filter: { type: 'DateRange', match: 'contains'},
                },
                date_print: {
                    name: 'date_print',
                    type: "Datetime",
                    width: 150,
                    label: 'Ngày in đơn',
                    filter: { type: 'DateRange', match: 'contains'},
                },
                date_preparation: {
                    name: 'date_preparation',
                    width: 150,
                    type: "Datetime",
                    label: 'Ngày soạn hàng',
                    filter: { type: 'DateRange', match: 'contains'},
                },
                date_transfer_wait_export: {
                    name: 'date_transfer_wait_export',
                    type: "Datetime",
                    width: 150,
                    label: 'Ngày chuyển khu chờ xuất',
                    filter: { type: 'DateRange', match: 'contains'},
                },
                actual_export_date: {
                    name: 'actual_export_date',
                    type: "Datetime",
                    width: 150,
                    label: 'Ngày xuất thực tế',
                    filter: { type: 'DateRange', match: 'contains'},
                },
                parcel_merge: {
                    name: 'parcel_merge',
                    type: "Selection",
                    width: 150,
                    label: 'Phân loại ghép kiện',
                    options: [
                        { value: true, label: "Ghép kiện"},
                        { value: false, label: "Không ghép kiện"},
                    ],
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    }
                },
                code_root: {
                    name: 'code_root',
                    type: "String",
                    width: 150,
                    label: 'Mã kiện',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.code_root = "";
                    }
                },
                total_package: {
                    name: 'total_package',
                    type: "CustomUrl",
                    width: 150,
                    class: 'center',
                    label: 'Tổng số kiện'
                },
                total_volume: {
                    name: 'total_volume',
                    type: "Number",
                    width: 150,
                    class: 'right',
                    label: 'Tổng số m³'
                },
                current_order_status_name: {
                    name:'current_order_status_name',
                    label:'Tình trạng',
                    width: 150,
                    type: "Selection",
                    custom: 'SelectionText',
                    // models: new OrderStatusModel(),
                    options: [
                        {label: "KH hủy đơn nhập lại kho",value: 1},
                        {label: "Hủy đơn", value: 2},
                        {label: "Chờ thanh toán", value: 3},
                        {value: 4, label: "Chưa soạn hàng"},
                        {value:5, label: "Đang soạn hàng"},
                        {value:6, label: "Chờ xuất"},
                        {value: 7, label:"Đã xuất"}
                    ],
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.current_order_status_name = "";
                    }
                },
                order_status: {
                    refs: 'order_status { id code name }',
                    display: false
                },
                once_method: {
                    name: 'once_method',
                    type: "String",
                    width: 150,
                    label: 'Lần 1 - hình thức giao hàng',
                    
                    computed: (data) => {
                        data.once_method = "";
                    }
                },
                once_date: {
                    name: 'once_date',
                    type: "Datetime",
                    width: 150,
                    label: 'Lần 1 - Ngày điều phối',
                    computed: (data) => {
                        data.once_date = "";
                    }
                },
                once_user: {
                    name: 'once_user',
                    width: 150,
                    label: 'Lần 1 - Nhân viên giao hàng',
                    type: "Selection",
                    custom: "SelectionText",
                    models: User,
                    filter: {
                        type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'
                    },
                    computed: (data) => {
                        data.once_user = "";
                    }
                },
                once_vehicle: {
                    name: 'once_vehicle',
                    width: 150,
                    label: 'Lần 1 - Phương tiện giao hàng',
                    type: "Selection",
                    custom: "SelectionText",
                    models: Vehicle,
                    filter: {
                        type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'
                    },
                    computed: (data) => {
                        data.once_vehicle = "";
                    }
                },
                once_vehicle_type: {
                    name: 'once_vehicle_type',
                    width: 150,
                    label: 'Lần 1 - Loại phương tiện',
                    type: "Selection",
                    custom: "SelectionText",
                    options: [
                        {value: 1, label: 'Xe tải'},
                        {value: 2, label: 'Xe máy'}
                    ],
                    filter: {
                        type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'
                    },
                    computed: (data) => {
                        data.once_vehicle_type = "";
                    }
                },
                once_shipping_code: {
                    name: 'once_shipping_code',
                    type: "String",
                    width: 150,
                    label: 'Lần 1 - Mã chuyến giao hàng',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.once_shipping_code = "";
                    }
                },
                once_combine: {
                    name: 'once_combine',
                    type: "String",
                    width: 150,
                    label: 'Lần 1 - Ghép chuyến',
                    
                    computed: (data) => {
                        data.once_combine = "";
                    }
                },
                once_trip: {
                    name: 'once_trip',
                    type: "String",
                    width: 150,
                    label: 'Lần 1 - Số chuyến giao hàng',
                    class: 'right',
                    computed: (data) => {
                        data.once_trip = "";
                    }
                },
                once_date_delivery: {
                    name: 'once_date_delivery',
                    type: "Datetime",
                    width: 150,
                    label: 'Lần 1 - Ngày giao hàng',
                    filter: {
                        type: "DateRange", match: "contrains",
                    },
                    computed: (data) => {
                        data.once_date_delivery = "";
                    }
                },
                once_note_delivery: {
                    name: 'once_note_delivery',
                    type: "String",
                    width: 150,
                    label: 'Lần 1 - Ghi chú giao hàng',
                    class: 'right',
                    computed: (data) => {
                        data.once_note_delivery = "";
                    }
                },
                once_reason: {
                    name: 'once_reason',
                    type: "Selection",
                    custom: "SelectionText",
                    width: 150,
                    label: 'Lần 1 - Lý do giao hàng không thành công',
                    models: Reason,
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
                    computed: (data) => {
                        data.once_reason = "";
                    }
                },
                once_company_paid: {
                    name: 'once_company_paid',
                    type: "String",
                    width: 150,
                    label: 'Lần 1 - Sự cố giao hàng Lê Mây chịu phí',
                    
                    computed: (data) => {
                        data.once_company_paid = "";
                    }
                },
                once_note: {
                    name: 'once_note',
                    type: "String",
                    width: 150,
                    label: 'Lần 1 - Ghi chú điều phối',
                    
                    computed: (data) => {
                        data.once_note = "";
                    }
                },
                
                second_method: {
                    width: 150,
                    name: 'second_method',
                    type: "String",
                    label: 'Lần 2 - hình thức giao hàng',
                    
                    computed: (data) => {
                        data.second_method = "";
                    }
                },
                second_date: {
                    name: 'second_date',
                    type: "Datetime",
                    width: 150,
                    label: 'Lần 2 - Ngày điều phối',
                    computed: (data) => {
                        data.second_date = "";
                    }
                },
                second_user: {
                    name: 'second_user',
                    label: 'Lần 2 - Nhân viên giao hàng',
                    type: "Selection",
                    width: 150,
                    custom: "SelectionText",
                    models: User,
                    filter: {
                        type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'
                    },
                    computed: (data) => {
                        data.second_user = "";
                    }
                },
                second_vehicle: {
                    name: 'second_vehicle',
                    label: 'Lần 2 - Phương tiện giao hàng',
                    type: "Selection",
                    width: 150,
                    custom: "SelectionText",
                    models: Vehicle,
                    filter: {
                        type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'
                    },
                    computed: (data) => {
                        data.second_vehicle = "";
                    }
                },
                second_vehicle_type: {
                    name: 'second_vehicle_type',
                    label: 'Lần 2 - Loại phương tiện',
                    type: "Selection",
                    width: 150,
                    custom: "SelectionText",
                    options: [
                        {value: 1, label: 'Xe tải'},
                        {value: 2, label: 'Xe máy'}
                    ],
                    computed: (data) => {
                        data.second_vehicle_type = "";
                    }
                },
                second_shipping_code: {
                    name: 'second_shipping_code',
                    type: "String",
                    width: 150,
                    label: 'Lần 2 - Mã chuyến giao hàng',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.second_shipping_code = "";
                    }
                },
                second_combine: {
                    name: 'second_combine',
                    type: "String",
                    width: 150,
                    label: 'Lần 2 - Ghép chuyến',
                    
                    computed: (data) => {
                        data.second_combine = "";
                    }
                },
                second_trip: {
                    name: 'second_trip',
                    type: "String",
                    width: 150,
                    label: 'Lần 2 - Số chuyến giao hàng',
                    class: 'right',
                    computed: (data) => {
                        data.second_trip = "";
                    }
                },
                second_date_delivery: {
                    name: 'second_date_delivery',
                    type: "Datetime",
                    width: 150,
                    label: 'Lần 2 - Ngày giao hàng',
                    filter: {
                        type: "DateRange", match: "contrains",
                    },
                    computed: (data) => {
                        data.second_date_delivery = "";
                    }
                },
                second_note_delivery: {
                    name: 'second_note_delivery',
                    type: "String",
                    width: 150,
                    label: 'Lần 2 - Ghi chú giao hàng',
                    class: 'right',
                    computed: (data) => {
                        data.second_note_delivery = "";
                    }
                },
                second_reason: {
                    name: 'second_reason',
                    type: "Selection",
                    custom: "SelectionText",
                    width: 150,
                    label: 'Lần 2 - Lý do giao hàng không thành công',
                    models: Reason,
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals"
                      },
                    computed: (data) => {
                        data.second_reason = "";
                    }
                },
                second_company_paid: {
                    name: 'second_company_paid',
                    type: "String",
                    width: 150,
                    label: 'Lần 2 - Sự cố giao hàng Lê Mây chịu phí',
                    
                    computed: (data) => {
                        data.second_company_paid = "";
                    }
                },
                second_note: {
                    name: 'second_note',
                    type: "String",
                    width: 150,
                    label: 'Lần 2 - Ghi chú điều phối',
                    
                    computed: (data) => {
                        data.second_note = "";
                    }
                },
                
                
                third_method: {
                    name: 'third_method',
                    type: "String",
                    width: 150,
                    label: 'Lần 3 - hình thức giao hàng',
                    
                    computed: (data) => {
                        data.third_method = "";
                    }
                },
                third_date: {
                    name: 'third_date',
                    type: "Datetime",
                    width: 150,
                    label: 'Lần 3 - Ngày điều phối',
                    computed: (data) => {
                        data.third_date = "";
                    }
                },
                third_user: {
                    name: 'third_user',
                    label: 'Lần 3 - Nhân viên giao hàng',
                    type: "Selection",
                    width: 150,
                    custom: "SelectionText",
                    models: User,
                    filter: {
                        type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'
                    },
                    computed: (data) => {
                        data.third_user = "";
                    }
                },
                third_vehicle: {
                    name: 'third_vehicle',
                    width: 150,
                    label: 'Lần 3 - Phương tiện giao hàng',
                    type: "Selection",
                    custom: "SelectionText",
                    models: Vehicle,
                    filter: {
                        type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'
                    },
                    computed: (data) => {
                        data.third_vehicle = "";
                    }
                },
                third_vehicle_type: {
                    width: 150,
                    name: 'third_vehicle_type',
                    label: 'Lần 3 - Loại phương tiện',
                    type: "Selection",
                    custom: "SelectionText",
                    options: [
                        {value: 1, label: 'Xe tải'},
                        {value: 2, label: 'Xe máy'}
                    ],
                    computed: (data) => {
                        data.third_vehicle_type = "";
                    }
                },
                third_shipping_code: {
                    name: 'third_shipping_code',
                    type: "String",
                    width: 150,
                    label: 'Lần 3 - Mã chuyến giao hàng',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.third_shipping_code = "";
                    }
                },
                third_combine: {
                    name: 'third_combine',
                    type: "String",
                    width: 150,
                    label: 'Lần 3 - Ghép chuyến',
                    
                    computed: (data) => {
                        data.third_combine = "";
                    }
                },
                third_trip: {
                    name: 'third_trip',
                    type: "String",
                    width: 150,
                    label: 'Lần 3 - Số chuyến giao hàng',
                    class: 'right',
                    computed: (data) => {
                        data.third_trip = "";
                    }
                },
                third_date_delivery: {
                    width: 150,
                    name: 'third_date_delivery',
                    type: "Datetime",
                    label: 'Lần 3 - Ngày giao hàng',
                    filter: {
                        type: "DateRange", match: "contrains",
                    },
                    computed: (data) => {
                        data.third_date_delivery = "";
                    }
                },
                third_note_delivery: {
                    name: 'third_note_delivery',
                    type: "String",
                    width: 150,
                    label: 'Lần 3 - Ghi chú giao hàng',
                    class: 'right',
                    computed: (data) => {
                        data.third_note_delivery = "";
                    }
                },
                third_reason: {
                    width: 150,
                    name: 'third_reason',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'Lần 3 - Lý do giao hàng không thành công',
                    models: Reason,
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals"
                      },
                    computed: (data) => {
                        data.third_reason = "";
                    }
                },
                third_company_paid: {
                    width: 150,
                    name: 'third_company_paid',
                    type: "String",
                    label: 'Lần 3 - Sự cố giao hàng Lê Mây chịu phí',
                    
                    computed: (data) => {
                        data.third_company_paid = "";
                    }
                },
                third_note: {
                    width: 150,
                    name: 'third_note',
                    type: "String",
                    label: 'Lần 3 - Ghi chú điều phối',
                    
                    computed: (data) => {
                        data.third_note = "";
                    }
                },
                
                count_delivery: {
                    width: 150,
                    name: 'count_delivery',
                    type: "String",
                    label: 'Số lần đã giao hàng',
                    class: 'right',
                    computed: (data) => {
                        data.count_delivery = "";
                    }  
                },
                reason_cancel: {
                    width: 150,
                    name: 'reason_cancel',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'Lý do hủy đơn',
                    models: Reason,
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                      },
                    computed: (data) => {
                        data.reason_cancel = "";
                    }  
                },
                
                reason_cancel_warehouse: {
                    width: 150,
                    name: 'reason_cancel_warehouse',
                    type: "String",
                    label: 'Lý do hủy đơn nhập lại kho',
                    
                    computed: (data) => {
                        data.reason_cancel_warehouse = "";
                    }  
                },
                
                date_done: {
                    width: 150,
                    name: 'date_done',
                    type: "Datetime",
                    label: 'Ngày giao hàng thành công',
                    filter: {
                        type: "DateRange", match: "contrains",
                    },
                },

                note_delivery: {
                    width: 150,
                    name: 'note_delivery',
                    type: "String",
                    label: 'Ghi chú giao hàng',
                    computed: (data) => {
                        data.note_delivery = "";
                    } 
                },
                
                date_expected: {
                    width: 150,
                    name: 'date_expected',
                    type: "Date",
                    label: 'Ngày giao hàng dự kiến (khách hàng yêu cầu)',
                    filter: {
                        type: "DateRange", match: "contrains",
                    },
                },
                delivery_status: {
                    width: 150,
                    name: 'delivery_status',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'Tình trạng giao hàng',
                    // models: new OrderStatusModel(),
                    options: [
                        {label: "KH hủy đơn nhập lại kho",value: 1},
                        {label: "Hủy đơn", value: 2},
                        {value:3, label: "Chưa giao hàng"},
                        {value: 4, label:"Giao hàng thành công"},
                        {value: 5, label:"Đang giao hàng lần 1"},
                        {value: 6, label: "Giao không thành công lần 1"},
                        {value: 7, label: "Đang giao hàng lần 2"},
                        {value: 8, label: "Giao không thành công lần 2"},
                        {value: 9, label: "Đang giao hàng lần 3"},
                        {value: 10, label: "Giao không thành công lần 3"}
                    ],
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.delivery_status = "";
                    }
                },
                updated_at: {
                    width: 150,
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Thời gian chỉnh sửa',
                    filter: {
                        type: "DateRange", match: "contrains",
                    },
                },
                
                updated_by: {
                    width: 150,
                    name: 'updated_by',
                    type: "String",
                    label: 'Người chỉnh sửa',
                    
                    computed: (data) => {
                        data.updated_by = "";
                    }  
                },
                
                return_date: {
                    width: 150,
                    name: 'return_date',
                    type: "Datetime",
                    label: 'Trả hàng - Thời gian trả hàng',
                    filter: {
                        type: "DateRange", match: "contrains",
                    },
                    computed: (data) => {
                        data.return_date = "";
                    }  
                },
                
                return_reason: {
                    width: 150,
                    name: 'return_reason',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'Trả hàng - Lý do trả hàng',
                    models: Reason,
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                      },
                    computed: (data) => {
                        data.return_reason = "";
                    }  
                },
                
                return_combine: {
                    width: 150,
                    name: 'return_combine',
                    type: "String",
                    label: 'Trả hàng - Ghép chuyến',
                    
                    computed: (data) => {
                        data.return_combine = "";
                    }  
                },
                
                return_fee: {
                    width: 150,
                    name: 'return_fee',
                    type: "String",
                    label: 'Gửi/Trả hàng - Chi phí bưu điện',
                    class: "right",
                    computed: (data) => {
                        data.return_fee = "";
                    }  
                },
                
                hsct_date: {
                    width: 150,
                    name: 'hsct_date',
                    type: "Datetime",
                    label: 'Ngày chuyển bộ HSCT',
                    computed: (data) => {
                        data.hsct_date = "";
                    }  
                },
                
                hsct_date_confirm: {
                    width: 150,
                    name: 'hsct_date_confirm',
                    type: "Datetime",
                    label: 'Ngày xác nhận đủ HSCT',
                    computed: (data) => {
                        data.hsct_date_confirm = "";
                    }  
                },
                
                hsct_confirm: {
                    width: 150,
                    name: 'hsct_confirm',
                    type: "String",
                    label: 'KT xác nhận bộ HSCT',
                    
                    computed: (data) => {
                        data.hsct_confirm = "";
                    }  
                },
                
                gave_hsct: {
                    width: 150,
                    name: 'gave_hsct',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'Đã nhận chứng từ',
                    options: [
                        { value: 1, label: "Đủ", style: "color:  #000000;" },
                        { value: 0, label: "Không đủ", style: "color:  #000000;" },
                    ],
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    },
                    computed: (data) => {
                        data.gave_hsct = "";
                    }  
                },
                
                hsct_checklist_confirm: {
                    width: 150,
                    name: 'hsct_checklist_confirm',
                    type: "String",
                    label: 'Bộ HSCT, check list xác nhận',
                    
                    computed: (data) => {
                        data.hsct_checklist_confirm = "";
                    }  
                },
                
                staff_code: {
                    width: 150,
                    name: 'staff_code',
                    type: "String",
                    label: 'Mã nhân viên phụ trách đơn hàng',
                },
                
                staff_name: {
                    width: 150,
                    name: 'staff_name',
                    type: "String",
                    label: 'Tên nhân viên phụ trách đơn hàng',
                    filter: {
                        type: "input", match: "contrains",
                    }
                },
                
                total_price: {
                    width: 150,
                    name: 'total_price',
                    type: "String",
                    label: 'Tổng giá trị đơn hàng',
                    class: 'right',
                    computed: (data) => {
                        data.total_price = "";
                    }  
                },
                
                channel: {
                    width: 150,
                    name: 'channel',
                    type: "Selection",
                    label: 'Kênh',
                    custom: "SelectionText",
                    models: new ChannelModel('name'),
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    },
                    computed: (data) => {
                        data.channel = "";
                    }  
                },
                
                channel_group: {
                    width: 150,
                    name: 'channel_group',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'Nhóm khách hàng',
                    models: new GroupChannelModel(),
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    },
                    computed: (data) => {
                        data.channel_group = "";
                    }  
                },
                
                delivery_eligibility_date: {
                    width: 150,
                    name: 'delivery_eligibility_date',
                    type: "Date",
                    label: 'Ngày đủ điều kiện giao hàng'
                },
                
                sal_type_order: {
                    width: 150,
                    name: 'sal_type_order',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'SAL - Phân loại đơn hàng',
                    models: new StatusClassificationModel,
                    computed: (data) => {
                        data.sal_type_order = "";
                    }  
                },
                
                sal_status: {
                    width: 150,
                    name: 'sal_status',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'SAL - Tình trạng',
                    models: new StatusClassificationModel,
                    computed: (data) => {
                        data.sal_status = "";
                    }  
                },
                
                count_of_day_waiting_export: {
                    width: 150,
                    name: 'count_of_day_waiting_export',
                    type: "String",
                    label: 'Số ngày ở khu chờ xuất',
                    class: 'right',
                    computed: (data) => {
                        data.count_of_day_waiting_export = "";
                    }  
                },
                
                date_wating_export_rate: {
                    width: 150,
                    name: 'date_wating_export_rate',
                    type: "String",
                    label: 'Thời gian chờ xuất định mức (ngày)',
                    class: 'right',
                    computed: (data) => {
                        data.date_wating_export_rate = "";
                    }  
                },
                
                count_of_day_handle_order: {
                    width: 150,
                    name: 'count_of_day_handle_order',
                    type: "String",
                    label: 'Số ngày xử lý đơn hàng',
                    class: 'right',
                    computed: (data) => {
                        data.count_of_day_handle_order = "";
                    }  
                },
                
                committed_delivery_time: {
                    width: 150,
                    name: 'committed_delivery_time',
                    type: "String",
                    label: 'Thời gian giao hàng cam kết (ngày)',
                    class: 'right',
                    computed: (data) => {
                        data.committed_delivery_time = "";
                    }  
                },
                
                date_committed_delivery_time: {
                    width: 150,
                    name: 'date_committed_delivery_time',
                    type: "String",
                    label: 'Số ngày giao hàng so với cam kết',
                    class: 'right',
                    computed: (data) => {
                        data.date_committed_delivery_time = "";
                    }  
                },
                
                key_classify: {
                    width: 150,
                    name: 'key_classify',
                    type: "Selection",
                    label: 'Phân loại theo thời gian giao hàng',
                    options: [
                        { value: 'NOW', label: "Giao ngay", style: "color:  #000000;" },
                        { value: '1_3_DAYS', label: "1-3 ngày", style: "color:  #000000;" },
                        { value: '4_6_DAYS', label: "4-6 ngày", style: "color:  #000000;" },
                        { value: '7_9_DAYS', label: "7-9 ngày", style: "color:  #000000;" },
                        { value: '10_15_DAYS', label: "10-15 ngày", style: "color:  #000000;" },
                        { value: '16_20_DAYS', label: "16-20 ngày", style: "color:  #000000;" },
                        { value: 'MORE_20_DAYS', label: "> 20 ngày", style: "color:  #000000;" },
                        { value: 'ONLINE', label: "Online lấy tại kho", style: "color:  #000000;" },
                        { value: 'GET_SELF', label: "Tự lấy hàng", style: "color:  #000000;" },
                        { value: 'EXCHANGE', label: "Chuyển kho", style: "color:  #000000;" },
                        { value: 'NOT_DATE_PAID', label: "Chưa có ngày thanh toán", style: "color:  #000000;" },
                        { value: 'NOT_DELIVERY', label: "Chưa giao hàng", style: "color:  #000000;" },
                        { value: 'CANCEL', label: "Biên bản hủy", style: "color:  #000000;" },
                    ],
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    }
                },
                
                warehouse_export_code: {
                    width: 150,
                    name: 'warehouse_export_code',
                    type: "String",
                    label: 'Mã kho xuất',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.warehouse_export_code = "";
                    }
                },
                
                warehouse_export_name: {
                    width: 150,
                    name: 'warehouse_export_name',
                    type: "String",
                    label: 'Tên kho xuất',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.warehouse_export_name = "";
                    }
                },
                
                warehouse_export_address: {
                    width: 150,
                    name: 'warehouse_export_address',
                    type: "String",
                    label: 'Địa chỉ kho xuất',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.warehouse_export_address = "";
                    }
                },
                
                warehouse_export_address_code: {
                    width: 150,
                    name: 'warehouse_export_address_code',
                    type: "String",
                    label: 'Mã địa chỉ giao hàng',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.warehouse_export_address_code = "";
                    }
                },
                
                customer_address: {
                    width: 150,
                    name: 'customer_address',
                    type: "String",
                    label: 'Địa chỉ khách hàng',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.customer_address = "";
                    }
                },
                
                freight_address: {
                    width: 150,
                    name: 'freight_address',
                    type: "String",
                    label: 'Địa chỉ chành xe',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.freight_address = "";
                    }
                },
                
                district: {
                    width: 150,
                    name: 'district',
                    type: "String",
                    label: 'Địa chỉ giao hàng - Quận',
                    filter: {
                        type: "input", match: "contrains",
                    },
                    computed: (data) => {
                        data.district = "";
                    }
                },
                
                state: {
                    width: 150,
                    name: 'state',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'Địa chỉ giao hàng - Tỉnh',
                    models: new StateModel(),
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    },
                    computed: (data) => {
                        data.state = "";
                    }
                },
                
                area: {
                    width: 150,
                    name: 'area',
                    type: "Selection",
                    custom: "SelectionText",
                    label: 'Khu vực giao hàng',
                    models: new AreaTypeModel,
                    filter: {
                        type: "select",
                        optionLabel: "label",
                        optionKey: "value",
                        match: "equals",
                    },
                    computed: (data) => {
                        data.area = "";
                    }
                },


        },)
    }

}