import BaseModel from '../core/BaseModel';
export default class QuestionServiceModel extends BaseModel {
    constructor() {
        super({ 
                name: "question_service",
                tableName: 'question_service', 
                label: "Danh sách dịch vụ", 
                display_key: 'name'
            },  
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            type_service_id : { 
                name: 'type_service_id',
                type: "String",
                display: false
            },
            question_id : { 
                name: 'question_id', 
                type: "String",
                display: false
            },
            question: { 
                refs: 'question {id name}',
                display: false
            },
        },)
    }

}