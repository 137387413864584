import BaseModel from '../core/BaseModel'
import moment from "moment-timezone";
// import CountryModel from "./CountryModel";
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
import OrderStatusModel from "@/models/OrderStatusModel";
export default class OrdershippingItemModel extends BaseModel {
    constructor() {
        super({
            tableName: 'order_shipping_item', label: "Quản lý điều phối", display_key: 'id',name: "order_shipping_item",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            date_order: {
                name: "date_order",
                label: "Ngày đơn hàng",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                      //  text = data.order.date_order;
                        text = moment.tz(data.order.date_order, "Asia/Ho_Chi_Minh").format("DD/MM/yyyy")
                    }
                    data.date_order = text
                }
            },
            code: {
                name: "code",
                label: "Mã đơn hàng",
                class: 'center',
                custom: "CutomCLickA",
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.code;
                    }
                    data.code = text
                }
            },
            doc_created_at: {
                name: "doc_created_at",
                label: "Ngày chứng từ",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        //text = data.order.doc_created_at;
                        if (data.order.doc_created_at){
                            text = moment.tz(data.order.doc_created_at, "Asia/Ho_Chi_Minh").format("DD/MM/yyyy")
                        }
                    }
                    data.doc_created_at = text
                }
            },
            doc_code: {
                name: "doc_code",
                label: "Số chứng từ",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.doc_code;
                    }
                    data.doc_code = text
                }
            },
            explain: {
                name: "explain",
                label: "Diễn giải chung",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.description;
                    }
                    data.explain = text
                }
            },
            total_deliveries: {
                name: "total_deliveries",
                label: "Lần giao thứ",
                class: 'center',
                computed: (data) => {
                    var history_status_orders = data?.order?.history_status_orders ?? [];
                    /*var text = ''
                    var status_code = data?.order?.order_status?.code ?? null;
                    var total_deliveries = data?.order?.total_deliveries ?? null;
                    var history_status_orders = data?.order?.history_status_orders ?? [];*/
                    //console.log("data.created_at:",data.created_at)
                    //var created_at_check = moment.tz(data.created_at).format("YYYYMMDDHHmmss");
                    var created_at_check = moment.tz(data.created_at, "Asia/Ho_Chi_Minh").format("YYYYMMDDHHmmss")
                    console.log("created_at_checkcreated_at_check:",created_at_check)
                    console.log("history_status_orders:",history_status_orders)
                    var count_number = 0;
                    for (let i = 0; i < history_status_orders.length; i++) {
                        var item_data = history_status_orders[i]
                        //var created_at_sta = item_data?.created_at
                        var created_sta_check = moment.tz(item_data.created_at, "Asia/Ho_Chi_Minh").format("YYYYMMDDHHmmss")
                        //var created_sta_check = moment.tz(created_at_sta).format("YYYYMMDDHHmmss");
                        console.log("created_sta_check:"+created_sta_check,item_data)
                        if (parseInt(created_at_check) > parseInt(created_sta_check) ){
                            if (item_data.code_status == 'ST008'){
                                count_number++;
                            }
                        }
                    }
                    count_number = count_number+1;
                    if (count_number>3) {
                        count_number = 3;
                    }
                    data.total_deliveries = count_number
                }
            },
            current_order_status_id: {
                name:'current_order_status_id',
                label:'Trạng thái',
                type: "Selection",
                custom: 'SelectionText',
                class: "center",
                width: 20,
                models: new OrderStatusModel(),
                // filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.current_order_status_id = '';
                },
            },
            total_package: {
                name: "total_package",
                label: "SL kiện",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    var code_root = data.code_root
                    if (code_root == data.order.code){
                        text = data.total_qty
                    }else {
                        text = 0
                    }
                    data.total_package = text
                }
            },

            total_m3_key: {
                name: "total_m3_key",
                label: "Tổng số m3",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    var code_root = data.code_root
                    if (code_root == data.order.code){
                        text = data?.total_m3
                    }else {
                        text = 0
                    }
                    data.total_m3_key = text
                }
            },
            detail_package_key: {
                name: "detail_package_key",
                label: "Chi tiết",
                class: 'center',
                width: 20,
                computed: (data) => {
                    var text = ''
                    var code_root = data.code_root
                    if (code_root == data.order.code){
                        text =  data?.detail_package
                    }else {
                        if (code_root){
                            text =  `Ghép kiện đơn hàng ${code_root}`
                        }
                    }
                    data.detail_package_key = text
                }
            },
            customer_name: {
                name: "customer_name",
                label: "Tên khách hàng",
                width: 15,
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.customer_name;
                    }
                    data.customer_name = text
                }
            },
            delivery_type_key: {
                name: "delivery_type_key",
                label: "Loại giao",
                width: 20,
                class: 'center',
                computed: (data) => {
                    var text = ''
                        console.log('data.order.order_shipping_item',data.order.order_shipping_item)
                    if (data.id){
                        //đơn đã cập nhật địa chỉ điều phối
                        if (data.delivery_type) {
                            if (data.delivery_type == 1) {
                                text = 'Giao hàng đến địa chỉ khách'
                            }else if (data.delivery_type == 2) {
                                text = 'Giao hàng đến chành'
                            }
                            else if (data.delivery_type == 3) {
                                text = 'Gửi bưu điện'
                            }
                            else if (data.delivery_type == 4) {
                                text = 'Tự lấy hàng'
                            }
                            // text = data.order.customer_name;
                        }
                    }else {
                        //đơn mới
                        //suggest_delivery_type
                        if (data.order) {
                            if (data.order.suggest_delivery_type == 1) {
                                text = 'Giao hàng đến địa chỉ khách'
                            }else if (data.order.suggest_delivery_type == 2) {
                                text = 'Giao hàng đến chành'
                            }
                            else if (data.order.suggest_delivery_type == 3) {
                                text = 'Gửi bưu điện'
                            }
                            else if (data.order.suggest_delivery_type == 4) {
                                text = 'Tự lấy hàng'
                            }
                            // text = data.order.customer_name;
                        }
                    }

                    data.delivery_type_key = text
                }
            },
            freight_id_key: {
                name: "freight_id_key",
                label: "Chành",
                class: 'center',
                computed: (data) => {
                    console.log("datadata:",data)
                    var text = ''
                    var code = ''
                    var name = ''
                    if (data.id) {
                        if (data.freight) {
                             code = data.freight.code
                             name = data.freight.name
                            text = code + " - " + name
                        }
                    }else {
                        if (data.order.obj_suggest_freight) {
                             code = data.order.obj_suggest_freight.code
                             name = data.order.obj_suggest_freight.name
                            text = code + " - " + name
                        }
                    }
                    data.freight_id_key = text
                }
            },
            key_get: {
                refs: 'order_group_parcel_id created_at address_delivery_root address_delivery total_package delivery_type freight{ code name } order{history_status_orders { id code_status created_at } order_status { id code name } description id date_order code doc_created_at doc_code explain  total_deliveries total_package customer_name  order_shipping_item{ delivery_type freight{ code name }} } ',
                display: false
            },



        })
    }

}