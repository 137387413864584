import BaseModel from '../core/BaseModel'
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class OrderCustomTransferHsctAccountingFaildModel extends BaseModel {

    constructor() {
        super({
                name: "order_custom_transfer_hsct_accounting_faild",
                tableName: 'order',
                label: "Danh Sách Đơn hàng",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } },
            {

                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                obj_order_shipping_items: {
                    refs: 'obj_order_shipping_items { order_shipping { code id } }',
                    display: false
                },
                obj_order_item: {
                    refs: 'obj_order_item { warehouseByWarehouseId { name id } }',
                    display: false
                },
                code_custom: {
                    name: "code_custom",
                    label: 'Mã đơn hàng',
                    class: 'left txt-width_common-comlumn',
                    custom: "CutomCLickA",
                    width: 10,
                    computed: (data) => {
                        /*var total = 0;
                        if (data.order_shipping_items_aggregate ){
                            if (data.order_shipping_items_aggregate.aggregate ){
                                if (data.order_shipping_items_aggregate.aggregate.count ){
                                    total = data.order_shipping_items_aggregate.aggregate.count
                                }
                            }

                        }*/
                        data.code_custom = '';
                        // console.log("wqwqwqwqwqwqw",data.delivery_trip_number_key)
                    },
                    // filter: {type: 'input', match: 'contains'},
                },
                doc_code: {
                    name: 'doc_code',
                    type: "String",
                    label: 'Số chứng từ',
                    sortable: false,
                    width: 10,
                    /* filter: {type: 'input', match: 'contains'},*/
                },
                customer_code: {
                    name: 'customer_code',
                    type: "String",
                    label: 'Mã khách hàng',
                    class: "large-col",
                    sortable: false,
                    width: 10,
                },
                customer_name: {
                    name: 'customer_name',
                    type: "String",
                    label: 'Tên khách hàng',
                    class: "large-col",
                    sortable: false,
                    width: 10,
                },
                text_docs_key: {
                    name: "text_docs_key",
                    label: 'Bộ hồ sơ chứng từ, check list xác nhận',
                    type: "Computed",
                    class: "large-col",
                    sortable: false,
                    width: 10,
                    computed: (data) => {
                        data.text_docs_key = (data.text_docs) ? data.text_docs:'';
                    },
                    // display: { list: true, add: false, edit: false }
                },
                note_profile: {
                    name: 'note_profile',
                    type: "String",
                    label: 'Ghi chú HSCT',
                    class: "large-col",
                    sortable: false,
                    width: 10,
                  //  filter: {type: 'input', match: 'contains'},
                },
                date_done: {
                    name: 'date_done',
                    type: "Datetime",
                    label: 'Ngày giao hàng thành công',
                    // filter: { type: 'DateRange', match: 'contains'},
                    class:'medium-col style-text-date'
                },
                key_get1: {
                    refs:  'code ',
                    display: false
                },
            },)
    }

}