import BaseModel from "../core/BaseModel";
// import TypeReasonModel from "@/models/TypeReasonModel";
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
// import StateModel from "@/models/StateModel";
// import DistrictModel from "@/models/DistrictModel";
// import WardModel from "@/models/WardModel";
// import CountryModel from "@/models/CountryModel";
export default class ContentExportCompanyModel extends BaseModel {
  constructor() {
    super(
      {
        name: "content_export_company",
        tableName: "content_export_company",
        label: "content_export_company",
        //display_key: "name",
        default_order: { key: "updated_at", direction: "desc" },
      },
      {
        stt: {
          name: "stt",
          label: "STT",
          type: "Computed",
          class: "xs-col",
          computed: (data, index) => {
            data.stt = index + 1;
          },
          // display: { list: true, add: false, edit: false }
        },
        key_get: {
          refs: 'date_settlement content cost_difference',
          display: false
        },

        /* rel_object_group_uses: {
                    refs: `rel_object_group_uses {
                            group_id
                        }`,
                    display: false
                },
                name_group: {
                    name: 'name_group',
                    type: "String",
                    label: 'Nhóm quyền',
                    sortable: false,
                    display: false
                },
                name_group_filter: {
                    name: 'name_group_filter',
                    type: "Selection",
                    label: 'Nhóm quyền',
                    sortable: false,
                    width: 10,
                    models: new GroupModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.name_group_filter = '';
                    }
                },*/
      }
    );
  }
}
