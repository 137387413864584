import BaseModel from "../core/BaseModel";
// import TypeReasonModel from "@/models/TypeReasonModel";
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class FreightCustomerModel extends BaseModel {
  constructor() {
    super(
      {
        name: "freightcustomer_",
        tableName: "freight_customer",
        label: "Số lượng khách hàng",
        display_key: "name",
        // default_order: { key: "updated_at", direction: "desc" },
      },
      {
        // stt: {
        //   name: "stt",
        //   label: "STT",
        //   type: "Computed",
        //   computed: (data, index) => {
        //     data.stt = index + 1;
        //   },
        //   // display: { list: true, add: false, edit: false }
        // },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          width: 1,
          primaryKey: true,
          display: false,
        },
        freight_id: {
          name: "freight_id",
          type: "String",
          label: "Mã chành",
          sortable: false,
          display: false,
          //   filter: { type: "input", match: "contains" },
        },
        customer_id: {
          name: "customer_id",
          type: "String",
          label: "Mã khách hàng",
          sortable: false,
          display: false,
          //   filter: { type: "input", match: "contains" },
        },
        customer: {
          refs: "customer { code company { name code } name }",
          display: false,
        },
        freight: {
          refs: "freight { id code name }",
          display: false,
        },
        code: {
          name: "code",
          type: "Computed",
          custom: "SelectionText",
          label: "Mã khách hàng",
          class: "medium-col",
          sortable: false,
          computed: (data) => {
            data.code = "";
          },
        },
        name: {
          name: "name",
          type: "Computed",
          custom: "SelectionText",
          label: "Tên khách hàng",
          sortable: false,
          class: "large-col",
          computed: (data) => {
            data.name = "";
          },
          //   filter: { type: "input", match: "contains" },
        },
        company: {
          name: "company",
          type: "Computed",
          custom: "SelectionText",
          label: "Công ty",
          sortable: false,
          class: "medium-col",
          computed: (data) => {
            data.company = "";
          },
        },
        /* rel_object_group_uses: {
                    refs: `rel_object_group_uses {
                            group_id
                        }`,
                    display: false
                },
                name_group: {
                    name: 'name_group',
                    type: "String",
                    label: 'Nhóm quyền',
                    sortable: false,
                    display: false
                },
                name_group_filter: {
                    name: 'name_group_filter',
                    type: "Selection",
                    label: 'Nhóm quyền',
                    sortable: false,
                    width: 10,
                    models: new GroupModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.name_group_filter = '';
                    }
                },*/
      }
    );
  }
}
