import BaseModel from '../core/BaseModel'

export default class StatusClassificationModel extends BaseModel {
    constructor() {
        super({
            tableName: 'status_classification', label: "Quản lý Banner", display_key: 'name'
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            name: { 
                name: 'name', 
                type: "String", 
                label: 'Tiêu đề',  
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            
        })
    }

}