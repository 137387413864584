import BaseModel from '../core/BaseModel'

export default class DeliveryCostsDetailModel extends BaseModel {
    constructor() {
        super({
            tableName: 'delivery_costs_detail', label: "địa chỉ giao hàng", display_key: 'code'
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            /*code: {
                name: 'code', 
                type: "String", 
                label: 'Mã chuyến',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },*/
            text_code_key: {
                name: "text_code",
                label: "Mã chuyến",
                class: 'center',
                computed: (data) => {
                    data.text_code_key = data.text_code;
                }
            },
            vehicle_name_key: {
                name: "vehicle_name_key",
                label: "Phương tiện",
                class: 'center',
                computed: (data) => {
                    data.vehicle_name_key = data.vehicle_name;
                    //console.log('data.vehicledata.vehicle:',data.vehicle.name)
                    /*if (data.vehicle){
                        if (data.vehicle.name) {
                            data.vehicle_name = data.vehicle.name;
                        }
                    }*/
                }
            },
            /*vehicle_name: {
                name: 'vehicle_name',
                type: "String",
                label: 'Phương tiện',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },*/
            total_package: {
                name: 'total_package',
                type: "String",
                label: 'Tổng số kiện',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            shipping_service_price: {
                name: 'shipping_service_price',
                type: "String",
                label: 'Dịch vụ vận chuyển',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            shipping_truck_price: {
                name: 'shipping_truck_price',
                type: "String",
                label: 'Giao từ xe tải từ điểm giao thứ 2',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            // goods_returned_price: {
            //     name: 'goods_returned_price',
            //     type: "String",
            //     label: 'Hàng trả về',
            //     sortable: false,
            //     width : 15,
            //     // filter: { type: 'input', match: 'contains'}
            // },
            return_not_delivery: {
                name: 'return_not_delivery',
                type: "String",
                label: 'Hàng trả về không kết hợp với chuyến đi giao cùng địa điểm',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            return_delivery: {
                name: 'return_delivery',
                type: "String",
                label: 'Hàng trả về có kết hợp với chuyến đi giao cùng địa điểm',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            combine_flights_company: {
                name: 'combine_flights_company',
                type: "String",
                label: 'Ghép chuyến với hàng của Lê Mây',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            transportation_service_npp_price: {
                name: 'transportation_service_npp_price',
                type: "String",
                label: 'DVVC từ chành đến NPP',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            handling_fee_price: {
                name: 'handling_fee_price',
                type: "String",
                label: 'Dịch vụ bốc xếp',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            send_return_goods_post: {
                name: 'send_return_goods_post',
                type: "String",
                label: 'Gửi/ trả hàng bằng bưu điện',
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            key_get: {
                refs: 'vehicle_id ordinal code vehicle { id name } ',//
                display: false
            },
            
        })
    }

}