import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class AccountsModel extends BaseModel {

    constructor() {
        super({
                name: "accounts",
                tableName: 'accounts',
                label: "Quản lý người dùng",
                // display_key: 'fullname', 
                default_order: {key: 'updated_at', direction: 'asc'}
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
                id: {
                    name: 'id',
                    type: "String",
                    label: 'ID',
                    primaryKey: true,
                    // edit: {
                    //     add: false,
                    //     edit: false
                    // },
                    display: false
                },
                uid: {
                    name: 'uid',
                    type: "String",
                    label: 'ID',
                    display: false
                },
                account: {
                    name: 'account',
                    type: "String",
                    label: 'Account',
                    sortable: false,
                    // filter: { type: 'input', match: 'contains'},
                },
                type: {
                    name: 'type',
                    type: "String",
                    label: 'Type',
                    // filter: { type: 'input', match: 'contains'},
                    // display: false
                },
                role: {
                    name: 'role',
                    type: "Text",
                    label: 'Type',
                    display: false
                },
                /*password: {
                    name: 'password',
                    type: "String",
                    label: 'Type',
                    display: false
                }*/

            },)
    }

}