import BaseModel from '../core/BaseModel'
import moment from "moment-timezone";
// import CountryModel from "./CountryModel";
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
export default class OrdershippingItemCustomModel extends BaseModel {
    constructor() {
        super({
            tableName: 'order_shipping_item', label: "Quản lý điều phối", display_key: 'id',name: "order_shipping_item_custom",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            date_order: {
                name: "date_order",
                label: "Ngày đơn hàng",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                      //  text = data.order.date_order;
                        text = moment.tz(data.order.date_order, "Asia/Ho_Chi_Minh").format("DD/MM/yyyy")
                    }
                    data.date_order = text
                }
            },
            code: {
                name: "code",
                label: "Mã đơn hàng",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.code;
                    }
                    data.code = text
                }
            },
            doc_created_at: {
                name: "doc_created_at",
                label: "Ngày chứng từ",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        //text = data.order.doc_created_at;
                        text = moment.tz(data.order.doc_created_at, "Asia/Ho_Chi_Minh").format("DD/MM/yyyy")
                    }
                    data.doc_created_at = text
                }
            },
            doc_code: {
                name: "doc_code",
                label: "Số chứng từ",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.doc_code;
                    }
                    data.doc_code = text
                }
            },
            explain: {
                name: "explain",
                label: "Diễn giải chung",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.description;
                    }
                    data.explain = text
                }
            },
            total_deliveries: {
                name: "total_deliveries",
                label: "Lần giao thứ",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.total_deliveries;
                    }
                    data.total_deliveries = text
                }
            },
            total_package: {
                name: "total_package",
                label: "SL kiện",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.total_package;
                    }
                    data.total_package = text
                }
            },
            customer_name: {
                name: "customer_name",
                label: "Tên khách hàng",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        text = data.order.customer_name;
                    }
                    data.customer_name = text
                }
            },
            /*delivery_type_key: {
                name: "delivery_type_key",
                label: "Loại giao",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        console.log('data.order.order_shipping_item',data.order.order_shipping_item)
                        if (data.order.order_shipping_item) {
                            if (data.order.order_shipping_item.delivery_type == 1) {
                                text = 'Giao hàng đến địa chỉ khách'
                            }else if (data.order.order_shipping_item.delivery_type == 2) {
                                text = 'Giao hàng đến chành'
                            }
                            else if (data.order.order_shipping_item.delivery_type == 3) {
                                text = 'Gửi bưu điện'
                            }
                            else if (data.order.order_shipping_item.delivery_type == 4) {
                                text = 'Tự lấy hàng'
                            }
                           // text = data.order.customer_name;
                        }
                    }
                    data.delivery_type_key = text
                }
            },*/
            freight_id_key: {
                name: "freight_id_key",
                label: "Chành",
                class: 'center',
                computed: (data) => {
                    var text = ''
                    if (data.order){
                        if (data.order.order_shipping_item) {
                        if (data.order.order_shipping_item.freight) {
                            var code = data.order.order_shipping_item.freight.code
                            var name = data.order.order_shipping_item.freight.name
                            text = code+" - "+name
                        }
                        }
                    }
                    data.freight_id_key = text
                }
            },
            key_get: {
                refs: ' address_delivery delivery_type order_shipping{id code user{id fullname }  vehicle_delivery_id {id name } } ',
                display: false
            },



        })
    }

}