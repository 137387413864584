import BaseModel from '../core/BaseModel'
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
import CompanyModel from "./CompanyModel";
import ChannelModel from "./ChannelModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import GroupChannelCompanyModel from "./GroupChannelCompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import GroupChannelModel from "./GroupChannelModel";
import Common from '../core/Common';
export default class GroupChannelModel extends BaseModel {
    constructor() {
        super({
            tableName: 'group_channel', label: "Quản Lý Nhóm Kênh", display_key: 'name',name: "group_channel",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'width_135 common',
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            group_channel_companies: {
                refs: 'group_channel_companies (where: {deleted: {_eq: false}}) { company_id company { name code id } }',
                 display: false
            },
            // company_id: {
            //     name:'company_id',
            //     label:'Mã công ty',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new CompanyModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     class:"width_135 common",
            //     computed: (data) => {
            //         data.company_id = '';
            //     },
            //     // display: false
            // },
            company_id: Common.makeFilterCompany('company_id', 'Mã công ty', new CompanyModel(), "group_channel", "small-col"),

            channel: {
                    refs: 'channel { code id name }',
                display: false
            },

            // channel_id: {
            //     name:'channel_id',
            //     label:'Mã kênh',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new ChannelModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     class:"width_135 common",
            //     computed: (data) => {
            //         data.channel_id = '';
            //     },
            //     // display: false
            // },
            channel_id: Common.makeFilterCompany('channel_id', 'Mã kênh', new ChannelModel(), "group_channel", "width_135 common"),
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            code : {
                name: 'code',
                type: "Text",
                label: 'Mã nhóm kênh',
                filter: {type: 'input', match: 'contains'},
                class:"width_135 common"
            },
            name : {
              name: 'name',
              type: "Text",
              label: 'Tên nhóm kênh',
              filter: {type: 'input', match: 'contains'},
                class:"width_135 common",
            },
            active: {
                name: "active",
                label: "Hiển thị",
                type: "Selection",
                custom: 'SelectionText',
                options: [
                    {value: 'true', label: 'Có', style: 'color:  #000000;'},
                    {value: 'false', label: 'Không',style: 'color:  #000000;'}
                ],
                filter: {type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
                class:"width_135 common",
            },
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            updated_by_id: {
                refs:"updated_by_id { fullname}",
                type: "Selection",
                label: "Người cập nhật",
                custom: 'SelectionText',
                class: "width_135 common"
            },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
                class:"width_135 common",

            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },




        })
    }

}