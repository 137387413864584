import BaseModel from '../core/BaseModel'
//import UnitModel from "./UnitModel";
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class OrderModel extends BaseModel {

    constructor() {
        super({ 
                name: "order_item",
                tableName: 'order_item',
                label: "Danh Sách Đơn hàng",
                display_key: 'code',
                default_order: { key: 'updated_at', direction: 'asc' } }, 
            {
            check_box: {
                name: "check_box",
                label: " ",
                type: "Computed",
                custom: 'CheckBoxSelect',
                computed: (data) => {
                    data.stt = '';
                },
                filter: {}
                // display: { list: true, add: false, edit: false }
            },
            order: {
                refs: "order {code doc_code doc_created_at date_order type paid description customer_code customer{request_pay} order_status{code name} history_status_orders{code_status, reason{cancel_at_warehouse}} delivery_costs{id}}",
                display: false
            },
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                product_code: {
                    name: 'product_code',
                    type: "String",
                    label: 'Mã sản phẩm',
                    sortable: false,
                    width: 10,
                    /*filter: {type: 'input', match: 'contains'},*/
                },
                product_name: {
                    name: 'product_name',
                    type: "String",
                    label: 'Tên sản phẩm',
                    sortable: false,
                    width: 10,
                    /*filter: {type: 'input', match: 'contains'},*/
                },
                unit: {
                    refs: 'unit {id name}',
                    display: false
                },
                unit_name: {
                    name: "unit_name",
                    label: "Đơn vị tính",
                    type: "Selection",
                    custom: 'SelectionText',
                    computed: (data) => {
                        data.unit_id = '';
                    }
                },
                lot_number: {
                    name: 'lot_number',
                    type: "String",
                    label: 'Số lô/ Mã lô',
                    sortable: false,
                    width: 10,
                    /*filter: {type: 'input', match: 'contains'},*/
                },
                expiry_date: {
                    name: 'expiry_date',
                    type: "Date",
                    label: 'Hạn sử dụng',
                    width: 10,
                    /*filter: { type: 'DateRange', match: 'contains'},*/
                    class:'style-text-date'
                },
                material: {
                    refs: 'material {id name code}',
                    display: false
                },
                materials_code: {
                    name: "materials_code",
                    label: "Mã nhóm vật tư",
                    type: "Selection",
                    custom: 'SelectionText',
                    computed: (data) => {
                        data.materials_code = '';
                    }
                },
                warehouse: {
                    refs: 'warehouseByWarehouseId {id name code address}',
                    display: false
                },
                warehouse_code: {
                    name: "warehouse_code",
                    label: "Mã kho",
                    type: "Selection",
                    custom: 'SelectionText',
                    computed: (data) => {
                        data.materials_code = '';
                    }
                },
                qty: {
                    name: "qty",
                    label: "Số lượng",
                    type: "Computed",
                    computed: (data) => {
                        data.qty =0;
                    },
                    // display: { list: true, add: false, edit: false }
                },
                load_colum: {
                    refs: 'export_qty import_qty product_id',
                    display: false
                },




        },)
    }

}