import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Front"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/pages/NotFound/index"),
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/pages/Forbidden/index"),
  },
  {
    path: "/redirect",
    name: "redirect",
    component: () => import("@/views/pages/redirect"),
  },
  /*{
    path: '/webview/notification/:id',
    name: 'redirect',
    component: () => import('@/views/pages/Webview/Notification')
  },*/
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/auth/AuthLayout"),
    children: [
      {
        path: "/",
        redirect: "/auth/login",
        component: () => import("@/views/auth/Login"),
      },
      {
        path: "/auth/login",
        name: "login",
        component: () => import("@/views/auth/Login"),
      },
      {
        path: "/auth/confirm-email",
        name: "confirmEmail",
        component: () => import("@/views/auth/confirmEmail"),
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/admin/AdminLayout"),
    meta: {
      // // requiresAuth: true
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/admin/dashboard"),
        default: true,
        meta: {
          model: "dashboard",
          // requiresAuth: true,
        },
      },
      {
        path: "/",
        name: "index",
        component: () => import("@/views/admin/dashboard"),
        default: true,
        meta: {
          model: "dashboard",
          // requiresAuth: true,
        },
      },
      {
        path: "list-view_admin",
        name: "admin",
        component: () => import("@/views/admin/admin"),
        default: true,
        meta: {
          model: "view_admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-view_admin/add",
        name: "addAdmin",
        component: () => import("@/views/admin/admin/function"),
        meta: {
          model: "view_admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-view_admin/edit/:id",
        name: "editAdmin",
        component: () => import("@/views/admin/admin/function"),
        meta: {
          model: "view_admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-manager",
        name: "manager",
        component: () => import("@/views/admin/manager"),
        default: true,
        meta: {
          model: "admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-manager/add",
        name: "addManager",
        component: () => import("@/views/admin/manager/function"),
        meta: {
          model: "admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-manager/edit/:id",
        name: "editManager",
        component: () => import("@/views/admin/manager/function"),
        meta: {
          model: "admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-manager/view/:id",
        name: "viewManager",
        component: () => import("@/views/admin/manager/view"),
        meta: {
          model: "admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-users",
        name: "user",
        component: () => import("@/views/admin/user"),
        default: true,
        meta: {
          model: "users",
          // requiresAuth: true,
        },
      },
      {
        path: "list-users/add",
        name: "addUser",
        component: () => import("@/views/admin/user/function"),
        meta: {
          model: "users",
          // requiresAuth: true,
        },
      },
      {
        path: "list-users/edit/:id",
        name: "editUser",
        component: () => import("@/views/admin/user/function"),
        meta: {
          model: "users",
          // requiresAuth: true,
        },
      },
      {
        path: "list-users/view/:id",
        name: "viewUser",
        component: () => import("@/views/admin/user/view"),
        meta: {
          model: "users",
          // requiresAuth: true,
        },
      },

      {
        path: "list-view_admin",
        name: "admin",
        component: () => import("@/views/admin/admin"),
        default: true,
        meta: {
          model: "view_admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-view_admin/add",
        name: "addAdmin",
        component: () => import("@/views/admin/admin/function"),
        meta: {
          model: "view_admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-view_admin/edit/:id",
        name: "editAdmin",
        component: () => import("@/views/admin/admin/function"),
        meta: {
          model: "view_admin",
          // requiresAuth: true,
        },
      },
      {
        path: "list-active_users/:id",
        name: "active_users",
        component: () => import("@/views/admin/active_users"),
        meta: {
          model: "active_users",
          // requiresAuth: true
        },
      },
      {
        path: "list-policy",
        name: "policy",
        component: () => import("@/views/admin/policy/function"),
        meta: {
          model: "policy",
          // requiresAuth: true
        },
      },
      {
        path: "list-term",
        name: "term",
        component: () => import("@/views/admin/term/function"),
        meta: {
          model: "term",
          // requiresAuth: true
        },
      },
      {
        path: "list-contact",
        name: "contact",
        component: () => import("@/views/admin/contact/function"),
        meta: {
          model: "contact",
          // requiresAuth: true
        },
      },
      // {
      //   path: 'list-policy/edit/:id',
      //   name: 'editPolicy',
      //   component: () => import('@/views/admin/policy/function'),
      //   meta: {
      //     model: 'policy',
      //     // requiresAuth: true
      //   }
      // },

      {
        path: "list-notification",
        name: "notification",
        component: () => import("@/views/admin/notification"),
        default: true,
        meta: {
          model: "notification",
          // requiresAuth: true,
        },
      },
      {
        path: "list-notification/add",
        name: "addNotification",
        component: () => import("@/views/admin/notification/function"),
        meta: {
          model: "notification",
          // requiresAuth: true
        },
      },
      {
        path: "list-notification/edit/:id",
        name: "editNotification",
        component: () => import("@/views/admin/notification/function"),
        meta: {
          model: "notification",
          // requiresAuth: true
        },
      },

      {
        path: "list-group",
        name: "group",
        component: () => import("@/views/admin/permission"),
        default: true,
        meta: {
          model: "group",
          // requiresAuth: true,
        },
      },
      {
        path: "list-group/add",
        name: "addGroup",
        component: () => import("@/views/admin/permission/function"),
        meta: {
          model: "group",
          // requiresAuth: true,
        },
      },
      {
        path: "list-group/edit/:id",
        name: "editGroup",
        component: () => import("@/views/admin/permission/function"),
        meta: {
          model: "group",
          // requiresAuth: true,
        },
      },
      {
        path: "list-booking",
        name: "booking",
        component: () => import("@/views/admin/booking"),
        meta: {
          model: "booking",
          // requiresAuth: true,
        },
      },
      {
        path: "list-booking/view/:id",
        name: "viewBooking",
        component: () => import("@/views/admin/booking/view"),
        meta: {
          model: "booking",
          // requiresAuth: true,
        },
      },
      {
        path: "list-booking/add",
        name: "addBooking",
        component: () => import("@/views/admin/booking/function"),
        meta: {
          model: "booking",
          // requiresAuth: true
        },
      },
      {
        path: "list-booking/add/:id",
        name: "reexamineBooking",
        component: () => import("@/views/admin/booking/function"),
        meta: {
          model: "booking",
          // requiresAuth: true
        },
      },
      {
        path: "list-booking/edit/:id",
        name: "editBooking",
        component: () => import("@/views/admin/booking/function"),
        meta: {
          model: "booking",
          // requiresAuth: true
        },
      },
      {
        path: "list-category-beauty",
        name: "categoryService",
        component: () => import("@/views/admin/category_service"),
        meta: {
          model: "category_service",
          // requiresAuth: true,
        },
      },
      {
        path: "list-category-beauty/add",
        name: "addCategoryService",
        component: () => import("@/views/admin/category_service/function"),
        meta: {
          model: "category_service",
          // requiresAuth: true
        },
      },
      {
        path: "list-category-beauty/edit/:id",
        name: "editCategoryService",
        component: () => import("@/views/admin/category_service/function"),
        meta: {
          model: "category_service",
          // requiresAuth: true
        },
      },
      {
        path: "list-service",
        name: "service",
        component: () => import("@/views/admin/service"),
        meta: {
          model: "service",
          // requiresAuth: true,
        },
      },
      {
        path: "list-service/add",
        name: "addService",
        component: () => import("@/views/admin/service/function"),
        meta: {
          model: "service",
          // requiresAuth: true
        },
      },
      {
        path: "list-service/edit/:id",
        name: "editService",
        component: () => import("@/views/admin/service/function"),
        meta: {
          model: "service",
          // requiresAuth: true
        },
      },
      {
        path: "list-promotion",
        name: "promotion",
        component: () => import("@/views/admin/promotion"),
        meta: {
          model: "promotion",
          // requiresAuth: true,
        },
      },
      {
        path: "list-promotion/add",
        name: "addPromotion",
        component: () => import("@/views/admin/promotion/function"),
        meta: {
          model: "promotion",
          // requiresAuth: true
        },
      },
      {
        path: "list-promotion/edit/:id",
        name: "editPromotion",
        component: () => import("@/views/admin/promotion/function"),
        meta: {
          model: "promotion",
          // requiresAuth: true
        },
      },
      {
        path: "list-club",
        name: "club",
        component: () => import("@/views/admin/club/function"),
        meta: {
          model: "club",
          // requiresAuth: true,
        },
      },
      {
        path: "list-question",
        name: "question",
        component: () => import("@/views/admin/question"),
        meta: {
          model: "questions",
          // requiresAuth: true,
        },
      },
      {
        path: "list-question/add",
        name: "addQuestion",
        component: () => import("@/views/admin/question/function"),
        meta: {
          model: "questions",
          // requiresAuth: true
        },
      },
      {
        path: "list-question/edit/:id",
        name: "editQuestion",
        component: () => import("@/views/admin/question/function"),
        meta: {
          model: "questions",
          // requiresAuth: true
        },
      },
      {
        path: "list-member-club",
        name: "question",
        component: () => import("@/views/admin/club_register"),
        meta: {
          model: "club_register",
          // requiresAuth: true,
        },
      },
      // {
      //   path: 'list-member-club/add',
      //   name: 'addQuestion',
      //   component: () => import('@/views/admin/club_register/function'),
      //   meta: {
      //     model: 'club_register',
      //     // requiresAuth: true
      //   }
      // },
      // {
      //   path: 'list-member-club/edit/:id',
      //   name: 'editQuestion',
      //   component: () => import('@/views/admin/club_register/function'),
      //   meta: {
      //     model: 'club_register',
      //     // requiresAuth: true
      //   }
      // },
      {
        path: "list-news",
        name: "news",
        component: () => import("@/views/admin/news"),
        meta: {
          model: "news",
          // requiresAuth: true,
        },
      },
      {
        path: "list-news/add",
        name: "addNews",
        component: () => import("@/views/admin/news/function"),
        meta: {
          model: "news",
          // requiresAuth: true
        },
      },
      {
        path: "list-news/edit/:id",
        name: "editNews",
        component: () => import("@/views/admin/news/function"),
        meta: {
          model: "news",
          // requiresAuth: true
        },
      },
      {
        path: "list-holiday",
        name: "holiday",
        component: () => import("@/views/admin/holiday"),
        meta: {
          model: "holiday",
          // requiresAuth: true,
        },
      },
      {
        path: "list-holiday/add",
        name: "addHoliday",
        component: () => import("@/views/admin/holiday/function"),
        meta: {
          model: "holiday",
          // requiresAuth: true
        },
      },
      {
        path: "list-holiday/edit/:id",
        name: "editHoliday",
        component: () => import("@/views/admin/holiday/function"),
        meta: {
          model: "holiday",
          // requiresAuth: true
        },
      },

      {
        path: "list-type-service",
        name: "typeService",
        component: () => import("@/views/admin/type_service"),
        meta: {
          model: "type_service",
          // requiresAuth: true,
        },
      },
      {
        path: "list-type-service/add",
        name: "addTypeService",
        component: () => import("@/views/admin/type_service/function"),
        meta: {
          model: "type_service",
          // requiresAuth: true
        },
      },
      {
        path: "list-type-service/edit/:id",
        name: "editTypeService",
        component: () => import("@/views/admin/type_service/function"),
        meta: {
          model: "type_service",
          // requiresAuth: true
        },
      },
      {
        path: "list-reason",
        name: "reason",
        component: () => import("@/views/admin/reason"),
        meta: {
          model: "reason",
          // requiresAuth: true,
        },
      },
      {
        path: "list-reason/add",
        name: "addreason",
        component: () => import("@/views/admin/reason/function"),
        meta: {
          model: "reason",
          // requiresAuth: true
        },
      },
      {
        path: "list-reason/edit/:id",
        name: "editreason",
        component: () => import("@/views/admin/reason/function"),
        meta: {
          model: "reason",
          // requiresAuth: true
        },
      },
      {
        path: "list-vehicle",
        name: "vehicle",
        component: () => import("@/views/admin/vehicle"),
        meta: {
          model: "vehicle",
          // requiresAuth: true,
        },
      },
      {
        path: "list-vehicle/add",
        name: "addvehicle",
        component: () => import("@/views/admin/vehicle/function"),
        meta: {
          model: "vehicle",
          // requiresAuth: true
        },
      },
      {
        path: "list-vehicle/edit/:id",
        name: "editvehicle",
        component: () => import("@/views/admin/vehicle/function"),
        meta: {
          model: "vehicle",
          // requiresAuth: true
        },
      },
      {
        path: "list-freight",
        name: "freight",
        component: () => import("@/views/admin/freight"),
        meta: {
          model: "freight",
          // requiresAuth: true,
        },
      },
      {
        path: "list-freight/add",
        name: "addfreight",
        component: () => import("@/views/admin/freight/function"),
        meta: {
          model: "freight",
          // requiresAuth: true
        },
      },
      {
        path: "list-freight/edit/:id",
        name: "editfreight",
        component: () => import("@/views/admin/freight/function"),
        meta: {
          model: "freight",
          // requiresAuth: true,
        },
      },
      {
        path: "list-order",
        name: "admin",
        component: () => import("@/views/admin/order"),
        default: true,
        meta: {
          model: "order",
          // requiresAuth: true,
        },
      },
      {
        path: "list-order/edit/:id",
        name: "editOrder",
        component: () => import("@/views/admin/order/function"),
        meta: {
          model: "order",
          // requiresAuth: true
        },
      },
      {
        path: "list-order/confirm-order/:id",
        name: "editOrder",
        component: () => import("@/views/admin/order/confirm_order_function"),
        meta: {
          model: "order",
          // requiresAuth: true
        },
      },
      {
        path: "list-order/view/:id",
        name: "viewOrder",
        component: () => import("@/views/admin/order/function"),
        meta: {
          model: "order",
          // requiresAuth: true
        },
      },
      {
        path: 'list-process-status-order',
        name: 'admin',
        component: () => import('@/views/admin/order/index_process_status'),
        default: true,
        meta: {
          model: 'order',
          // requiresAuth: true,
        }
      },
      {
        path: "list-channel",
        name: "channel",
        component: () => import("@/views/admin/channel"),
        meta: {
          model: "channel",
        },
      },
      {
        path: "list-channel/add",
        name: "addChannel",
        component: () => import("@/views/admin/channel/function"),
        meta: {
          model: "channel",
        },
      },
      {
        path: "list-channel/edit/:id",
        name: "editChannel",
        component: () => import("@/views/admin/channel/function"),
        meta: {
          model: "channel",
        },
      },
      {
        path: "list-group_channel",
        name: "group_channel",
        component: () => import("@/views/admin/group_channel"),
        meta: {
          model: "group_channel",
        },
      },
      {
        path: "list-group_channel/add",
        name: "addGroup_channel",
        component: () => import("@/views/admin/group_channel/function"),
        meta: {
          model: "group_channel",
        },
      },
      {
        path: "list-group_channel/edit/:id",
        name: "editGroup_channel",
        component: () => import("@/views/admin/group_channel/function"),
        meta: {
          model: "group_channel",
        },
      },
      {
        path: "list-product",
        name: "product",
        component: () => import("@/views/admin/product"),
        meta: {
          model: "product",
          // requiresAuth: true,
        },
      },
      {
        path: "list-product/add",
        name: "addPromotion",
        component: () => import("@/views/admin/product/function"),
        meta: {
          model: "product",
          // requiresAuth: true
        },
      },
      {
        path: "list-product/edit/:id",
        name: "editProduct",
        component: () => import("@/views/admin/product/function"),
        meta: {
          model: "product",
          // requiresAuth: true
        },
      },


      {
        path: "list-shipping_method/other",
        name: "shippingMethodOther",
        component: () => import("@/views/admin/shipping_method/other"),
        meta: {
          model: "shipping_method",
        },
      },
      {
        path: "list-shipping_method/other/add",
        name: "addShippingMethodOther",
        component: () => import("@/views/admin/shipping_method/other/function"),
        meta: {
          model: "shipping_method",
        },
      },
      {
        path: "list-shipping_method/other/edit/:id",
        name: "editShippingMethodOther",
        component: () => import("@/views/admin/shipping_method/other/function"),
        meta: {
          model: "shipping_method",
        },
      },


      {
        path: "list-shipping_method/logictics",
        name: "shippingMethodLogictics",
        component: () => import("@/views/admin/shipping_method/logictics"),
        meta: {
          model: "shipping_method_logictics",
        },
      },
      {
        path: "list-shipping_method/logictics/add",
        name: "addShippingMethodLogictics",
        component: () => import("@/views/admin/shipping_method/logictics/function"),
        meta: {
          model: "shipping_method_logictics",
        },
      },
      {
        path: "list-shipping_method/logictics/edit/:id",
        name: "editShippingMethodLogictics",
        component: () => import("@/views/admin/shipping_method/logictics/function"),
        meta: {
          model: "shipping_method_logictics",
        },
      },
      {
        path: 'list-parcel',
        name: 'parcel',
        component: () => import('@/views/admin/parcel'),
        meta: {
          model: 'parcel',
        }
      },
      {
        path: 'list-parcel/add',
        name: 'addParcel',
        component: () => import('@/views/admin/parcel/function'),
        meta: {
          model: 'parcel',
        }
      },
      {
        path: 'list-parcel/edit/:id',
        name: 'editParcel',
        component: () => import('@/views/admin/parcel/function'),
        meta: {
          model: 'parcel',
        }
      },
      {
        path: 'list-area',
        name: 'area',
        component: () => import('@/views/admin/area'),
        meta: {
          model: 'area',
        }
      },
      {
        path: 'list-area/add',
        name: 'addArea',
        component: () => import('@/views/admin/area/function'),
        meta: {
          model: 'area',
        }
      },
      {
        path: 'list-area/edit/:id',
        name: 'editArea',
        component: () => import('@/views/admin/area/function'),
        meta: {
          model: 'area',
        }
      },

      {
        path: 'list-customer',
        name: 'customer',
        component: () => import('@/views/admin/customer'),
        meta: {
          model: 'customer',
        }
      },
      {
        path: 'list-customer/add',
        name: 'addCustomer',
        component: () => import('@/views/admin/customer/function'),
        meta: {
          model: 'customer',
        }
      },
      {
        path: 'list-customer/edit/:id',
        name: 'editCustomer',
        component: () => import('@/views/admin/customer/function'),
        meta: {
          model: 'customer',
        }
      }
      ,

        {
            path: 'list-warehouse',
            name: 'area',
            component: () => import('@/views/admin/warehouse'),
            meta: {
                model: 'warehouse',
            }
        },
        {
            path: 'list-warehouse/add',
            name: 'addWarehouse',
            component: () => import('@/views/admin/warehouse/function'),
            meta: {
                model: 'warehouse',
            }
        },
        {
            path: 'list-warehouse/edit/:id',
            name: 'editWarehouse',
            component: () => import('@/views/admin/warehouse/function'),
            meta: {
                model: 'warehouse',
            }
        },
      {
        path: 'list-stock_quant',
        name: 'stock_quant',
        component: () => import('@/views/admin/stock_quant'),
        meta: {
          model: 'stock_quant',
        }
      },
      {
        path: 'list-order_shipping',
        name: 'order_shipping',
        component: () => import('@/views/admin/order_shipping'),
        meta: {
          model: 'order_shipping',
        }
      },
      {
        path: 'list-order_shipping/add',
        name: 'addOrdershipping',
        component: () => import('@/views/admin/order_shipping/function'),
        meta: {
          model: 'order_shipping',
        }
      },
      {
        path: 'list-order_shipping/edit/:id',
        name: 'editOrdershipping',
        component: () => import('@/views/admin/order_shipping/function'),
        meta: {
          model: 'order_shipping',
        }
      },
      {
        path: 'list-order_shipping/view/:id',
        name: 'viewOrdershipping',
        component: () => import('@/views/admin/order_shipping/function'),
        meta: {
          model: 'order_shipping',
        }
      },
      {
        path: 'list-other_costs',
        name: 'otherCosts',
        component: () => import('@/views/admin/other_costs'),
        meta: {
          model: 'other_costs',
        }
      },
      {
        path: 'list-other_costs/add',
        name: 'addOtherCosts',
        component: () => import('@/views/admin/other_costs/function'),
        meta: {
          model: 'other_cost',
        }
      },
      {
        path: 'list-other_costs/edit/:id',
        name: 'editOtherCosts',
        component: () => import('@/views/admin/other_costs/function'),
        meta: {
          model: 'other_costs',
        }
      },
      {
        path: "list-report_expense",
        name: "admin",
        component: () => import("@/views/admin/report_detail_cost_service"),
        default: true,
        meta: {
          model: "report_detail_cost_service",
          // requiresAuth: true,
        },
      },
      {
        path: 'list-report_expense/edit/:id',
        name: 'editDeliveryCosts',
        component: () => import('@/views/admin/report_detail_cost_service/function'),
        meta: {
          model: 'other_costs',
        }
      },
      {
        path: "list-report_edit_order",
        name: "admin",
        component: () => import("@/views/admin/log_order"),
        default: true,
        meta: {
          model: "log_order",
          // requiresAuth: true,
        },
      },
      {
        path: 'list-report_inventory',
        name: 'inventory_report',
        component: () => import('@/views/admin/report_inventory'),
        meta: {
          model: 'inventory_report',
        }
      },
      {
        path: 'list-report_order_progress',
        name: 'report_order_progress',
        component: () => import('@/views/admin/report_order_progress'),
        meta: {
          model: 'report_order_progress',
        }
      }
      ,{
        path: 'list-report_summary',
        name: 'report_summary',
        component: () => import('@/views/admin/report_summary'),
        meta: {
          model: 'report_summary',
        }
      }
      ,{
        path: 'list-report_document',
        name: 'report_document',
        component: () => import('@/views/admin/report_document'),
        meta: {
          model: 'order_docs',
        }
      },
      {
        path: 'list-report_order_category',
        name: 'report_order_category',
        component: () => import('@/views/admin/report_order_category'),
        meta: {
          model: 'report_order_category',
        }
      },
      {
        path: 'list-order_progress',
        name: 'order_progress',
        component: () => import('@/views/admin/order_progress'),
        meta: {
          model: 'order_progress',
        }
      },
      {
        path: 'list-group-users/:id',
        name: 'list-group-users',
        component: () => import('@/views/admin/permission/list_user'),
        meta: {
          model: 'group_user'
        }
      },
      {
        path: 'list-document',
        name: 'document',
        component: () => import('@/views/admin/document'),
        meta: {
          model: 'document',
        }
      },
      {
        path: 'list-document/add',
        name: 'addDocument',
        component: () => import('@/views/admin/document/function'),
        meta: {
          model: 'document'
        }
      },
      {
        path: 'list-document/edit/:id',
        name: 'editDocument',
        component: () => import('@/views/admin/document/function'),
        meta: {
          model: 'document'
        }
      },
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
  //base: process.env.BASE_URL,
});

export default router;
