import BaseModel from "../core/BaseModel";
import Common from "../core/Common";
import CompanyModel from "./CompanyModel";
// import TypeReasonModel from "@/models/TypeReasonModel";
//import GroupModel from "@/models/GroupModel";
//import gql from 'graphql-tag'
export default class VehicleModel extends BaseModel {
  constructor() {
    super(
      {
        name: "vehicle",
        tableName: "vehicle",
        label: "Danh Sách Phương Tiện Giao Hàng",
        display_key: "name",
        //default_order: { key: "updated_at", direction: "asc" },
      },
      {
        stt: {
          name: "stt",
          label: "STT",
          type: "Computed",
          class: "xs-col",
          computed: (data, index) => {
            data.stt = index + 1;
          },
          // display: { list: true, add: false, edit: false }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          width: 1,
          primaryKey: true,
          display: false,
        },
        company_id: Common.makeFilterCompany('company_id', 'Mã công ty', new CompanyModel(), "vehicle","width_160 common"),
        name: {
          name: "name",
          type: "String",
          label: "Phương tiện giao hàng",
          sortable: false,
          class: "medium-col",
          filter: { type: "input", match: "contains" },
        },
        type: {
          name: "type",
          label: "Loại phương tiện",
          type: "Selection",
          custom: "SelectionText",
          //   models: new TypeReasonModel(),
          class: "small-col",
          options: [
            { value: "1", label: "Xe tải", style: "color:  #000000;" },
            { value: "2", label: "Xe máy", style: "color:  #000000;" },
          ],
          filter: {
            type: "select",
            optionLabel: "label",
            optionKey: "value",
            match: "equals",
          },
        },
        classify: {
          name: "classify",
          label: "Phân loại",
          type: "Selection",
          custom: "SelectionText",
          class: "small-col",
          options: [
            { value: "1", label: "Nội bộ", style: "color:  #000000;" },
            { value: "2", label: "Thuê ngoài", style: "color:  #000000;" },
          ],
        },
        vehicle_id: {
          name: "vehicle_id",
          type: "String",
          label: "Phương tiện hiển thị trong báo cáo",
          sortable: false,
          display: false,
        },
        active: {
          name: "active",
          type: "Selection",
          label: "Hiển thị",
          custom: "SelectionText",
          sortable: false,
          disabled: true,
          class: "small-col",
          options: [
            { value: true, label: "Có", style: "color:  #000000;" },
            { value: false, label: "Không", style: "color:  #000000;" },
          ],
          filter: {
            type: "select",
            optionLabel: "label",
            optionKey: "value",
            match: "equals",
          },
          // display: false
        },
        created_by: {
          name: "created_by",
          type: "Uuid",
          label: "ID",
          //primaryKey: true,
          display: false,
        },
        updated_by_id: {
          refs: "updated_by_id { fullname}",
          type: "String",
          label: "Người cập nhật",
          class: "small-col",
          custom: "SelectionText",
        },
        updated_at: {
          name: "updated_at",
          type: "Datetime",
          label: "Ngày cập nhật",
          sortable: false,
          class: "small-col",
          // filter: { type: "DateRange", match: "contains" },
          // display: false
        },
        created_at: {
          name: "created_at",
          type: "Datetime",
          label: "Ngày tạo",
          sortable: false,
          filter: { type: "DateRange", match: "contains" },
          display: false,
        },
        deleted: {
          name: "deleted",
          type: "Boolean",
          label: "Đã xóa",
          sortable: false,
          // filter: { type: "DateRange", match: "contains" },
          display: false,
        },
        key_get1: {
          refs:  'vehicle_companies { company_id company { code name } }',
          display: false
        },

        /* rel_object_group_uses: {
                    refs: `rel_object_group_uses {
                            group_id
                        }`,
                    display: false
                },
                name_group: {
                    name: 'name_group',
                    type: "String",
                    label: 'Nhóm quyền',
                    sortable: false,
                    display: false
                },
                name_group_filter: {
                    name: 'name_group_filter',
                    type: "Selection",
                    label: 'Nhóm quyền',
                    sortable: false,
                    width: 10,
                    models: new GroupModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.name_group_filter = '';
                    }
                },*/
      }
    );
  }
}
