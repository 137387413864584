import BaseModel from '../core/BaseModel'
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
//import CompanyModel from "./CompanyModel";
// im//port ChannelModel1 from "./ChannelModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
export default class ChannelSelectModel extends BaseModel {
    constructor() {
        super({
            tableName: 'channel', label: "Kênh", display_key: 'code',name: "channel_select",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            code : {
                name: 'code',
                type: "Text",
                label: 'Mã kênh',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.code = '';
                }
            },
            name : {
              name: 'name',
              type: "Text",
              label: 'Tên kênh phân phối',
              filter: {type: 'input', match: 'contains'},
              // computed: (data) => {
              //   data.name = '';
              // }
            },
            active: {
                name: "active",
                label: "Hiển thị",
                type: "Selection",
                custom: 'SelectionText',
                options: [
                    {value: 'true', label: 'true', key : 'true',style: 'color:  #000000;'},
                    {value: 'false', label: 'fasle', key : 'fasle',style: 'color:  #000000;'}
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
            },
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            created_by: {
                name: "created_by",
                type: "Uuid",
                label: "Người cập nhật",
                // primaryKey: true,
                // display: false,
            },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
              filter: { type: 'DateRange', match: 'contains'},
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },




        })
    }

}