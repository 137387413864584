import BaseModel from '../core/BaseModel'
import ServiceModel from './ServiceModel';
import TypeServiceModel from './TypeServiceModel';
import StaffModel from './StaffModel';
export default class BookingModel extends BaseModel {

    constructor() {
        super({
                name: "booking",
                tableName: 'booking',
                label: "Lịch hẹn",
                /* display_key: 'fullname',
                 default_order: { key: 'updated_at', direction: 'asc' }*/
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                // booking_line: {
                //     name: 'booking_line',
                //     label: 'Booking Line',
                //     display: false
                // },
                booking_lines: { 
                    refs: 'booking_lines {service {id name}}',
                    display: false
                },
                code: { 
                    name: 'code',
                    type: "String",
                    label: 'Mã lịch hẹn',
                    // width : 20,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },
                time: { 
                    refs: 'time {id name}',
                    display: false
                },
                branch: { 
                    refs: 'branch {id name}',
                    display: false
                },
                type_service: { 
                    refs: 'type_service {id name}',
                    display: false
                },
                type_service_name: {
                    name: 'type_service_name', 
                    label: 'Loại dịch vụ',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new TypeServiceModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.type_service_name = '';
                    }
                },
                service_name: {
                    name: 'service_name',
                    label: 'Dịch vụ',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new ServiceModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.service_name = '';
                    }
                },
                date_booking: {
                    name: 'date_booking',
                    type: "Date",
                    label: 'Ngày khám',
                    // width : 20,
                    filter: {type: 'DateRange', match: 'contains'},
                    // display: false
                },
                time_name: {
                    name: 'time_name',
                    type: "Text",
                    label: 'Giờ khám',
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.time_name = '';
                    }
                },
                branch_name: {
                    name: 'branch_name',
                    type: "Text",
                    label: 'Chi nhánh',
                    filter: {type: 'input', match: 'contains'},
                    computed: (data) => {
                        data.branch_name = '';
                    }
                },
                first_name: {
                    name: 'first_name',
                    type: "String",
                    label: 'Họ',
                    // width : 20,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },
                last_name: {
                    name: 'last_name',
                    type: "String",
                    label: 'Tên',
                    // width : 20,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },
                prefix_phone: {
                    name: 'prefix_phone',
                    type: "String",
                    label: 'Số điện thoại',
                    sortable: false,
                    // width: 8,
                    filter: {
                        type: 'input',
                        match: 'contains'
                    },
                    display: false
                },
                phone: {
                    name: 'phone',
                    type: "String",
                    label: 'Số điện thoại',
                    sortable: false,
                    // width: 8,
                    filter: {
                        type: 'input',
                        match: 'contains'
                    },
                },
                email : {
                    name: 'email',
                    type: "String",
                    label: 'Email',
                    // width : 20,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },
                // status : {
                //     name: 'status',
                //     label: 'Trạng thái',
                //     display: false
                // },
                status : {
                    name: 'status',
                    type: "Selection",
                    label: 'Trạng thái',
                    custom: 'SelectionText',
                    // width : 6,
                    options: [
                        {value:1,label:"Đã đặt"},
                        {value:2,label:"Đã xác nhận"},
                        {value:3, label:"Hủy hẹn"},
                        {value:4,label:"Hoàn thành"}
                    ],
                    filter: {
                      type: 'select',
                      optionLabel: 'label',
                      optionKey: 'value',
                      match: 'equals'
                    },
                },
                staff: { 
                    refs: 'staff {id name}',
                    display: false
                },
                staff_name: {
                    name: 'staff_name',
                    label: 'Nhân viên phụ trách',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new StaffModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.staff_name = '';
                    }
                },
                created_at : { 
                    name: 'created_at', 
                    type: "Datetime",
                    label: 'Ngày tạo',
                    filter: { type: 'DateRange', match: 'contains'}
                }
            })
    }

}