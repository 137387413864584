import BaseModel from '../core/BaseModel';
// import CompanyModel from "./CompanyModel";
// import BranchModel from './BranchModel';
export default class HolidayItemModel extends BaseModel {
    constructor() {
        super({ 
                name: "holiday_item", tableName: 'holiday_item', label: "Ngày lễ/ngày nghỉ", display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },

            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
                date: {
                    name: 'id',
                    type: "Uuid",
                    label: 'date',
                    display: false
                },

                holiday_id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'holiday id',
                    display: false
                },

            },)
    }

}