    import BaseModel from '../core/BaseModel'
    // import StateModel from "./StateModel";
    // import DistrictModel from "./DistrictModel";
    // import WardModel from "./WardModel";
    // import CountryModel from "./CountryModel";
    // import TypeWareHouseModel from "./TypeWareHouseModel";
export default class SelectWareHouseModel extends BaseModel {
    constructor() {
        super({
            tableName: 'warehouse', label: "Khu vực", display_key: 'code', name: "warehouse_select",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            code : {
                name: 'code',
                type: "Text",
                label: 'Mã kênh',
            },
            name : {
                name: 'name',
                type: "Text",
                label: 'Tên kho',
                filter: {type: 'input', match: 'contains'},
            },
            // type_warehouse: {
            //     refs:  ' type_warehouse {  name id }',
            //     display: false
            // },
            // type_id: {
            //     name:'type_warehouse',
            //     label:'Loại',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new TypeWareHouseModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.type_id = '';
            //     },
            // },
            id: {
              name: 'id',
              type: "Uuid",
              label: 'ID',
              primaryKey: true,
              display: false
            },
            //
            // country: {
            //     refs:  ' country {  name id }',
            //     display: false
            // },
            // country_id: {
            //     name:'country_id',
            //     label:'Quốc gia',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new CountryModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.country_id = '';
            //     },
            // },
            // state: {
            //     refs:  ' state {  name id }',
            //     display: false
            // },
            // state_id: {
            //     name:'state_id',
            //     label:'Tỉnh',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new StateModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.state_id = '';
            //     },
            // },
            // district: {
            //     refs:  ' district {  name id }',
            //     display: false
            // },
            // district_id: {
            //     name:'district_id',
            //     label:'Quận',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new DistrictModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.district_id = '';
            //     },
            // },
            // ward: {
            //     refs:  ' ward {  name id }',
            //     display: false
            // },
            // ward_id: {
            //     name:'ward_id',
            //     label:'Xã/Phường',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new WardModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.ward_id = '';
            //     },
            // },
            address : {
                name: 'address',
                type: "Text",
                label: 'Số nhà, tên đường',
                filter: {type: 'input', match: 'contains'},
            },


            active: {
                name: "status",
                label: "Trạng thái",
                type: "Selection",
                custom: 'SelectionText',
                options: [
                    {value: 'true', label: 'Hoạt động', style: 'color:  #000000;'},
                    {value: 'false', label: 'Dừng hoạt động',style: 'color:  #000000;'}
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
            },
            updated_by: {
                name: "updated_by",
                type: "Uuid",
                label: "Người cập nhật",
                // primaryKey: true,
                // display: false,
            },
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
              filter: { type: 'DateRange', match: 'contains'},
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },




        })
    }

}