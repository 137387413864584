import BaseModel from "../core/BaseModel";
export default class TypeServiceModel extends BaseModel {
  constructor() {
    super(
      {
        name: "type_service",
        tableName: "type_service",
        label: "Loại dịch vụ",
        display_key: "name",
      },
      {
        stt: {
          name: "stt",
          label: "STT",
          type: "Computed",
          computed: (data, index) => {
            data.stt = index + 1;
          },
          // display: { list: true, add: false, edit: false }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        name: {
          name: "name",
          type: "String",
          label: "Tên loại dịch vụ",
          sortable: false,
          filter: {
            type: "input",
            match: "contains",
          },
        },
        name_en: {
          name: "name_en",
          type: "String",
          label: "Tên tiếng anh",
          sortable: false,
          filter: {
            type: "input",
            match: "contains",
          },
        },
        icon: {
          name: "icon",
          label: "Icon",
          custom: "image",
          width: 8,
          type: "Computed",
          computed: (data, index) => {
            data.icon = index + 1;
          },
          display: false,
        },
        logo: {
          name: "logo",
          label: "Hình ảnh",
          custom: "image",
          width: 8,
          type: "Computed",
          computed: (data, index) => {
            data.logo = index + 1;
          },
          display: false,
        },
        updated_at: {
          name: "updated_at",
          type: "Datetime",
          label: "Thời gian cập nhật",
          filter: { type: "DateRange", match: "contains" },
        },
      }
    );
  }
}
