import BaseModel from '../core/BaseModel';
import CompanyModel from './CompanyModel';
import Common from '../core/Common';
export default class OtherCostsModel extends BaseModel {
    constructor() {
        super({ 
                name: "other_costs",
                tableName: 'other_costs', 
                label: "Khai Báo Chi Phí Khác", 
                display_key: 'name'
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    class: "xs-col",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            company: {
                refs: "company{id name code}",
                display: false
            },
            // company_code: {
            //     name: 'company_code',
            //     label: 'Mã công ty',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new CompanyModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.company_code = '';
            //     }
            // },
            company_code: Common.makeFilterCompany('company_code', 'Mã công ty', new CompanyModel(), "other_costs", "small-col"),
            other_costs_services: {
                refs: "other_costs_services{id shipping_method{name code}}",
                display: false
            },
            code : { 
                name: 'code', 
                type: "String", 
                custom: "SelectionText",
                label: 'Mã chi phí',
                sortable: false,
                class: "small-col",
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.code = '';
                }
            },
            name : { 
                name: 'name', 
                type: "String", 
                label: 'Tên hạng mục chi phí',
                custom: "SelectionText",
                sortable: false,
                class: "small-col",
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.name = '';
                }
            },
            date_costs: {
                name: 'date_costs', 
                type: "Date",
                label: 'Thời gian',
                class: "small-col",
                filter: { type: 'Date', match: 'contains'}
            },
            active: {
                name: 'active',
                label: 'Hiển thị',
                type: "Selection",
                class: "small-col",
                custom: 'SelectionText',
                // width : 6,
                options: [
                    {value:true, label:"Hiện"},
                    {value:false, label:"Ẩn"}
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
                display: false
            },
            user_update: {
                refs: "user_update { id fullname }",
                display: false
            },
            updated_by: {
                name: 'updated_by', 
                type: "Text",
                class: "small-col",
                label: 'Người cập nhật',
                computed: (data) => {
                    data.updated_by = '';
                }
            },
            updated_at: {
                name: 'updated_at', 
                type: "Datetime",
                class: "medium-col",
                label: 'Ngày cập nhật'
            },
        },)
    }

}