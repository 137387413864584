import BaseModel from '../core/BaseModel'
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
import CompanyModel from "./CompanyModel";
import Common from '../core/Common';
import ChannelSelectModel from "@/models/ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import ChannelCompanyModel from "./ChannelCompanyModel";
export default class ChannelModel extends BaseModel {
    constructor(key_display = 'code') {
        super({
            tableName: 'channel', label: " Quản Lý Kênh", display_key: key_display, name: "channel",
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'width_160 common',
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            channel_companies: {
                refs: 'channel_companies (where: {deleted: {_eq: false}}) { company_id company { name code id } }',
                 display: false
            },
            customers: {
                refs: "customers {orders { paid order_status { code } } }",
                display: false
            },
            // company_id: {
            //     name:'company_id',
            //     label:'Mã công ty',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new CompanyModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     class:"width_160 common",
            //     computed: (data) => {
            //         data.company_id = '';
            //     },
            // },
            company_id: Common.makeFilterCompany('company_id', 'Mã công ty', new CompanyModel(), "channel","width_160 common"),
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            code : {
                name: 'code',
                type: "Selection",
                label: 'Mã kênh',
                custom: 'SelectionText',
                models: new ChannelSelectModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                sortable: false,
                class:"width_160 common",
            },
            prefix_code_cus : {
                name: 'prefix_code_cus',
                type: "Text",
                label: 'Quy chuẩn mã tiền tố khách hàng',
                class:"width_160 common",
            },
            name : {
              name: 'name',
              type: "String",
              label: 'Tên kênh phân phối',
                sortable: false,
              filter: {type: 'input', match: 'contains'},
                class:"width_160 common",
            },
            active: {
                name: "active",
                label: "Hiển thị",
                type: "Selection",
                custom: 'SelectionText',
                options: [
                    {value: 'true', label: 'Có', style: 'color:  #000000;'},
                    {value: 'false', label: 'Không',style: 'color:  #000000;'}
                ],
                filter: {type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals'},
                class:"width_160 common",
            },
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            updated_by_id: {
                refs:"updated_by_id { fullname}",
                type: "Selection",
                label: "Người cập nhật",
                custom: 'SelectionText',
                class:"width_160 common",
            },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
                class:"width_160 common",
            },
            created_at : {
              name: 'created_at',
              type: "Datetime",
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },




        })
    }

}