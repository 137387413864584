import BaseModel from '../core/BaseModel';
import TypeServiceModel from './TypeServiceModel';
export default class CategoryServiceModel extends BaseModel {
    constructor() {
        super({ 
                name: "category_service",
                tableName: 'service', 
                label: "Danh mục làm đẹp",
                display_key: 'name'
            },  
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
                id: { 
                    name: 'id', 
                    type: "Uuid", 
                    label: 'ID', 
                    primaryKey: true, 
                    display: false
                },
                name : { 
                    name: 'name', 
                    type: "String", 
                    label: 'Danh mục',
                    sortable: false,
                    filter: { 
                        type: 'input', 
                        match: 'contains' 
                    },
                },
                ordinal : {
                        name: 'ordinal',
                        type: "Int",
                        label: 'Thứ tự',
                        sortable: false,
                        // display: false
                },
                type_service_name: {
                    name: 'type_service_name', 
                    label: 'Loại dịch vụ',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new TypeServiceModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    computed: (data) => {
                        data.type_service_name = '';
                    },
                    display: false
                },
                type_service_id: {
                    name: 'type_service_id', 
                    type: "String", 
                    label: 'Loại dịch vụ',
                    display: false
                },
                updated_at: {
                    name: 'updated_at', 
                    type: "Datetime",
                    label: 'Cập nhật lúc',
                    filter: { type: 'DateRange', match: 'contains'}
                }
            },
        )
    }

}