import BaseModel from "../core/BaseModel";
import DepartmentModel from "@/models/DepartmentModel";
import CompanyModel from "@/models/CompanyModel";
import Common from "../core/Common";
export default class UserModel extends BaseModel {
  constructor() {
    super(
      {
        name: "user",
        tableName: "users",
        label: "Quản lý Users",
          display_key: 'fullname',
        /* display_key: 'fullname',
                 default_order: { key: 'updated_at', direction: 'asc' }*/
      },
      {
        stt: {
          name: "stt",
          label: "STT",
          type: "Computed",
          class: "xs-col",
          computed: (data, index) => {
            data.stt = index + 1;
          },
        },
        accounts: {
          refs: "accounts (where: {deleted: {_eq: false}}) {id account}",
          display: false,
        },
        users_companies: {
          refs: "users_companies (where: {deleted: {_eq: false}}) { company_id company { name code id } }",
          display: false,
        },
        users_group: {
          refs: "users_group { group_id group { name id } }",
          display: false,
        },
        // company_id: {
        //   name: "company_id",
        //   label: "Mã công ty",
        //   type: "Selection",
        //   custom: "SelectionText",
        //   models: new CompanyModel(),
        //   class: "small-col",
        //   filter: {
        //     type: "select",
        //     optionLabel: "label",
        //     optionKey: "value",
        //     match: "equals",
        //   },
        //   computed: (data) => {
        //     data.company_id = "";
        //   },
        // },
        company_id: Common.makeFilterCompany('company_id', 'Mã công ty', new CompanyModel(), "users", "small-col"),
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        department: {
          refs: "department { name id }",
          display: false,
        },
        department_id: {
          name: "department_id",
          type: "Selection",
          custom: "SelectionText",
          label: "Bộ phận",
          models: new DepartmentModel(),
          class: "small-col",
          sortable: false,
          filter: {
            type: "select",
            optionLabel: "label",
            optionKey: "value",
            match: "equals",
          },
          computed: (data) => {
            data.department_id = "";
          },
        },
        fullname: {
          name: "fullname",
          type: "String",
          label: "Tên",
          class: "large-col",
          filter: { type: "input", match: "contains" },
        },
        last_name: {
          name: "last_name",
          type: "String",
          label: "Họ",
          display: false,
        },
        first_name: {
          name: "first_name",
          label: "Tên",
          type: "String",
          display: false,
        },
        email: {
          name: "email",
          label: "Email",
          type: "String",
          class: "large-col",
          filter: { type: "input", match: "contains" },
        },
        phone: {
          name: "phone",
          label: "Số điện thoại",
          type: "String",
          class: "small-col",
          sortable: false,
          filter: { type: "input", match: "contains" },
        },
        prefix_phone: {
          name: "prefix_phone",
          label: "Mã điện thoại",
          type: "String",
          filter: { type: "input", match: "contains" },
          display: false,
          sortable: false,
        },
        gender: {
          name: "gender",
          label: "Giới tính",
          type: "Selection",
          custom: "SelectionText",
          options: [
            { value: 1, label: "Nam" },
            { value: 2, label: "Nữ" },
            { value: 3, label: "Khác" },
          ],
          filter: {
            type: "select",
            optionLabel: "label",
            optionKey: "value",
            match: "equals",
          },
          display: false,
        },
        fullbirthdate: {
          name: "fullbirthdate",
          type: "Date",
          label: "Ngày sinh",
          // width : 20,
          filter: { type: "Date", match: "contains" },
          display: false,
        },

        /*type_login: {
                    name: "type_login",
                    label: "Login Type",
                    type: "Selection",
                    custom: 'SelectionText',
                    // width : 6,
                    options: [
                      { value: 'USERNAME', label: 'Username',style: 'color:  #000000;'},
                      { value: 'FACEBOOK', label: 'Facebook',style: 'color:  #000000;'},
                      { value: "GOOGLE", label: 'Google',style: 'color:  #000000;'},
                      { value: 'APPLE', label: 'Apple ID',style: 'color:  #000000;'},
                    ],
                    filter: {
                      type: 'select',
                      optionLabel: 'label',
                      optionKey: 'value',
                      match: 'equals'
                    },
                    computed: (data) => {
                      data.type_login = '';
                    },
                    display: false
                },
                book_html: {
                    name: "book_html",
                    label: "Lịch hẹn",
                    type: "CustomUrl",
                    computed: (data) => {
                      data.book_html = '';
                    },
                    display: false
                },*/
        active: {
          name: "active",
          label: "Hoạt động",
          type: "Selection",
          custom: "SelectionText",
          class: "small-col",
          options: [
            { value: true, label: "Đang hoạt động" },
            { value: false, label: "Đã khóa" },
          ],
          filter: {
            type: "select",
            optionLabel: "label",
            optionKey: "value",
            match: "equals",
          },
        },
        updated_at: {
          name: "updated_at",
          type: "Datetime",
          label: "Ngày cập nhật",
          class: "small-col",
          // filter: { type: "DateRange", match: "contains" },
        },
        
          updated_by_id_key: {
            name: "updated_by_id_key",
            label: "Người cập nhật",
            type: "Computed",
            class: "medium-col",
            computed: (data) => {
              data.updated_by_id_key = (data.updated_by_id )?data.updated_by_id.fullname:'';
            },
          },
        updated_by_id: {
          refs: "updated_by_id { fullname}",
          type: "String",
          label: "Người cập nhật",
          class: "small-col",
          display: false,
          custom: "SelectionText",
          
        },
        updated_by: {
          name: "updated_by",
          type: "Uuid",
          label: "Người cập nhật",
          class: "small-col",
          display: false,
          // primaryKey: true,
          //   display: false,
        },
        created_at: {
          name: "created_at",
          type: "Datetime",
          label: "Ngày tạo",
          filter: { type: "DateRange", match: "contains" },
          display: false,
        },
        created_by: {
          name: "created_by",
          type: "Uuid",
          label: "Người tạo",
          // primaryKey: true,
          display: false,
        },
        role: {
          name: "role",
          type: "Selection",
          custom: "SelectionText",
          label: "Bộ phận",
          //   models: new RoleModel(),

          display: false,
        },
        key_get1: {
          refs:  'department_users { department_id department{ name } }',
          display: false
        },
        // count_club: {
        //     refs: 'users_club_expiration_aggregate {aggregate { count } }',
        //     display: false
        // },
        // count_sport: {
        //     refs: 'users_setting_sports_aggregate {aggregate { count } }',
        //     display: false
        // },
        // count_stripe: {
        //     refs: 'users_stripe_aggregate {aggregate { count } }',
        //     display: false
        // },
        /*count_booking: {
                    refs: 'bookings_aggregate {aggregate { count } }',
                    display: false
                },
                health_history: {
                    name: 'health_history',
                    type: "String",
                    label: 'Tiền sử sức khỏe',
                    filter: {type: 'input', match: 'contains'},
                    display: false
                }*/
      }
    );
  }
}
