import BaseModel from '../core/BaseModel'
export default class TimeModel extends BaseModel {
    constructor() {
        super({
            tableName: 'time', label: "Quản lý thời gian", display_key: 'id'
        }, {
            stt: { 
              name: "stt", 
              label: "No.", 
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            name : {
              name: 'name', 
              type: "Text", 
              label: 'Tên',
              filter: {type: 'input', match: 'contains'},
              computed: (data) => {
                data.name = '';
              }
            },
            ordinal: {
              name: "ordinal",
              label: "Thứ tự",
              type: "Text",
              display : false
            },
            active: {
              name: "active",
              label: "Hiển thị",
              type: "Checkbox",
              display : false
            },
            updated_at : { 
              name: 'updated_at', 
              type: "Datetime", 
              label: 'Updated Date',
              filter: { type: 'DateRange', match: 'contains'},
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },
            deleted: {
              name: "deleted",
              label: "Xóa",
              type: "Checkbox",
              display: false
            }
        })
    }

}