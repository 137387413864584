import BaseModel from '../core/BaseModel'

export default class DeliveryModel extends BaseModel {
    constructor() {
        super({
            tableName: 'customer_delivery', label: "địa chỉ giao hàng", display_key: 'code'
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            code: { 
                name: 'code', 
                type: "String", 
                label: 'Tiêu đề',  
                sortable: false,
                width : 15,
                // filter: { type: 'input', match: 'contains'}
            },
            deleted: {
                name: "deleted",
                label: "Xóa",
                type: "Checkbox",
                display: false
            },
            key_get1: {
                refs: 'address customer{code} ',
                display: false
            },
            // plain_name : {
            //     name: 'plain_name', 
            //     type: "Text", 
            //     label: 'Mô tả', 
            //     sortable: false,
            //     display: false
            // }
            
        })
    }

}