import BaseModel from '../core/BaseModel'
// import CompanyModel from "@/models/CompanyModel";
// import VehicleModel from "@/models/VehicleModel";
// import AreaModel from "@/models/AreaModel";
import AreaTypeModel from "@/models/AreaTypeModel";
// import TypeServiceModel from "./TypeServiceModel";
// import CompanyModel() from "./CompanyModel";
// import Chan() from "./CompanyModel";
// import CompanyModel from "./CompanyModel";
// import ChannelModel from "./ChannelModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import GroupChannelCompanyModel from "./GroupChannelCompanyModel";
// import ChannelSelectModel from "./ChannelSelectModel";
// import GroupChannelModel from "./GroupChannelModel";
export default class ReportSummaryModel extends BaseModel {
    constructor() {
        super({
            tableName: 'report_summary', label: "Báo Cáo Tổng Hợp Chi Phí Dịch Vụ Vận Hành Logistics", display_key: 'name_service',name: "report_summary",
        }, {
            stt: {
                name:'stt',
                label:'STT',
                type: "Text",

                class:"width_135 common",
            },
            code_cost: {
                name:'code_cost',
                label:'Mã chi phí',
                type: "Text",
                filter: {type: 'input', match: 'contains'},
                class:"width_135 common",
            },

            name_service: {
                name:'name_service',
                label:'Tên dịch vụ',
                type: "Text",
                filter: {type: 'input', match: 'contains'},
                class:"width_135 common",
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            type_vehicle : {
                name: 'type_vehicle',
                type: "Selection",
                label: 'Phương tiện vận chuyển',
                // custom: 'SelectionText',
                // models: new VehicleModel(),
                options: [
                    {value: 1, label: 'Xe tải'},
                    {value: 2, label: 'Xe máy'}
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                class:"width_135 common",
            },
            area_type : {
                name: 'area_type',
                type: "Selection",
                label: 'Khu vực giao hàng',
                custom: 'SelectionText',
                models: new AreaTypeModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                class:"width_135 common",

            },
            unit : {
              name: 'unit',
              type: "Text",
              label: 'Đơn vị tính',
                class:"width_135 common",
            },
            unit_price : {
                name: 'unit_price',
                type: "Text",
                label: 'Đơn giá',
                class:"width_135 common",
            },
            qty : {
                name: 'quantity',
                type: "Text",
                label: 'Số lượng',
                class:"width_135 common",
            },
            total_price : {
                name: 'total_price',
                type: "Text",
                label: 'Thành tiền',
                class:"width_135 common",
            },
            deleted: {
                name: "deleted",
                label: "Hiện thị",
                type: "Checkbox",

                display: false
           },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },




        })
    }

}