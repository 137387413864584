import BaseModel from '../core/BaseModel'

export default class CustomerDocumentModel extends BaseModel {
    constructor() {
        super({
            tableName: 'customer_document', label: "chứng từ"
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            key_get: {
                refs: 'qty_delivery return_quantity document {  name code id  }',
                display: false
            },
        })
    }

}