import BaseModel from '../core/BaseModel'

export default class CustomerFreightModel extends BaseModel {
    constructor() {
        super({
            tableName: 'freight_customer', label: "chứng từ"
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Int", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            }           
        })
    }

}