import BaseModel from '../core/BaseModel';
import MaterialModel from './MaterialModel';
import WarehouseModel from './WarehouseModel';
import MaterialCodeModel from "./MaterialCodeModel";
import Common from "../core/Common";
export default class StockQuantModel extends BaseModel {
    constructor() {
        super({ 
                name: "stock_quant",
                tableName: 'stock_quant', 
                label: "Quản lý tồn kho",
                display_key: 'name'
            },  
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    class: "xs-col",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            company_code: {
              name: "company_code",
              label: "Mã công ty",
              type: "String",
              class: "small-col",
              filter: { 
                type: 'input', 
                match: 'contains' 
            },
              computed: (data) => {
                data.company_code = '';
              }
            },
            product: { 
                refs: 'product {id name code unit{id name} material{id name code} company{id code}}',
                display: false
            },
            product_code : { 
                name: 'product_code', 
                type: "String", 
                label: 'Mã sản phẩm',
                sortable: false,
                class: "small-col",
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.product_code = '';
                }
            },
            product_name : { 
                name: 'product_name', 
                type: "String", 
                label: 'Tên sản phẩm',
                class: "large-col",
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.product_name = '';
                }
            },
            unit_name : { 
                name: 'unit_name', 
                type: "String", 
                label: 'Đơn vị tính',
                class: "small-col",
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.unit_name = '';
                }
            },
            lot_number : { 
                name: 'lot_number', 
                type: "String", 
                label: 'Mã lô',
                class: "small-col",
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            expiry_date : { 
                name: 'expiry_date', 
                type: "Date", 
                label: 'Hạn sử dụng',
                class: "small-col",
            },
            // material_code : { 
            //     name: 'material_code', 
            //     label: 'Mã nhóm VTHH',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new MaterialCodeModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.material_code = '';
            //     }
            // },
            material_code: Common.makeFilterCompany('material_code', 'Mã nhóm VTHH', new MaterialCodeModel(), "stock_quant", "small-col",),
            material_name : { 
                name: 'material_name', 
                label: 'Nhóm VTHH',
                type: "Input",
                custom: 'SelectionText',
                class: "small-col",
                models: new MaterialModel(),
                filter: { type: 'input', match: 'contains'},
                computed: (data) => {
                    data.material_code = '';
                }
            },
            warehouse: { 
                refs: 'warehouse {id name code}',
                display: false
            },
            // warehouse_code : { 
            //     name: 'warehouse_code', 
            //     label: 'Mã kho',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new WarehouseModel("code"),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.warehouse_code = '';
            //     }
            // },
            warehouse_code: Common.makeFilterCompany('warehouse_code', 'Mã kho', new WarehouseModel("code"), "stock_quant", "small-col",),
            warehouse_name : { 
                name: 'warehouse_name', 
                type: "String", 
                label: 'Tên kho',
                sortable: false,
                class: "medium-col",
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.warehouse_name = '';
                }
            },
            warning_expiry : { 
                name: 'warning_expiry', 
                label: 'Cảnh báo HSD',
                type: "Selection",
                class: "small-col",
                custom: 'SelectionText',
                options: [
                    { 'value': 1, 'label': '<1' },
                    { 'value': 3, 'label': '1-3' },
                    { 'value': 6, 'label': '3-6' },
                    { 'value': 12, 'label': '6-12' },
                    { 'value': 13, 'label': '>12' }
                ],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.warning_expiry = '';
                }
            },
            updated_at: {
                name: 'updated_at', 
                type: "Datetime",
                class: "small-col",
                label: 'Lần cập nhật cuối'
            },
            qty: { 
                name: 'qty', 
                type: "Number",
                class: 'right',
                label: 'SL kiểm tồn',
                display: false
            },
            qty_txt: {
                name: 'qty_txt', 
                type: "String",
                class: 'right txt-small-col',
                label: 'SL tồn kho',
                computed: (data) => {
                    data.qty_txt = 0;
                }
            },
            key_get1: {
                refs: 'warehouse_id ',
                display: false
            },//


        },)
    }

}