import BaseModel from '../core/BaseModel'
import CompanyModel from '@/models/CompanyModel';
import DepartmentModel from '@/models/DepartmentModel'
//import gql from 'graphql-tag'
import Common from '@/core/Common'
export default class GroupUserModel extends BaseModel {

    constructor() {
        super({ 
                name: "group_user",
                tableName: 'group_user',
                label: "Danh Sách Admin"
            },
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            uid: {
                name: 'uid',
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            // company_name: {
            //     name: 'company_name',
            //     label: 'Công ty',
            //     type: "Selection",
            //     custom: 'SelectionText',
            //     models: new CompanyModel(),
            //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
            //     computed: (data) => {
            //         data.company_name = '';
            //     }
            // },
            company_name: Common.makeFilterCompany('company_name', 'Công ty', new CompanyModel(), 'group_user'),
            department_name: {
                name: 'department_name',
                type: "Selection",
                label: 'Bộ phận',
                custom: 'SelectionText',
                models: new DepartmentModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.department_name = '';
                }
            },
            fullname: {
                name: 'fullname',
                type: "Text",
                label: 'Tên người dùng',
                filter: {type: 'input', match: 'contains'},
                computed: (data) => {
                    data.fullname = ''
                }
            },
            group_id: {
                name: 'group_id',
                type: "Bigint",
                label: 'Tên',
                display: false
            },
            group: {
                refs: 'group {id name}',
                display: false
            },
            user: {
                refs: 'user {id fullname department{id name} users_companies{id company{id name code}}}',
                display: false
            },
            created_name: {
                type: 'Text',
                name: 'created_name',
                label: 'Người tạo',
                computed: (data) => {
                    data.created_name = '';
                }
            },
            
            created: {
                refs: 'created {id fullname }',
                display: false  
            },
            created_at: {
                type: 'Datetime',
                name: 'created_at',
                label: 'Ngày tạo'
            }
        },)
    }

}