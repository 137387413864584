import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class ImageModel extends BaseModel {

    constructor() {
        super({
                name: "file",
                tableName: 'file',
                label: "Hình ảnh",
                /*display_key: 'fullname',
                default_order: { key: 'updated_at', direction: 'asc' } */},
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
                id  : {
                    name: 'id',
                    type: "Uuid",
                    label: 'Hình anh',
                    sortable: false,
                    width : 8,
                },
            url  : {
                name: 'url',
                type: "String",
                label: 'Hình anh',
                sortable: false,
                width : 8,
            },
        },)
    }

}