import BaseModel from '../core/BaseModel'
export default class BookingLineModel extends BaseModel {

    constructor() {
        super({
                name: "booking_line",
                tableName: 'booking_line',
                label: "dòng lịch hẹn",
                /* display_key: 'fullname',
                 default_order: { key: 'updated_at', direction: 'asc' }*/
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                booking_id : {
                    name: 'booking_id',
                    type: "String",
                    label: 'Lịch hẹn'
                },
                service_id : {
                    name: 'service_id',
                    type: "String",
                    label: 'Dịch vụ'
                },
                service_name : {
                    name: 'service_name',
                    type: "String",
                    label: 'Tên dịch vụ'
                }
            })
    }

}