import BaseModel from '../core/BaseModel'
export default class ChannelCompanyModel extends BaseModel {
    constructor() {
        super({
            tableName: 'channel_company', label: "Kênh", display_key: 'id'
        }, {
            stt: { 
              name: "stt", 
              label: "STT",
              class: 'center', 
              computed: (data, index) => {
                data.stt = index + 1;
              }
            },
            id: { 
              name: 'id', 
              type: "Uuid", 
              label: 'ID', 
              primaryKey: true,  
              display: false
            },
            channel_id: {
                name: 'channel_id',
                    type: "String",
                    label: 'Công ty',
                    filter: {type: 'input', match: 'contains'},
            },

            company_id : {
              name: 'company_id',
              type: "Text",
              label: 'Têm kênh phân phối',
              filter: {type: 'input', match: 'contains'},
              // computed: (data) => {
              //   data.name = '';
              // }
            },
            updated_at : {
              name: 'updated_at',
              type: "Datetime",
              label: 'Ngày cập nhật',
              filter: { type: 'DateRange', match: 'contains'},
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'},
              display : false
            },
            deleted: {
              name: "deleted",
              label: "Xóa",
              type: "Checkbox",
              display: false
            }
        })
    }

}